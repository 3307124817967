import { createEffect, createEvent } from 'effector'

import * as splash from '@/modules/splash'
import { api } from '@/services'

import { HTTP_STATUS_CODE } from '@/services/api/instance'
import { queryClient } from '@/services/react-query/query-client'
import store from '../store'
import SetActiveOrganizationErrorModal from './SetActiveOrganizationErrorModal'

export const leaveOrganization = createEffect('leaveOrganization', {
  handler: async ({ id, email }) => {
    await api.organizations.members.remove(id, email)

    return id
  },
})

export const acceptInvitation = createEffect('acceptInvitation', {
  handler: async (inviteId: string) => {
    const { id, ...rest } = await api.invites.accept(inviteId)

    const payload = {
      ...rest,
      inviteId: id,
      ...rest.organization,
    }

    return payload
  },
})

export const refuseInvitation = createEffect('refuseInvitation', {
  handler: async (id: string) => {
    await api.invites.refuse(id)

    return id
  },
})

export const unsetActiveOrganization = createEvent('unsetActiveOrganization')

export const setActiveOrganization = createEffect('setActiveOrganization', {
  handler: async (id: string) => {
    const organization = store.getState().organizations.current.find(org => org.id == id)

    const handlePartnerships = async () => {
      const data = await api.organizations.partnerships.list({
        [{
          BROKER: 'broker',
        }[organization.type] || 'partner']: id,
      })

      if (!data)
        return await api.organizations.partnerships.list({
          [{
            BROKER: 'broker',
          }[organization.type] || 'partner']: organization.document,
        })

      return data
    }

    const handleQueryKey = () => {
      if (organization.type === 'BROKER')
        return {
          filters: { broker: organization.id },
          key: `broker-${organization.id}`,
        }

      if (organization.type === 'REAL_ESTATE')
        return {
          filters: { partner: organization.id },
          key: `partner-${organization.id}`,
        }
    }

    try {
      splash.toggle()

      const queryKey = handleQueryKey()

      // Isso salva a resposta no cache para que a requisição não seja duplicada posteriormente.
      const partnerships = await queryClient
        .fetchQuery(['organizations.partnerships.list', queryKey.key], handlePartnerships)
        .finally(() => {
          splash.toggle()
        })

      return {
        ...organization,
        partnerships,
      }
    } catch (e) {
      const status = e?.response?.status || 500

      if (status === HTTP_STATUS_CODE.NOT_FOUND) {
        await SetActiveOrganizationErrorModal({
          errorTitle: 'Vínculo não encontrado',
          errorMessage:
            '<p>Não encontramos o vínculo da sua conta com a organização selecionada.</p><p>Para informações, entre em contato com seu consultor.</p>',
          buttonText: 'Ok, entendi',
        })
      } else {
        await SetActiveOrganizationErrorModal({
          errorTitle: 'Algo deu errado',
          errorMessage: 'Não foi possível acessar a organização selecionada.',
          buttonText: 'Tentar novamente',
        })
      }

      throw e
    }
  },
})

export const resetActiveOrganization = createEvent('resetActiveOrganization')

store
  .on(leaveOrganization.doneData, (state, id) => {
    return {
      ...state,
      organizations: {
        ...state.organizations,
        current: state.organizations.current.filter(organization => organization.id !== id),
      },
    }
  })
  .on(acceptInvitation.doneData, (state, organization) => {
    return {
      ...state,
      organizations: {
        ...state.organizations,
        current: [...state.organizations.current, organization],
        invites: state.organizations.invites.filter(invite => invite.id !== organization.inviteId),
      },
    }
  })
  .on(refuseInvitation.doneData, (state, id) => {
    return {
      ...state,
      organizations: {
        ...state.organizations,
        invites: state.organizations.invites.filter(invite => invite.id !== id),
      },
    }
  })
  .on(setActiveOrganization.doneData, (state, organization: any) => ({
    ...state,
    organizations: {
      ...state.organizations,
      active: organization,
    },
  }))
  .on(unsetActiveOrganization, state => ({
    ...state,
    organizations: {
      ...state.organizations,
      active: {
        id: '',
        type: '',
        partnerships: [],
      },
    },
  }))
  .on(resetActiveOrganization, state => ({
    ...state,
    organizations: {
      ...state.organizations,
      active: {
        id: '',
      },
    },
  }))
  .watch(({ organizations: { active } }) => {
    api.instance.v1.defaults.headers['x-organization'] = active.id
    api.instance.v2.defaults.headers['x-organization'] = active.id
  })
