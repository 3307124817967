import { createEffect } from 'effector'

import { api } from '@/services'
import { toast } from '@/components'

import { ContractStore } from '../store'

export const order = createEffect('orderContract', {
  handler: async payload => {
    const contract = await api.contracts.order(ContractStore.getState().id, payload)
    return contract
  },
})

ContractStore.on(order.pending, (state, pending) => {
  return {
    ...state,
  }
})
  .on(order.fail, (state, requestResult: any) => {
    if (requestResult.error.response.data.code === '@contract/invalid-create-contract') {
      return state
    }
    return {
      ...state,
      _status: 'fulfilled',
      order_error: requestResult.error.response.data,
    }
  })
  .on(order.doneData, (_, contract) => {
    contract.order_error = undefined

    return contract
  })
