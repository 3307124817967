import { rem, rgba } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import { Actions as ActionsComponent, Forms } from '@/components'
import { themes } from '@/styles'

import SelectGroup from '@/components/Forms/SelectGroup'
import { Button as ButtonComponent } from 'design-system-react'
import { Form } from 'formik'

export const Container = styled.div`
  border-radius: 8px;
  background-color: ${themes.page.colors.background};
`
export const Body = styled.div`
  overflow-x: auto;

  span {
    white-space: nowrap;
  }
`

export const Select = styled(SelectGroup)

export const Section = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 40px;
  }
`
export const Card = styled.div<{ variant: string }>`
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: ${themes.page.colors.background};
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
  ${props =>
    props.variant &&
    {
      danger: css`
        border-color: ${rgba(themes.palletes.danger, 0.5)};
      `,
    }[props.variant]}
`
export const Group = styled.div`
  padding: 24px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${themes.utilities.border};
  }
  ${media.greaterThan('huge')`
      padding: 32px;
    `}
`
export const Title = styled.h6`
  margin-bottom: 16px;
  font-weight: 500;
  color: ${themes.text.low};
  ${media.greaterThan('huge')`
      margin-bottom: 24px;
    `}
`
export const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem('11px')};
  ${media.greaterThan('huge')`
      font-size: ${rem('14px')};
    `}
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`
export const Label = styled.div`
  width: 100px;
  padding-right: 8px;
  color: ${themes.text.low};
  ${media.greaterThan('huge')`
      width: 160px;
    `}
`
export const Value = styled.div`
  flex: 1;
  color: ${themes.text.higher};
`
export const Head = styled.div`
  padding: 8px 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;

  ${media.greaterThan('large')`
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
  `}

  ${media.greaterThan('huge')`
      padding: 24px 32px;
  `};
`
export const SearchForm = styled(Form)`
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
  gap: 16px;
  width: fit-content;
`

export const Search = styled(Forms.InputGroup)`
  width: max-content;
  max-width: 20rem;
  margin: 0;
`

export const Actions = styled.div`
  margin-bottom: 32px;

  ${media.greaterThan('large')`
    margin-left: auto;
    margin-bottom: unset;
  `}
`

export const Action = styled(ButtonComponent)`
  display: inline-flex !important;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`
export const Floating = styled(ActionsComponent.Floating)<any>``
export const Paginator = styled.div`
  margin-top: 50px;
`
export const Message = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${rem('25px')};
  font-weight: 600;
  font-size: ${rem('13px')};
  color: ${themes.text.medium};
  ${media.greaterThan('huge')`
    margin-left: 16px;
    font-size: ${rem('15px')};
  `}
`
