import { useFormikContext } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext, useEffect, useMemo } from 'react'

import { Conditional, Content, Forms, Grid } from '@/components'

import { BRLMoneyToNumber, formatMoneyBRL } from '@/tools'
import { BudgetsContext, ImobiliariaBudget } from '..'
import * as S from '../styles'
import { coveragesParse } from '../utils'

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
}

const readOnlyFields = ['content']

const Coverages = () => {
  const context = useContext(BudgetsContext)
  const formik = useFormikContext<ImobiliariaBudget>()

  const vacantEstate = useMemo(
    () => ['7', '8', '4'].includes(formik.values.estate_type),
    [formik.values.estate_type],
  )

  const requiredFields = useMemo(
    () => ['fire', 'content'].filter(item => !(item === 'content' && vacantEstate)),
    [vacantEstate],
  )

  useEffect(() => {
    formik.setFieldValue(
      'coverages.content',
      formatMoneyBRL(BRLMoneyToNumber(`${formik.values.coverages.fire}`) * 0.1),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.coverages.fire])

  return (
    <Forms.Card
      step={formik.values.renewal_number ? '4' : '5'}
      title="Coberturas"
      helper={
        formik.values.renewal_number
          ? 'Atualize e confirme os valores de cada <b>cobertura</b> para o imóvel. Lembre-se de <b>não</b> ultrapassar o total de <b>2,7 milhões</b>.'
          : formik.values.contract_plan === 'residential'
            ? 'Configure os valores de cada <b>cobertura</b> para o imóvel. Lembre-se de <b>não</b> ultrapassar o total de <b>2,7 milhões</b>.'
            : 'Configure os valores de cada <b>cobertura</b> para o imóvel. Lembre-se de <b>não</b> ultrapassar o total de <b>3 milhões</b>.'
      }
      active
    >
      <Conditional when={!formik.values.contract_plan}>
        <Content.Message title="Atenção" kind="warning">
          Antes de cadastrar as coberturas, selecione o plano deste orçamento na <b>2ª etapa</b>.
        </Content.Message>
      </Conditional>

      <Conditional when={!!formik.values.contract_plan}>
        <S.Container>
          <Grid columns={['repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={['.5rem', '1rem']}>
            {requiredFields.map(coverage => {
              const readOnlyField = readOnlyFields.includes(coverage)

              return (
                <Forms.InputGroup
                  key={coverage}
                  readOnly={readOnlyField}
                  showRequired={!readOnlyField}
                  name={'coverages.' + coverage}
                  label={coveragesParse[coverage]}
                  tip={readOnlyField && '10% do valor de incêndio'}
                  mask="money"
                  resetValueOnUnmount={false}
                  placeholder="R$"
                  data-gtm-form="input"
                  data-gtm-name={coveragesParse[coverage]}
                  data-gtm-subname="coberturas"
                  data-test-id={`coverage-${coverage}`}
                />
              )
            })}
          </Grid>
          <S.Label>Opcionais</S.Label>
          <Grid columns={['repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={['.5rem', '1rem']}>
            {context.settings.coverages
              .filter(coverage => !requiredFields.includes(coverage))
              .map(coverage => {
                const readOnlyField = readOnlyFields.includes(coverage)

                if (formik.values.contract_plan === 'residential' && coverage === 'riot')
                  return null

                if (
                  vacantEstate &&
                  ['content', 'rental_loss', 'civil_responsability'].includes(coverage)
                )
                  return null

                return (
                  <Forms.InputGroup
                    key={coverage}
                    readOnly={readOnlyField}
                    name={'coverages.' + coverage}
                    label={coveragesParse[coverage]}
                    mask="money"
                    placeholder="R$"
                    resetValueOnUnmount={false}
                    data-gtm-form="input"
                    data-gtm-name={coveragesParse[coverage]}
                    data-gtm-subname="coberturas"
                    data-test-id={`coverage-${coverage}`}
                  />
                )
              })}
          </Grid>
        </S.Container>
      </Conditional>

      <AnimatePresence>
        <Conditional when={context.rules.isMaximumValueOfCoverages}>
          <motion.div
            variants={variants}
            key="coverages-warning"
            initial="initial"
            animate="animate"
            exit="initial"
          >
            <Grid space={['"1rem 0 0"', '1rem 0 0']}>
              <Content.Message title="Atenção" kind="danger">
                A soma de todas as IS das coberturas informadas não podem ultrapassar{' '}
                <b>2,7 milhões</b>, ajuste para continuar.
              </Content.Message>
            </Grid>
          </motion.div>
        </Conditional>
      </AnimatePresence>
    </Forms.Card>
  )
}

export default Coverages
