import { useCurrentOrganization } from '@/modules'
import { loadBrokerRealEstatesPartnerships } from '@/services/api/partnership'
import { useQuery, UseQueryOptions } from 'react-query'

type DataType = Awaited<ReturnType<typeof loadBrokerRealEstatesPartnerships>>

export function usePartnerOptions(options?: UseQueryOptions<DataType>) {
  const currentProfile = useCurrentOrganization()

  const query = useQuery(
    ['@get-partner-options', currentProfile.id],
    () => loadBrokerRealEstatesPartnerships(currentProfile.id),
    {
      // ? Tempo de vida do dado antes de uma nova busca 5min.
      staleTime: 1 * 60 * 1000,
      enabled: currentProfile.type === 'BROKER',
      ...options,
    },
  )

  return query
}
