import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div).attrs({
  transition: { duration: 0.2 },
  variants: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
  exit: 'exit',
  initial: 'initial',
  animate: 'animate',
})`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  width: 100%;
  height: 100vh;

  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--porto-banking-05);
`

export const Flex1 = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '48px',
  justifyContent: 'center',
  alignItems: 'center',
})

export const Flex2 = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'center',
  alignItems: 'center',
})
