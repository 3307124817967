import { onlyNumbers } from '@/tools'
import { isCEP, isCNPJ, isCPFOrCNPJ } from 'brazilian-values'
import * as yup from 'yup'
import { sociosSchema } from './firstStepSchema'

export function parseToNumericValue(value: string) {
  const numericValue = Number(value?.replace('%', '').replace(',', '') || '0')

  return numericValue
}

export const secondStepSchema = yup.object().shape({
  estate: yup.object().shape({
    type: yup.string().oneOf(['RESIDENCIAL', 'COMERCIAL']).required(),
    address: yup.object().shape({
      zipcode: yup
        .string()
        .required('Campo CEP é obrigatório.')
        .test('cep', 'Por favor insira um CEP válido.', value => {
          const valueDigits = value?.replace(/\D/g, '')
          return valueDigits?.length === 8
        }),
      street: yup.string().required('Por favor confirme a rua.'),
      number: yup
        .string()
        .matches(/^[0-9]*$/g, 'Utilize o campo de complemento para inserir letras.')
        .required('Por favor insira o número.'),
      complement: yup.string(),
      neighborhood: yup.string().required('Por favor confirme o bairro.'),
      city: yup.string().required('Por favor confirme a cidade.'),
      state: yup.string().required('Por favor confirme o estado.'),
    }),
  }),
  landlord: yup
    .object({
      name: yup.string().required('Campo nome é obrigatório.'),
      document: yup
        .string()
        .required('Campo CPF é obrigatório.')
        .test(
          'document-test',
          'Por favor insira um CPF válido.',
          value => !!value && isCPFOrCNPJ(value),
        )
        .test(
          'document-test',
          'O documento deve ser diferente do documento do inquilino.',
          (value, context) => {
            const customerDocument = context.options.context.customerDocument
            const landlordDocument = onlyNumbers(value)

            if (customerDocument !== undefined) {
              return landlordDocument !== customerDocument
            }

            return true
          },
        ),
      phone: yup
        .string()
        .required('Campo telefone/celular é obrigatório.')
        .test('phone', 'Informe um número válido', value => {
          const valueDigits = value?.replace(/\D/g, '')
          return valueDigits?.length === 10 || valueDigits?.length === 11
        }),
      email: yup
        .string()
        .email('Por favor insira um e-mail válido.')
        .required('Campo e-mail é obrigatório.'),
      address_type: yup.string().oneOf(['RESIDENCIAL', 'COMERCIAL']).required(),
      address: yup.object().shape({
        zipcode: yup
          .string()
          .required('Campo CEP é obrigatório.')
          .test('cep', 'Por favor insira um CEP válido.', value => !!value && isCEP(value)),
        number: yup
          .string()
          .matches(/^[0-9]*$/g, 'Utilize o campo de complemento para inserir letras.')
          .required('Por favor insira o número.'),
      }),
    })
    .when({
      is: value => !!value?.document && isCNPJ(value?.document),
      then: schema => {
        return schema.shape({
          socios: yup
            .array()
            .of(sociosSchema)
            .nullable()
            .test({
              test: (value, context) => {
                const total = value?.reduce((currentValue, socio) => {
                  const numericValue = parseToNumericValue(socio?.stake || '0')

                  return currentValue + numericValue
                }, 0)

                if (total < 100) {
                  if (value.length < 2)
                    return context.createError({
                      message:
                        'Para empresas que têm um único sócio, informe a participação de 100% para ele. Se houver mais sócios, você deverá adicioná-los abaixo, ajustando a participação para cada um deles.',
                      path: 'stake',
                    })

                  return context.createError({
                    message:
                      'Para empresas com dois ou mais sócios, você deverá adicionar cada um deles, ajustando suas participações até que totalizem 100%.',
                    path: 'stake',
                  })
                }

                return true
              },
            })
            .test({
              test: (value, context) => {
                const total = value?.reduce((currentValue, socio) => {
                  const numericValue = parseToNumericValue(socio?.stake || '0')

                  return currentValue + numericValue
                }, 0)

                if (total > 100) {
                  const path = `customer.socios.${value.length - 1}.stake`

                  return context.createError({
                    message: 'Ajuste as participações para que o total não ultrapasse 100%.',
                    path,
                  })
                }

                return true
              },
            })
            .test({
              test: (value, context) => {
                if (typeof value?.length === 'number' && value?.length < 2) return true

                const total = value?.reduce((currentValue, socio) => {
                  const numericValue = parseToNumericValue(socio?.stake || '0')

                  return currentValue + numericValue
                }, 0)

                if (total > 100) {
                  return context.createError({
                    message:
                      'Para empresas com dois ou mais sócios, você deverá adicionar cada um deles, ajustando suas participações até que totalizem 100%.',
                    path: 'stake',
                  })
                }

                return true
              },
            })
            .test({
              test: (value, context) => {
                const errorIndex = value?.findIndex((findValue, findIndex, customersArray) => {
                  const duplicateIndex = customersArray.findIndex(
                    value => value?.document === findValue?.document,
                  )

                  return findIndex !== duplicateIndex
                })

                if (errorIndex > -1) {
                  return context.createError({
                    path: `landlord.socios.${errorIndex}.document`,
                    message: 'Este documento já está em uso por um dos Sócios',
                  })
                }

                return true
              },
            }),
        })
      },
    }),
})
