import { useContract } from '@/modules'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { formatToCEP, formatToCPFOrCNPJ, formatToPhone, isCPF } from 'brazilian-values'
import { TextContentGroup } from '../TextContentGroup'

export const Owner = () => {
  const contract = useContract<Products.CAPITALIZACAO>()

  return (
    <TextContentGroup
      title="Proprietário"
      values={[
        [
          {
            title: 'CPF/CNPJ',
            description: formatToCPFOrCNPJ(contract.payload.landlord.document),
          },
          {
            title: 'Nome/Razão social',
            description: contract.payload.landlord.name,
          },
          isCPF(contract.payload.landlord.document) &&
            contract.payload.landlord.socialName && {
              title: 'Nome social',
              description: contract.payload.landlord.socialName,
            },
          { title: 'E-mail', description: contract.payload.landlord.email },
        ],
        [
          {
            title: 'Telefone/celular',
            description: formatToPhone(contract.payload.landlord.phone) || '-',
          },
        ],
        [
          {
            title: 'CEP',
            description: formatToCEP(contract?.payload?.landlord.address.zipcode),
          },
          {
            title: 'Número',
            description: contract?.payload?.landlord.address.number,
          },
        ],
      ]}
    />
  )
}
