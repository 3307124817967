import { Loader } from '@/components/Icons'
import { Typography } from 'design-system-react'
import * as S from './styles'

const Splash = () => {
  return (
    <S.Container>
      <S.Flex1>
        <S.Flex2>
          <Typography as="h4" type="Title4" weight="bold">
            Carregando os dados da organização
          </Typography>
          <Typography as="span" type="Body1">
            Por favor, aguarde...
          </Typography>
        </S.Flex2>

        <Loader size="48px" />
      </S.Flex1>
    </S.Container>
  )
}

export default Splash
