import { useCurrentOrganization } from '@/modules'
import { api } from '@/services'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'

export function usePartnershipQuery(options?: UseQueryOptions) {
  const currentOrganization = useCurrentOrganization()

  const filtersAndKey = useMemo(() => {
    if (currentOrganization?.type === 'BROKER')
      return {
        filters: { broker: currentOrganization?.id },
        key: `broker-${currentOrganization?.id}`,
      }

    if (currentOrganization?.type === 'REAL_ESTATE')
      return {
        filters: { partner: currentOrganization?.id },
        key: `partner-${currentOrganization?.id}`,
      }
  }, [currentOrganization?.id, currentOrganization?.type])

  const partnershipsQuery = useQuery<any>(
    ['organizations.partnerships.list', filtersAndKey?.key],
    () => api.organizations.partnerships.list(filtersAndKey.filters),
    {
      enabled: !!filtersAndKey && !!filtersAndKey.key,
      staleTime: 1000 * 60 * 1, // 1 minutes
      ...options,
    },
  )

  return partnershipsQuery
}
