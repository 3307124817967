import { useFormikContext } from 'formik'
import { useMemo, useState } from 'react'

import { Address, Conditional, Content, Forms, Modals } from '@/components'
import { IAddressValuesProps } from '@/components/Utilities/Address/interfaces'
import { useGTM } from '@/tools'

import { ImobiliariaBudget } from './../..'
import * as S from './../../styles'

import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { EstablishmentRestrictionsModal } from './_EstablishmentRestrictionsModal'

const Estate = () => {
  const formik = useFormikContext<ImobiliariaBudget>()
  const { setDataLayer } = useGTM()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const values = formik.values
  const zipcode = values.estate_address?.zipcode
  const plan = values.contract_plan
  const isCommercialPlan = useMemo(() => ['commercial'].includes(plan), [plan])

  const includeCompanyInfos = useMemo(
    () => isCommercialPlan && formik.values.customer_company_type === '1',
    [isCommercialPlan, formik.values.customer_company_type],
  )

  const estateTypes =
    {
      residential: [
        {
          label: 'Casa',
          value: '5',
        },
        {
          label: 'Apartamento',
          value: '6',
        },
        // {
        //   label: 'Casa desocupada',
        //   value: '7',
        // },
        // {
        //   label: 'Apartamento desocupado',
        //   value: '8',
        // },
      ],
      commercial: [
        {
          label: 'Consultórios, escolas, escritórios e hospitais',
          value: '1',
        },
        {
          label: 'Comércio',
          value: '15',
        },
        {
          label: 'Indústrias',
          value: '3',
        },
        // {
        //   label: 'Imóveis desocupados/não locados',
        //   value: '4',
        // },
        {
          label: 'Serviços',
          value: '16',
        },
      ],
    }[plan] || []

  const estateTypeExtra = {
    label: 'Imóvel de madeira',
    value: '9',
  }

  const stateSelected = ['SC', 'RS', 'PR']

  const fetchAddress = async (initialValues?: IAddressValuesProps) => {
    if (!zipcode || !!formik.errors?.estate_address?.zipcode) return

    const address = await Modals.Address({
      zipcode,
      initialValues,
    })

    setDataLayer({
      event: 'solicitacao-servico',
      nome_servico: 'seguro-incendio',
      tipo_servico: 'confirmar-endereco',
      retorno: address ? 'sucesso' : 'erro',
      descricao: address ? 'endereço confirmado' : 'endereço não confirmado',
    })

    if (!address) {
      Object.entries(formik.values.estate_address).map(([key, value]) => {
        formik.setFieldValue(`estate_address.${key}`, value)
      })
      formik.setFieldValue('estate_address.zipcode', ' ')

      return
    }

    Object.entries(address).map(([key, value]) => {
      formik.setFieldValue(`estate_address.${key}`, value)
    })
  }

  if (formik.values.contract_plan === 'residential') {
    const isStateSelected = state => state === formik.values.estate_address.state
    stateSelected.filter(isStateSelected).forEach(() => {
      estateTypes.push(estateTypeExtra)
    })
  }

  return (
    <Forms.Card
      step={formik.values.renewal_number ? '3' : '4'}
      title="Imóvel"
      helper={
        !formik.values.renewal_number
          ? 'Aqui é o espaço para preencher os <b>dados do imóvel</b> a ser orçado.'
          : null
      }
      active
    >
      <S.ToggleWrapper>
        <Forms.Toggle
          name="estate_owner"
          label="Proprietário do imóvel?"
          data-gtm-form="select"
          data-gtm-name="proprietario-do-imovel"
        />
      </S.ToggleWrapper>
      <S.Grid>
        <Forms.InputGroup
          name="estate_address.zipcode"
          label="CEP"
          showRequired
          placeholder="Digite o CEP"
          mask="zipcode"
          onBlur={() => fetchAddress()}
          data-gtm-form="input"
          data-gtm-name="cep"
          data-gtm-subname="imovel"
          data-test-id="cep"
        />

        <Conditional when={!!plan}>
          <Forms.SelectGroup
            name="estate_type"
            label="Tipo do imóvel"
            showRequired
            resetValueOnUnmount={false}
            options={[
              {
                label: 'Selecione o tipo do imóvel',
                value: '',
              },
              ...estateTypes,
            ].filter(option =>
              !formik.values.estate_owner ? !['4', '7', '8'].includes(option.value) : true,
            )}
            data-gtm-type="select"
            data-gtm-name="tipo-do-imovel"
            data-gtm-subname="imovel"
            data-test-id="estate-type"
          />
        </Conditional>
      </S.Grid>

      <Conditional when={isCommercialPlan}>
        <S.Grid space={['.5rem 0 0', '1rem 0 0']}>
          <Forms.SelectGroup
            name="customer_company_type"
            label="Tipo de empresa"
            options={[
              {
                label: 'Selecione o tipo de empresa',
                value: '',
              },
              {
                label: 'Empresa Privada',
                value: '1',
              },
              {
                label: 'Órgãos/ Empresas Públicas',
                value: '2',
              },
              {
                label: 'Embaixadas / Consulados',
                value: '3',
              },
            ]}
            data-test-id="company-type"
          />
        </S.Grid>
      </Conditional>

      <Conditional when={includeCompanyInfos}>
        <S.Grid space={['.5rem 0 0', '1rem 0 0']}>
          <Forms.SelectGroup
            name="customer_company_netEquity"
            label="Patrimônio líquido"
            options={[
              {
                label: 'Não desejo informar',
                value: '0',
              },
              {
                label: 'Sem patrimônio líquido',
                value: '1',
              },
              {
                label: 'Até 1.199.999',
                value: '2',
              },
              {
                label: 'De 1.200.000 a 10.499.999',
                value: '3',
              },
              {
                label: 'De 10.500.000 a 60.000.000',
                value: '4',
              },
              {
                label: 'Acima de 60.000.000',
                value: '5',
              },
            ]}
            data-test-id="company-equity"
          />

          <Forms.SelectGroup
            name="customer_company_grossIncome"
            label="Receita operacional bruta anual"
            options={[
              {
                label: 'Não desejo informar',
                value: '0',
              },
              {
                label: 'Sem receita operacional bruta anual',
                value: '1',
              },
              {
                label: 'Até 1.199.999',
                value: '2',
              },
              {
                label: 'De 1.200.000 a 10.499.999',
                value: '3',
              },
              {
                label: 'De 10.500.000 a 60.000.000',
                value: '4',
              },
              {
                label: 'Acima de 60.000.000',
                value: '5',
              },
            ]}
            data-test-id="company-income"
          />

          {/* <Forms.SelectGroup
            name="customer_company_controllers"
            label="Controladores"
            options={[
              {
                label:
                  'Não desejo informar os administradores, controladores e procuradores',
                value: '3',
              },
              {
                label: 'Há administradores, controladores e procuradores',
                value: '1',
              },
              {
                label: 'Não há administradores, controladores e procuradores',
                value: '2',
              },
            ]}
          /> */}
        </S.Grid>
      </Conditional>

      <S.GridAddress space={['.5rem 0 0', '1rem 0 0']}>
        <Address
          fetchAddress={fetchAddress}
          isDisabled={!!formik.values.renewal_number}
          {...formik.values.estate_address}
        />
      </S.GridAddress>

      <Conditional when={isCommercialPlan}>
        <Content.Message title="Atenção" kind="warning">
          Existem alguns estabelecimentos que não são aceitos pelo Seguro Incêndio, como depósitos
          de produtos específicos, fazendas e fábricas.
          <br />
          <br />
          <Typography
            role="button"
            variant="porto-text-link-body-2-bold"
            onClick={() => setIsModalOpen(true)}
            color="porto-seguros-100"
            sx={{ cursor: 'pointer' }}
            content="Veja a lista completa aqui."
          />
          <br />
          <br />
          Certifique-se que seu imóvel esteja fora dessas condições.
        </Content.Message>
        <EstablishmentRestrictionsModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </Conditional>
    </Forms.Card>
  )
}

export default Estate
