import {
  capitalize,
  formatCNPJ,
  formatCPF,
  isValidCNPJ,
  isValidCPF,
} from '@brazilian-utils/brazilian-utils'
import { formatToPhone } from 'brazilian-values'

export function removeSpecialChars(string: string = '') {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/([^\w]+|\s+)/g, '-')
    .replace(/--+/g, '-')
    .replace(/(^-+|-+$)/, '')
}

export function removeSpecialCharsKeepingSpaces(string: string = '') {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/--+/g, '-')
    .replace(/(^-+|-+$)/, '')
}

export function formatCustomerName(name: string) {
  const names = capitalize(name).split(' ')

  if (names.length < 2) return name

  return `${names[0]} ${names[names.length - 1]}`
}

export function formatDocument(string: string) {
  if (isValidCPF(string)) return formatCPF(string)

  if (isValidCNPJ(string)) return formatCNPJ(string)

  return string
}

export function dashText(string: string = '') {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replaceAll(/[\W]/g, '-')
    .toLocaleLowerCase()
}

export function onlyNumbers(string: string) {
  return String(string).replace(/\D/g, '')
}

export function applyPhoneMask(phone: string): string {
  const removeCellphoneExtraSpace = (cellphone): string => {
    return cellphone.replace(/^(\([^)]*\))(\s+\S+)\s+/g, '$1$2')
  }

  return removeCellphoneExtraSpace(formatToPhone(phone))
}

export function encodeBase64(string: string): string {
  return btoa(string)
}

export function decodeBase64(string: string): string {
  return atob(string)
}

export function removeCpfChars(string: string): string {
  return string.split('-').join('').split('.').join('')
}
