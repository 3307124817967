export namespace PortoAluguel {
  export const Reason = {
    1: 'Próximo ao trabalho',
    3: 'Para terceiros',
    4: 'Separação',
    32: 'Apólice vencida',
    10: 'Casamento',
    13: 'Transferência de empresa',
    14: 'Independência',
    21: 'Funcionários da empresa do pretendente',
    24: 'Locação Adicional',
    25: 'Venda do imóvel próprio',
    28: 'Próximo a instituição de ensino',
    29: 'Imóvel próximo de familiares',
    31: 'Redução de Custo',
  }

  export const EstateType = {
    1: 'Consultórios, escolas, escritórios e hospitais',
    15: 'Comércio',
    3: 'Indústrias',
    4: 'Imóveis desocupados/não locados',
    16: 'Serviços',
    5: 'Casa',
    6: 'Apartamento',
    7: 'Casa desocupada',
    8: 'Apartamento desocupada',
    9: 'Casa de madeira',
  }

  export const Reajustment = {
    'IGP-M (FGV)': 'IGP-M (FGV)',
    'IGPM-DI (FGV)': 'IGPM-DI (FGV)',
    'IPC (FIPE)': 'IPC (FIPE)',
    'IPCA (IBGE)': 'IPCA (IBGE)',
    'INPC (IBGE)': 'INPC (IBGE)',
    'ICV (DIEESE)': 'ICV (DIEESE)',
    INCC: 'INCC',
    'IPC-FGV': 'IPC-FGV',
    MAIOR_INDICE: 'MAIOR ÍNDICE',
  }

  export const IncomesRange = {
    0: 'Não desejo informar',
    1: 'Sem Renda',
    2: 'Até R$ 2.500,00',
    3: 'De R$ 2.501,00 ate R$ 5.000,00',
    4: 'De R$ 5.001,00 até R$ 10.000,00',
    5: 'Acima de R$ 10.000,00',
  }
}
