import Button from '@/components/Actions/Button'
import { PartnershipSusepCombo } from '@/components/PartnershipSusepCombo'
import { useCurrentOrganization } from '@/modules'
import { usePartnershipQuery } from '@/tools/hooks/partnership'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { FormikProvider, useFormik } from 'formik'
import { HomeEquityStepComponent } from '..'
import { BaseCard } from '../components'
import validationSchema from './validations'

export const HomeEquityPartnerDefinition = ({ nextStep = () => null }: HomeEquityStepComponent) => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'

  const partnershipQuery = usePartnershipQuery()

  const getSusepNameFromCurrentOrganization = (susep): string => {
    const partnership = partnershipQuery.data?.find(partnership =>
      partnership.suseps?.includes(susep),
    )

    return partnership?.broker?.name || ''
  }

  const form = useFormik({
    initialValues: {
      susep: '',
      partner: !isBroker ? profile.id : '',
    },
    validationSchema,
    onSubmit: values => {
      let susepPartnerData: {
        susep: string
        susep_name: string
        partner_id: string
        partner_name: string
      }

      if (isBroker) {
        susepPartnerData = {
          susep: values.susep,
          susep_name: '',
          partner_id: values.partner,
          partner_name: '',
        }
      } else {
        susepPartnerData = {
          susep: values.susep,
          susep_name: getSusepNameFromCurrentOrganization(values.susep),
          partner_id: values.partner,
          partner_name: '',
        }
      }

      nextStep(susepPartnerData)
    },
  })

  return (
    <FormikProvider value={form}>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <BaseCard title={isBroker ? 'Imobiliária' : 'Corretora de Seguros'}>
          <Box
            sx={{
              display: 'flex',
              gridTemplateColumns: { xs: '1fr', lg: '1fr' },
              marginTop: '32px',
            }}
          >
            <Typography
              content={
                isBroker
                  ? 'Para começar, selecione a imobiliária parceira para a indicação.'
                  : 'Para começar, selecione a corretora parceira para a indicação.'
              }
              variant="porto-text-body-1-regular"
              sx={{
                color: 'var(--neutras-black-75, #404040)',
                paddingBottom: '16px',
              }}
            />
          </Box>
          <PartnershipSusepCombo product="any" />
        </BaseCard>
      </Flex>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
          gap: '1rem',
        }}
      >
        <Button onClick={() => form.handleSubmit()} isDisabled={!form.isValid}>
          Continuar
        </Button>
      </Box>
    </FormikProvider>
  )
}
