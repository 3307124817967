import { LAYOUT } from '@/constants'
import { centeredContainer } from '@/styles/mixins'
import { pxToRem } from '@/styles/sheets/helpers'
import breakpoints from '@pol-npm/riscos-financeiros-dashboard-ui/dist/theme/breakpoints.js'
import styled from 'styled-components'

const { CLOSED_MENU_WIDTH } = LAYOUT

export const Content = styled.div`
  ${centeredContainer}

  padding: ${pxToRem(84)} ${pxToRem(30)};
`

export const Main = styled.main`
  margin-top: ${pxToRem(80)};
  margin-left: ${pxToRem(`${CLOSED_MENU_WIDTH}px`)};

  @media (max-width: ${breakpoints.sm}) {
    margin-left: auto;
  }
`
