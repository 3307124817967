import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { size } from 'polished'

import { themes } from '@/styles'

import { Loader as LoaderComponent, FeatherIcons } from '../../Icons'
import { Icon as PortoIcons } from 'design-system-react'

import { IButtonProps } from './interfaces'

export const Loader = styled(LoaderComponent).attrs(() => ({
  switch: true,
}))`
  margin-left: 8px;
`

const IconStyles = css`
  ${size('16px')}
  font-size: 16px;

  color: #f6f7fb;
  transition: 0.3s;
  pointer-events: none;

  ${media.greaterThan('huge')`
    ${size('24px')}
    font-size: 24px;
  `}
`

export const FeatherIcon = styled(FeatherIcons)`
  ${IconStyles}
`

export const PortoIcon = styled(PortoIcons).attrs({ className: 'porto-icons' })`
  ${IconStyles}
`

export const Container = styled.button<
  Pick<IButtonProps, 'kind' | 'size' | 'isDisabled' | 'variant' | 'hover' | 'square'> & {
    disabled?: boolean
    iconLeft?: boolean
  }
>`
  all: unset;

  position: relative;

  padding: 12px 20px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;

  gap: 8px;

  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
  transition: background-color 0.3s;

  color: #fff;

  text-align: center;
  border: 1px solid transparent;
  font: semibold 11px ${themes.fonts.title};

  background-color: ${props => themes.palletes[props.variant]};

  &:hover {
    transition: background-color 0.3s;
    background-color: ${props => themes.palletes[props.hover]};
  }

  &:active {
    transform: scale(0.98);
    transition: transform 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }

  ${({ iconLeft }) =>
    !!iconLeft &&
    css`
      flex-direction: row;
      gap: 8px;
      padding: 12px;
    `}

  ${({ size }) =>
    size &&
    {
      block: css`
        width: 100%;

        display: flex;

        ${FeatherIcon}, ${PortoIcon} {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);

          margin-right: 0;
        }

        ${Loader} {
          position: absolute;
          top: 35%;
          right: 16px;

          margin-right: 0;
        }
      `,

      small: css`
        padding: 8px 10px;

        font-size: 7px;
        border-radius: 4px;

        ${media.greaterThan('large')`
          padding: 8px 14px;

          font-size: 9px;
        `}

        ${media.greaterThan('huge')`
          padding: 12px 22px;

          font-size: 10px;
        `}
      `,
    }[size]}
    
  ${props =>
    props.kind &&
    css`
      ${themes.button.kinds}

      ${FeatherIcon}, ${PortoIcon} {
        color: inherit;
      }
    `}

    ${({ square }) =>
    square &&
    css`
      border-radius: 0;
    `}

    ${({ isDisabled, kind }) =>
    isDisabled &&
    css`
      color: #999999;
      background-color: ${kind !== 'ghost' && '#e0e0e0'};
      border-color: #999999;

      ${FeatherIcon}, ${PortoIcon} {
        color: #999999;
      }

      cursor: not-allowed;

      pointer-events: none;
    `}
`
