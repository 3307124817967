import { useState } from 'react'

import { Content, Forms, Modals, toast } from '@/components'
import { useCurrentUser } from '@/modules'
import { api } from '@/services'

import * as S from './styles'

type Props = Modals.Promised.PromisedModalProps & {
  newEmail: string
  currentEmail?: string
}

const Verification = (props: Props) => {
  const user = useCurrentUser()

  const [code, setCode] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async () => {
    try {
      setLoading(true)
      await api.users.auth.confirmEmailVerificationCode(props.currentEmail ?? user.store.email, code)
      user.updateUserEmail(props.newEmail)
      props.onSubmit()
    } catch (error) {
      toast('Código de verificação incorreto.', {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modals.Content
      icon="checkmark-done"
      title="Verificar código"
      onSubmit={{
        text: 'Verificar código',
        onClick: onSubmit,
        disabled: code.length < 6,
      }}
      onCancel={{
        text: 'Cancelar',
        onClick: props.onDismiss,
      }}
    >
      {loading ? (
        <Content.Loader message="Aguarde, estamos validando o código inserido." />
      ) : (
        <>
          <S.Text>
            Para confirmar a alteração do seu novo e-mail, insira abaixo o
            código de verificação enviado para <b>{props.newEmail}</b>.
          </S.Text>

          <S.Field>
            <Forms.InputCode
              autoFocus
              fields={6}
              placeholder={['0', '0', '0', '0', '0', '0']}
              onComplete={setCode}
            />
          </S.Field>
        </>
      )}
    </Modals.Content>
  )
}

export default Modals.Promised.createModal<Props>(Verification)
