import ModalContent from '@/components/Modals/Content'
import { createModal, TCreateModal } from '@/components/Modals/promised'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system'
import { Content, IconContainer, Paragraph, Title } from './styles'

interface SetActiveOrganizationErrorModalInterface extends TCreateModal {
  errorTitle: string
  errorMessage: string
  buttonText: string
}

const SetActiveOrganizationErrorModal: React.FC<
  SetActiveOrganizationErrorModalInterface
> = props => {
  return (
    <ModalContent
      icon=""
      title=""
      onSubmit={{
        text: props.buttonText,
        onClick: e => {
          return props.onSubmit(e)
        },
      }}
      size="small"
    >
      <Content>
        <IconContainer>
          <Icon {...{ name: 'alert', color: '#842310' }} />
        </IconContainer>

        <Title>{props.errorTitle}</Title>

        <Paragraph dangerouslySetInnerHTML={{ __html: props.errorMessage }} />
      </Content>
    </ModalContent>
  )
}

export default createModal<SetActiveOrganizationErrorModalInterface>(
  SetActiveOrganizationErrorModal,
)
