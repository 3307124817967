import dayjs from 'dayjs'

import { Grid, Info, toast } from '@/components'
import { IPopoverItems } from '@/components/Popover/interfaces'
import { usePagination } from '@/tools'

import { Status } from '../components'
import { ITableColumn } from '../components/Table'
import { BudgetStatus, BudgetType } from '../enums'

import { Contract } from '@/modules/contract/interface'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { api } from '@/services'
import { useHistory } from 'react-router-dom'
import { getContractStatus } from './status.data'

import { useCurrentOrganization } from '@/modules'
import { canResend as canResendAluguel } from '@/modules/contract/domain/aluguel'
import { canResend as canResendImob } from '@/modules/contract/domain/imobiliaria'
import { isBroker } from '@/tools/partnership'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { isCPF } from 'brazilian-values'

export function getContractKey(contract) {
  return contract.id
}

const ticketExistsImob = ['11', '21', '81']
const ticketExistsFianca = ['11', '31', '55', '81']

const handlePrint = async (id: string, type: string, budget?: string | number) => {
  await api.contracts.print(id, type, budget)
}

type LoadingFunctions = {
  setLoading: ReturnType<typeof usePagination>['pushUpdate']
  finishLoading: ReturnType<typeof usePagination>['finishUpdate']
  updateContract: ReturnType<typeof usePagination>['updateContractData']
}

export function getContractOptions(
  contract: Contract<any>,
  history: ReturnType<typeof useHistory>,
  loading: LoadingFunctions,
): IPopoverItems[] {
  const communs = [
    {
      icon: 'refresh-circle',
      label: 'Atualizar status',
      onClick: () => {
        loading.setLoading(contract.id)
        api.instance.v2
          .post(`/contracts/sync`, { id: contract.id })
          .then(({ data }) => {
            loading.updateContract(data)
          })
          .finally(() => loading.finishLoading(contract.id))
      },
    },
  ]

  switch (contract.product) {
    case Products.IMOBILIARIA: {
      communs.push({
        icon: 'copy-outline',
        label: 'Duplicar',
        onClick: () => history.push('/contratos/novo/imobiliaria', contract),
      })

      if (
        canResendImob({
          status: contract.status,
          metadata: contract.metadata,
          payload: contract.payload,
        })
      ) {
        communs.unshift({
          icon: 'repeat-outline',
          label: 'Reenviar orçamento',
          onClick: async () => {
            loading.setLoading(contract.id)
            await api.contracts
              .calculate(contract.id)
              .finally(() => loading.finishLoading(contract.id))
            history.push(`/contratos/${contract.id}`)
          },
        })
      }

      if (contract.payload.payment?.method.includes(ticketExistsImob)) {
        communs.push({
          icon: 'reader',
          label: 'PDF do boleto',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'ticket').finally(() => loading.finishLoading(contract.id))
          },
        })
      }

      if (contract.budgets) {
        communs.push({
          icon: 'reader',
          label: 'PDF do orçamento',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(
              contract.id,
              'budget',
              contract.budgets[contract.budgets.length - 1].id,
            ).finally(() => loading.finishLoading(contract.id))
          },
        })
      }

      if (contract.policy?.proposal) {
        communs.push({
          icon: 'reader',
          label: 'PDF da proposta',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'proposal').finally(() => loading.finishLoading(contract.id))
          },
        })
      }

      if (contract.policy?.id) {
        communs.push({
          icon: 'reader',
          label: 'PDF da apólice',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'policy').finally(() => loading.finishLoading(contract.id))
          },
        })
      }

      break
    }

    case Products.ALUGUEL: {
      if (
        canResendAluguel({
          metadata: contract.metadata,
          payload: contract.payload,
          status: contract.status,
        })
      ) {
        communs.unshift({
          icon: 'repeat-outline',
          label: 'Reenviar orçamento',
          onClick: async () => {
            await api.contracts.calculate(contract.id)
            history.push(`/contratos/${contract.id}`)
          },
        })
      }

      if (contract.payload?.payment?.method?.includes(ticketExistsFianca)) {
        communs.push({
          icon: 'reader',
          label: 'PDF do boleto',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'ticket').finally(() => loading.finishLoading(contract.id))
          },
        })
      }

      if (contract.payload?.pac) {
        communs.push({
          icon: 'reader',
          label: 'PDF do PAC',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'pac').finally(() => loading.finishLoading(contract.id))
          },
        })
      }

      if (contract.budgets) {
        communs.push({
          icon: 'reader',
          label: 'PDF do orçamento',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(
              contract.id,
              'budget',
              contract.budgets[contract.budgets.length - 1].id,
            ).finally(() => loading.finishLoading(contract.id))
          },
        })
      }

      if (contract.policy?.proposal) {
        communs.push({
          icon: 'reader',
          label: 'PDF da proposta',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'proposal').finally(() => loading.finishLoading(contract.id))
          },
        })
      }

      if (contract.policy?.id) {
        communs.push({
          icon: 'reader',
          label: 'PDF da apólice',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'policy').finally(() => loading.finishLoading(contract.id))
          },
        })
      }
      break
    }

    case Products.ESSENCIAL: {
      if (contract.payload?.payment?.method?.includes(ticketExistsFianca)) {
        communs.push({
          icon: 'reader',
          label: 'PDF do boleto',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'ticket', contract.policy?.budget).finally(() =>
              loading.finishLoading(contract.id),
            )
          },
        })
      }

      if (contract.policy?.budget && !contract.policy?.proposal) {
        communs.push({
          icon: 'reader',
          label: 'PDF do orçamento',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'budget', contract.policy?.budget).finally(() =>
              loading.finishLoading(contract.id),
            )
          },
        })
      }

      if (contract.policy?.proposal && contract.policy?.budget) {
        communs.push({
          icon: 'reader',
          label: 'PDF da proposta',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'PROPOSAL', contract.policy?.budget).finally(() =>
              loading.finishLoading(contract.id),
            )
          },
        })
      }

      if (contract.policy?.id) {
        communs.push({
          icon: 'reader',
          label: 'PDF da apólice',
          onClick: () => {
            loading.setLoading(contract.id)
            handlePrint(contract.id, 'policy').finally(() => loading.finishLoading(contract.id))
          },
        })
      }
      break
    }

    case Products.CAPITALIZACAO: {
      if (contract?.metadata?.status === BudgetStatus.CAP_DRAFT) {
        communs.push({
          icon: 'pencil-outline',
          label: 'Editar orçamento',
          onClick: () => {
            history.push(`/contratos/${contract.id}`, {
              state: contract,
            })
          },
        })
      }

      if (contract?.metadata?.status === BudgetStatus.CAP_ISSUED) {
        communs.push({
          icon: 'reader',
          label: 'Welcome Kit',
          onClick: async () => {
            console.log(contract)
            try {
              toast('Fazendo download do welcome kit.')
              loading.setLoading(contract.id)
              await api.contracts.getWelcomeKit(contract.policy?.proposal?.toString())
              loading.finishLoading(contract.id)
            } catch (e) {
              toast('Não foi possível fazer o download do welcome kit.', {
                type: 'error',
              })
            }
          },
        })
      }
      break
    }
  }

  return communs
}

function renderStatus(contract) {
  return (
    <div>
      <Status product={contract.product as BudgetType} status={getContractStatus(contract)} />
      {contract.failSync && (
        <Typography variant="porto-text-caption-regular" color="#97453C" sx={{ marginTop: '4px' }}>
          Erro ao atualizar status
        </Typography>
      )}
    </div>
  )
}

function renderTenent(contract: Contract<any>) {
  const name: string =
    contract.payload?.customer?.name ||
    (contract.payload?.customers && contract.payload?.customers[0]?.name) ||
    contract.payload.customer?.companyName

  const socialName: string =
    contract.payload?.customer?.socialName ||
    (contract.payload?.customers && contract.payload?.customers[0]?.socialName)

  const hasSocialName = !!socialName

  return (
    <Grid>
      <Grid columns="repeat(2, max-content)" gap="8px">
        <Typography variant="porto-text-body-2-regular">
          {capitalize(socialName || name || '-')}
        </Typography>
        {hasSocialName && (
          <Info content="Este é o nome social do cliente e deve ser utilizado em todas as comunicações. O nome de registro, abaixo, é apenas para consulta." />
        )}
      </Grid>
      {hasSocialName && (
        <Typography variant="porto-text-label-regular" color="system-text-tertiary">
          {capitalize(name)}
        </Typography>
      )}
    </Grid>
  )
}

function getCreatedAt(contract) {
  return dayjs(contract.created_at).format('DD/MM/YYYY - HH[h]:mm')
}

function getStartsAt(contract: Contract<Products>) {
  let start: string | Date

  switch (contract.product) {
    case Products.ESSENCIAL:
      start = contract.payload?.periodContractTerm?.start
      break

    case Products.IMOBILIARIA:
      start = contract.payload?.contract?.period?.start
      break

    case Products.ALUGUEL:
      start = contract.payload?.contract?.periodContractTerm?.start
      break

    case Products.CAPITALIZACAO:
      start = contract.policy?.created_at
      break
  }

  if (!start) return '**/**/****'

  return new Date(start).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
}

function RenderPartner(contract: Contract<Products>) {
  const organization = useCurrentOrganization()

  return isBroker(organization) ? contract.partner : contract.broker
}

export const TABLE_COLUMNS: ITableColumn[] = [
  {
    label: 'Proponente',
    render: renderTenent,
  },
  {
    label: 'Proposta nº',
    render: contract => Number(contract?.policy?.proposal) || '-',
  },
  {
    label: 'Apólice nº',
    render: contract => Number(contract?.policy?.id) || '-',
  },
  {
    label: 'Parceiro',
    render: RenderPartner,
  },
  {
    label: 'Criado em',
    render: getCreatedAt,
  },
  {
    label: 'Início de vigência',
    render: getStartsAt,
  },
  {
    label: 'Status',
    render: renderStatus,
  },
]
