import { Forms, Grid } from '@/components'
import { api } from '@/services'
import { useDebounce } from '@/tools'
import { Box, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { RadioGroup } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useField } from 'formik'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { BaseCard } from '../../../../components/BaseCard'

export const Property = ({ addressType }) => {
  const [{ value: valueType }, , { setValue: setPropertyType }] = useField('estate.type')
  const [{ value: addressValue }, , { setValue }] = useField('estate.address')
  const [zipcodeField, zipcodeFieldMeta] = useField('estate.address.zipcode')

  const debouncedZipcode = useDebounce(zipcodeField.value)

  useEffect(() => {
    if (debouncedZipcode && !zipcodeFieldMeta.error) fetchAddress.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedZipcode])

  const fetchAddress = useMutation(async () => {
    const address = await api.addresses.getByZipcode(debouncedZipcode)

    setValue({
      ...addressValue,
      ...address,
    })

    return address
  })

  return (
    <BaseCard title="Imóvel" subtitle="Informe o endereço do imóvel que será alugado.">
      <Box
        sx={{
          display: 'grid',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', lg: 'repeat(3, 1fr)' },
            gap: '1rem',
          }}
        >
          <RadioGroup
            titleProps={{ content: 'Tipo de imóvel' }}
            initialChecked={valueType || 'RESIDENCIAL'}
            options={addressType.map(({ label, value }) => ({
              text: label,
              value,
            }))}
            onChange={setPropertyType}
            data-test-id="property-address-type"
          />
        </Box>
        <Grid columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}>
          <Forms.InputGroup
            name="estate.address.zipcode"
            label="CEP"
            placeholder="00000-000"
            mask="zipcode"
            loading={fetchAddress.isLoading}
            data-test-id="property-cep"
          />
        </Grid>
        {!zipcodeFieldMeta.error && !fetchAddress.isLoading && (
          <>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', lg: 'repeat(6, 1fr)' },
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridColumn: { xs: '1', lg: '1/4' },
                }}
              >
                <Forms.InputGroup
                  name="estate.address.street"
                  label="Rua"
                  placeholder="Digite a rua"
                  readOnly={fetchAddress.isSuccess && !!fetchAddress.data.street}
                  data-test-id="property-address-street"
                />
              </Box>
              <Forms.InputGroup
                name="estate.address.number"
                label="Número"
                type="number"
                placeholder="Digite"
                data-test-id="property-address-number"
              />
              <Box
                sx={{
                  display: 'grid',
                  gridColumn: { xs: '1', lg: '5/7' },
                }}
              >
                <Forms.InputGroup
                  name="estate.address.complement"
                  label="Complemento"
                  placeholder="ex.: ap 23"
                  data-test-id="property-address-complement"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', lg: 'repeat(3, 1fr)' },
                gap: '1rem',
              }}
            >
              <Forms.InputGroup
                name="estate.address.neighborhood"
                label="Bairro"
                placeholder="Digite o bairro"
                readOnly={fetchAddress.isSuccess && !!fetchAddress.data.neighborhood}
                data-test-id="property-address-neighborhood"
              />
              <Forms.InputGroup
                name="estate.address.city"
                label="Cidade"
                placeholder="Digite a cidade"
                readOnly={fetchAddress.isSuccess && !!fetchAddress.data.city}
                data-test-id="property-address-city"
              />
              <Forms.InputGroup
                name="estate.address.state"
                label="Estado"
                readOnly={fetchAddress.isSuccess && !!fetchAddress.data.state}
                data-test-id="property-address-state"
              />
            </Box>
          </>
        )}
      </Box>
    </BaseCard>
  )
}
