import { Budget } from '@/modules/@types/Budget'

export enum EProduct {
  PORTO_IMOBILIARIA = 'PORTO_IMOBILIARIA',
}

export interface IBatch {
  broker: {
    id: string
    susep: string
  }
  susep?: string
  budgets: Budget[]
  filename: string
  partner: string
  period_start: Date | undefined
  product: EProduct.PORTO_IMOBILIARIA
}

export const INITIAL_STATE: IBatch = {
  broker: {
    id: '',
    susep: '',
  },
  susep: '',
  budgets: [],
  filename: '',
  partner: '',
  period_start: undefined,
  product: EProduct.PORTO_IMOBILIARIA,
}

export const Types = {
  RESET_BATCHES: 'RESET_BATCHES',
  SET_BROKER: 'SET_BROKER',
  SET_BUDGETS: 'SET_BUDGETS',
  SET_FILENAME: 'SET_FILENAME',
  SET_PARTNER: 'SET_PARTNER',
  SET_PERIOD_START: 'SET_PERIOD_START',
}

export const batches = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case Types.RESET_BATCHES:
    case Types.SET_BROKER:
    case Types.SET_BUDGETS:
    case Types.SET_FILENAME:
    case Types.SET_PARTNER:
    case Types.SET_PERIOD_START:
      return { ...state, ...payload }

    default:
      return state
  }
}

export const resetBatches = () => {
  return {
    payload: {
      budgets: INITIAL_STATE.budgets,
      filename: INITIAL_STATE.filename,
    },
    type: Types.RESET_BATCHES,
  }
}

export function setBudgets(budgets) {
  return {
    payload: {
      budgets,
    },
    type: Types.SET_BUDGETS,
  }
}

export function setBroker(broker) {
  return {
    payload: {
      broker,
    },
    type: Types.SET_BROKER,
  }
}

export function setFilename(filename) {
  return {
    payload: {
      filename,
    },
    type: Types.SET_FILENAME,
  }
}

export function setPartner(partner) {
  return {
    payload: {
      partner,
    },
    type: Types.SET_PARTNER,
  }
}

export function setPeriodStart(period_start) {
  return {
    payload: {
      period_start,
    },
    type: Types.SET_PERIOD_START,
  }
}
