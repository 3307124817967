import { useField } from 'formik'
import { useEffect, useMemo } from 'react'
import { AutocompleteLocal } from '../AutocompleteLocal'
import { usePartnerOptions } from './hooks'
import { IRealEstatePartnershipCombo } from './interfaces'

export const RealEstatePartnershipCombo = ({
  disabled = false,
  selectionCallback,
}: IRealEstatePartnershipCombo) => {
  const [field, , helpers] = useField('partner')
  const partnerOptionsQuery = usePartnerOptions()

  const options = useMemo(() => {
    if (partnerOptionsQuery.isLoading || !partnerOptionsQuery.data) return []

    return partnerOptionsQuery.data?.map(e => e.id)
  }, [partnerOptionsQuery.data, partnerOptionsQuery.isLoading])

  const selected = useMemo(() => {
    return partnerOptionsQuery.data?.find(e => e.id === field.value)
  }, [field.value, partnerOptionsQuery.data])

  useEffect(() => {
    if (partnerOptionsQuery.isFetching) return

    const valid = options.some(value => value === field.value)

    if (!valid) helpers.setValue(undefined)
  }, [field.value, helpers, options, partnerOptionsQuery.isFetching])

  useEffect(() => {
    if (!selectionCallback) return

    selectionCallback(selected)
  }, [selected, selectionCallback])

  return (
    <AutocompleteLocal<string>
      label="Imobiliária"
      name="partner"
      id="real-estate-partnership-combo"
      getOptionLabel={value => {
        const option = partnerOptionsQuery.data?.find(e => e.id === value)

        if (!option) return ''

        return `${option.name} - ${option.id}`
      }}
      options={options}
      loading={partnerOptionsQuery.isLoading}
      disabled={disabled}
    />
  )
}
