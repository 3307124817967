import { useCurrentOrganization } from '@/modules'
import { loadRealEstateProductPartnershipSuseps } from '@/services/api/partnership'
import { useQuery, UseQueryOptions } from 'react-query'

type TypeData = Awaited<ReturnType<typeof loadRealEstateProductPartnershipSuseps>>

export function useSusepOptionsQuery(product: string, options?: UseQueryOptions<TypeData>) {
  const organization = useCurrentOrganization()

  const susepOptionsQuery = useQuery<TypeData>(
    `partnerships.real-estate.suseps.${product}`,
    () => loadRealEstateProductPartnershipSuseps(product),
    {
      // ? Tempo de vida do dado antes de uma nova busca 5min.
      staleTime: 1 * 60 * 1000,
      enabled: organization.type === 'REAL_ESTATE',
      ...options,
    },
  )

  return susepOptionsQuery
}
