import { AluguelPayment } from '@/modules/@types/Contract'
import { Contract } from '@/modules/contract/interface'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { api } from '@/services'
import { GetAllStatisticsResponse, ProfileTypes } from '@/services/api/contracts/types'
import { BRLMoneyToNumber } from '@/tools'
import { getOperationCodeFromPercent } from '@/tools/utils/commision'
import { Buffer } from 'buffer'
import cleanDeep from 'clean-deep'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { saveAs } from 'file-saver'
import printjs from 'print-js'

type Product = 'aluguel' | 'imobiliaria' | 'essencial' | 'capitalizacao'

dayjs.extend(utc)

function formatDate(date: string) {
  return dayjs(date).format('YYYY-MM-DD')
}

function operationCodeParser(percentage) {
  return getOperationCodeFromPercent(percentage) || percentage
}

function operationCodeAluguelParser(percentage: string, state: string) {
  const parseCodeStates = {
    RJ: {
      '10': '130',
      '11': '131',
      '12': '132',
      '13': '133',
      '14': '134',
      '15': '135',
      '16': '136',
      '17': '137',
      '18': '138',
      '19': '139',
      '20': '140',
      '21': '141',
      '22': '142',
      '23': '143',
      '24': '144',
      '25': '145',
      '26': '146',
      '27': '147',
      '28': '148',
      '29': '149',
      '30': '150',
      '31': '151',
      '32': '152',
      '33': '153',
      '34': '154',
      '35': '155',
    },
    RS: {
      '1': '60',
      '2': '61',
      '3': '62',
      '4': '63',
      '5': '64',
      '6': '65',
      '7': '66',
      '8': '67',
      '9': '68',
      '10': '69',
      '11': '70',
      '12': '71',
      '13': '72',
      '14': '73',
      '15': '74',
      '16': '75',
      '17': '76',
      '18': '77',
      '19': '78',
      '20': '79',
      '21': '80',
      '22': '81',
      '23': '82',
      '24': '83',
      '25': '84',
      '26': '85',
      '27': '86',
      '28': '87',
      '29': '88',
      '30': '89',
      '31': '90',
      '32': '91',
      '33': '92',
      '34': '93',
      '35': '94',
    },
    ALL: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      '14': '14',
      '15': '15',
      '16': '16',
      '17': '17',
      '18': '18',
      '19': '19',
      '20': '20',
      '21': '21',
      '22': '22',
      '23': '23',
      '24': '24',
      '25': '25',
      '26': '26',
      '27': '27',
      '28': '28',
      '29': '29',
      '30': '30',
      '31': '31',
      '32': '32',
      '33': '33',
      '34': '34',
      '35': '35',
    },
  }

  const validateState = state === 'RJ' || state === 'RS' ? state : 'ALL'

  return parseCodeStates[validateState][percentage]
}

async function contractParse(type: Product, payload) {
  const {
    partner,
    susep,
    contract_plan,
    contract_commission,
    estate_type,
    estate_address,
    coverages,
    renewal_number,
    renewal_policy_id,
  } = payload

  return cleanDeep({
    product: {
      aluguel: 'PORTO_ALUGUEL',
      essencial: 'PORTO_ESSENCIAL',
      imobiliaria: 'PORTO_IMOBILIARIA',
      capitalizacao: 'PORTO_CAPITALIZACAO',
    }[type],
    ...(await {
      aluguel: () => {
        const customerParse = ({ birthdate, employment, ...customer }, financial: boolean) => {
          customer.id && delete customer.id

          const parse: { [key: string]: any } = {
            ...customer,
            birthdate: birthdate.split('/').reverse().join('-'),
          }

          parse.employment = {
            ...employment,
            salary: BRLMoneyToNumber(employment.salary),
            incomes: BRLMoneyToNumber(employment.incomes),
          }

          return parse
        }

        return {
          broker: susep,
          partner: partner,
          payload: {
            contract: {
              plan: contract_plan.toUpperCase(),
              operation_code: operationCodeAluguelParser(contract_commission, estate_address.state),
              readjustment: payload.estate_readjustmentIndex,
              period: {
                start: formatDate(payload.contract_period_start),
                end: formatDate(payload.contract_period_end),
              },
              periodContractTerm: {
                start: formatDate(payload.validity_period_start),
                end: formatDate(payload.validity_period_end),
              },
            },
            customers: [
              {
                ...customerParse(payload.suitors_main, true),
                financial: true,
              },
              ...payload.suitors_additionals.map(customer =>
                customerParse(customer, customer.financial),
              ),
            ],
            estate: {
              type: estate_type,
              reason: payload.estate_reason,
              address: estate_address,
            },
            coverages: {
              ...coverages,
              ...payload.coverages_additionals,
            },
          },
        }
      },
      essencial: async () => {
        const customers = payload.customers.map(customer => {
          return {
            ...customer,
            document: customer.document.replace(/\D/g, ''),
            birthdate: customer.birthdate.split('/').reverse().join('-'),
            email: customer.email,
            phone: customer.phone.replace(/\D/g, ''),
          }
        })

        const coverages_main = { rent: '' }
        Object.entries(payload.coverages?.coverages_main).forEach(
          ([key, value]: [string, string]) => {
            coverages_main[key] = !!value ? BRLMoneyToNumber(value) : undefined
          },
        )

        return {
          broker: susep,
          partner: partner,
          payload: {
            customers,
            address: payload.address,
            rent_due_date: payload.rent_due_date,
            coverages: {
              rent: coverages_main.rent,
              coverages_main: {
                ...coverages_main,
              },
              coverages_additionals: payload.coverages?.coverages_additionals,
            },
            period: {
              start: formatDate(payload.contract_period_start),
              end: formatDate(payload.contract_period_end),
            },
            periodContractTerm: {
              start: formatDate(payload.validity_period_start),
              end: formatDate(payload.validity_period_end),
            },
            readjustment: payload.estate_readjustment,
            payment: {
              comission: payload.comission,
            },
          },
        }
      },
      imobiliaria: () => {
        return {
          broker: susep,
          partner,
          renewal_number,
          renewal_policy_id,
          payload: {
            contract: {
              plan: contract_plan.toUpperCase(),
              discount:
                payload?.contract_discount && typeof payload.contract_discount === 'string'
                  ? Number(payload.contract_discount.replace('%', ''))
                  : payload?.contract_discount,
              period: {
                start: formatDate(payload.contract_period_start),
                end: formatDate(payload.contract_period_end),
              },
              service_clause: payload.contract_clause,
              prolabore: payload.contract_prolabore,
              operation_code: operationCodeParser(contract_commission),
              beneficiaries: payload.beneficiaries || [],
            },
            customer: {
              document: payload.customer_document.replace(/\D/g, ''),
              name: payload.customer_name,
              socialName: payload.customer_social_name,
              email: payload.customer_email,
              phone: payload.customer_phone.replace(/\D/g, ''),
              birthdate: payload.customer_birthdate?.split('/').reverse().join('-'),
              company: {
                type: payload.customer_company_type,
                netEquity: payload.customer_company_netEquity,
                grossIncome: payload.customer_company_grossIncome,
                controllers: payload.customer_company_controllers,
              },
              civilStatus: payload.civil_status,
              customerIncome: payload.customer_income,
              customerOccupation: payload.customer_occupation,
              customerActivityArea: payload.customer_activity_area,
              customerPep: payload.customer_pep,
              pepDetails: {
                name: payload.pep_name,
                cpf: payload.pep_cpf?.replace(/\D/g, ''),
                relationshipType: payload.pep_relationship_type,
                nationality: payload.pep_nationality,
                brazilianResident: payload.pep_brazilian_resident,
                document: {
                  type: payload.pep_document_type,
                  code: payload.pep_document,
                  dispatcher: payload.pep_document_dispatcher,
                  expeditionDate: payload.pep_document_expedition_date
                    ?.split('/')
                    .reverse()
                    .join('-'),
                  expirationDate: payload.pep_document_expiration_date
                    ?.split('/')
                    .reverse()
                    .join('-'),
                },
              },
              companyName: payload.company_name,
              companyActivityArea: payload.company_activity_area,
              foundationDate: payload.foundation_date?.split('/').reverse().join('-'),
              companyPhone: payload.company_phone?.replace(/\D/g, ''),
              companyEmail: payload.company_email,
            },
            estate: {
              type: estate_type,
              address: estate_address,
              owner: payload.estate_owner,
            },
            coverages,
          },
        }
      },
      capitalizacao: () => ({
        broker: susep,
        partner: partner,
        payload: {
          customer: payload.customer,
          contract: payload.contract,
        },
      }),
    }[type]?.()),
  })
}

export async function create(product: Product, payload) {
  const response = await api.instance.v2.post('/contracts', await contractParse(product, payload))

  return response.data
}

export async function removeRenewal(id: string) {
  try {
    await api.instance.v2.delete(`/contracts/${id}/renewal`)
    return
  } catch (error) {
    throw error
  }
}

export async function update(id: string, payload) {
  try {
    const response = await api.instance.v2.patch(`/contracts/${id}`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function payment(id: string, payload) {
  try {
    const response = await api.instance.v2.put(`/contracts/${id}/payment`, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function recalculate(id: string, payload) {
  const response = await api.instance.v2.put(`/contracts/${id}/recalculate`, payload)

  return response.data
}

export async function get(id: string) {
  try {
    const response = await api.instance.v2.get(`/contracts/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export async function order(id: string, payload) {
  try {
    const { debit, card, budget, method, installment } = payload.payment

    const parsePayment: AluguelPayment = {
      budget: budget,
      method: method,
      installment: installment,
    }

    if (!!card) {
      parsePayment.card = {
        ...card,
        number: card.number.replace(/\D/g, ''),
      }
    }

    if (!!debit) {
      parsePayment.debit = {
        ...debit,
        document: debit.document.replace(/\D/g, ''),
      }
    }

    const response = await api.instance.v2.post(`/contracts/${id}/orders`, parsePayment)

    return response.data
  } catch (error) {
    throw error
  }
}

export async function calculate(id: string) {
  try {
    const response = await api.instance.v2.post(`/contracts/${id}/budgets`)
    return response.data
  } catch (error) {
    throw error
  }
}

function downloadPdf(pdf, name) {
  const base64 = Buffer.from(pdf, 'binary').toString('base64')

  return printjs({
    printable: base64,
    showModal: true,
    base64: true,
    type: 'pdf',
    onError: () => {
      const blob = new Blob([pdf], {
        type: 'application/pdf',
      })
      saveAs(blob, `${name}.pdf`)
    },
  })
}

export async function print(
  id: string,
  type: 'proposal' | 'letter' | 'ticket' | 'pac' | 'budget' | 'refused' | 'approve' | string,
  budget?: string | number | (string | number)[],
  paymentMethods?: string | string[],
) {
  try {
    const { data: pdf } = await api.instance.v2.get(`/contracts/${id}/pdf`, {
      responseType: 'arraybuffer',
      params: {
        type: type.toUpperCase(),
        budget: budget,
        payment_methods: paymentMethods,
      },
    })

    downloadPdf(pdf, id)
  } catch (error) {
    throw error
  }
}

export async function getWelcomeKit(proposal: string) {
  try {
    const { data: pdf } = await api.instance.v2.get(`/welcome-kit/pdf`, {
      responseType: 'arraybuffer',
      params: { proposal },
    })

    downloadPdf(pdf, 'welcome-kit')
  } catch (error) {
    throw error
  }
}

export async function getStatistics(currentProfile: ProfileTypes) {
  const now = dayjs().utc(false)

  const start = now
    .set('date', 1)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toISOString()

  const end = now
    .set('date', now.daysInMonth())
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 999)
    .toISOString()

  const response = await api.instance.v2.get<GetAllStatisticsResponse>('/contracts/statistics', {
    params: {
      start,
      end,
      ...{
        BROKER: {
          susep: currentProfile.metadata.susep?.porto.join(),
        },
        REAL_ESTATE: {
          partner: currentProfile.id,
        },
      }[currentProfile.type],
    },
  })

  return response.data
}

export async function sync(contract: any) {
  const response = await api.instance.v2.post('/contracts/sync', contract)

  return response.data
}

export async function listContracts(params) {
  const { data } = await api.instance.v2.get<Contract<Products>[]>('/contracts', { params })

  return data
}
