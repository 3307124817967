import { useState } from 'react'
import { Flex, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Benefit } from './_Benefit'
import { Modal } from './_Modal'
import { IBenefitsProps } from './interfaces'

export const Benefits = ({ benefits, advantages, rescuePercentage }: IBenefitsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleAction = {
    residential_services: () => setIsModalOpen(true),
  }

  return (
    <>
      <Flex
        sx={{
          flexWrap: 'wrap',
          flexDirection: 'column',
          gap: '1.5rem',
          backgroundColor: 'system-surface-secondary',
          borderRadius: '8px',
          padding: '1.25rem 1rem',
          marginTop: { xs: '1rem', lg: '0' },
        }}
      >
        <Typography
          content="Vantagens"
          variant="porto-title-6-medium"
          color="porto-primitive-black-85"
        />
        {benefits.map(values => (
          <Benefit
            key={`benefits_benefit_${values.title}`}
            {...values}
            rescuePercentage={rescuePercentage}
            onClickAction={() => handleAction[values.action.type]()}
          />
        ))}

        <Typography
          content="Benefícios"
          variant="porto-title-6-medium"
          color="porto-primitive-black-85"
        />
        {advantages.map(values => (
          <Benefit
            key={`benefits_benefit_${values.title}`}
            {...values}
            rescuePercentage={rescuePercentage}
            onClickAction={() => handleAction[values.action.type]()}
          />
        ))}
      </Flex>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  )
}
