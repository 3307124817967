import { BrowserRouter } from 'react-router-dom'

import {
  AuthProvider,
  SplashProvider,
  handlePersonalization,
  personalizationStore,
} from '@/modules'

import { useUnit } from 'effector-react'
import { useEffect } from 'react'
import { Renderer } from './renderer'

export default function Router() {
  const [{ url_prefix }, handlePersonalizationFn] = useUnit([
    personalizationStore,
    handlePersonalization,
  ])

  useEffect(() => {
    handlePersonalizationFn()
  }, [handlePersonalizationFn])

  const basename = url_prefix ? `/${url_prefix}` : '/'

  return (
    <BrowserRouter basename={basename} key={basename}>
      <AuthProvider>
        <SplashProvider>
          <Renderer />
        </SplashProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}
