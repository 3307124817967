import dot from 'dot-object'
import { FormikProvider, useFormik } from 'formik'
import { createContext, useEffect, useMemo, useState } from 'react'
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom'

import { Actions, Conditional, Content, Grid, Modals, toast } from '@/components'
import { api } from '@/services'

import { useCurrentOrganization, useCurrentUser } from '@/modules'

import Activity from '../components/Activity'
import Heading from '../components/Heading'
import SettingsHeading from '../components/SettingsHeading'
import products from '../products'

import Form from './Form'
import { validationSchema } from './schema'

import { Partnership } from '@/modules/@types/Partnership'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { useMutation } from 'react-query'
import * as S from './styles'
import { dashText } from '@/tools'

export const SettingsContext = createContext(null)

const initialDetails = {
  id: '',
  created_at: '',
  name: '',
  prolabore_enabled: false,
  suseps: [],
  settings: {} as Partnership.Settings,
  activities: [],
}

type Details = typeof initialDetails

const Settings = () => {
  const currentOrganization = useCurrentOrganization()

  const location = useLocation()
  const user = useCurrentUser()

  const match = useRouteMatch<{ id: string; partner: string }>()

  const partner = useMemo(() => match.params.partner, [match.params.partner])

  const isDefaultSettings = useMemo(() => partner === 'default', [partner])

  const [details, setDetails] = useState(initialDetails)

  const form = useFormik({
    initialValues: details.settings,
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: async values => {
      dot.keepArray = true

      values.porto_essencial.enabled =
        values.porto_aluguel.traditional.enabled =
        values.porto_aluguel.university.enabled =
          values.porto_aluguel.basic.enabled

      values.porto_essencial.payments.portoSeguroCard.enabled =
        values.porto_essencial.payments.creditCard.enabled

      values.porto_essencial.suseps = values.porto_aluguel.suseps

      try {
        if (
          values.porto_aluguel.basic.enabled &&
          !values.porto_essencial.payments.invoiceWithoutDown.enabled &&
          !values.porto_essencial.payments.creditCard.enabled
        ) {
          toast('Selecione ao menos uma forma de pagamento!', {
            type: 'error',
          })
        } else {
          await api.organizations.partnerships.update(
            currentOrganization.id,
            isDefaultSettings ? 'default' : match.params.partner,
            {
              settings: dot.dot(values),
            },
          )

          toast('Configurações salvas com sucesso!', {
            type: 'success',
          })
        }
      } catch (error) {
        toast('Ocorreu um erro ao salvar as configurações!', {
          type: 'error',
        })
      } finally {
        form.resetForm({ values: values })
        await fetchDetailsMutation.mutateAsync()
      }
    },
  })

  const helpers = {
    details,
    organization: currentOrganization,
    getValue: name => dot.pick(name, form.values),
  }

  const viewOnly = useMemo(() => {
    return {
      enabled: currentOrganization.type === 'REAL_ESTATE',
      fields: [
        'porto_imobiliaria.residential.comission',
        'porto_imobiliaria.residential.prolabore',
        'porto_imobiliaria.commercial.comission',
        'porto_imobiliaria.commercial.prolabore',
        'porto_aluguel.basic.comission',
        'porto_aluguel.university.comission',
        'porto_aluguel.traditional.comission',
      ],
    }
  }, [currentOrganization.type])

  function handleSetDetails(values: Partial<Details>) {
    setDetails(c => ({
      ...c,
      ...values,
      settings: {
        ...values.settings,
        porto_aluguel: {
          ...values.settings?.porto_aluguel,
          basic: {
            ...values.settings?.porto_aluguel?.basic,
            suseps: values.settings?.porto_aluguel?.basic?.suseps?.filter(s =>
              values.suseps.includes(s),
            ),
          },
          traditional: {
            ...values.settings?.porto_aluguel?.traditional,
            suseps: values.settings?.porto_aluguel?.traditional?.suseps?.filter(s =>
              values.suseps.includes(s),
            ),
          },
          university: {
            ...values.settings?.porto_aluguel?.university,
            suseps: values.settings?.porto_aluguel?.university?.suseps?.filter(s =>
              values.suseps.includes(s),
            ),
          },
          suseps: values.settings?.porto_aluguel?.suseps?.filter(s => values.suseps.includes(s)),
        },
        porto_essencial: {
          ...values.settings?.porto_essencial,
          suseps: values.settings?.porto_essencial?.suseps?.filter(s => values.suseps.includes(s)),
        },
        porto_imobiliaria: {
          ...values.settings?.porto_imobiliaria,
          suseps: values.settings?.porto_imobiliaria?.suseps?.filter(s =>
            values.suseps.includes(s),
          ),
        },
      },
    }))
  }

  const fetchDetailsMutation = useMutation(async () => {
    const [organization, partnership] = await Promise.all([
      api.organizations.get(currentOrganization.id),
      api.organizations.partnerships.get(
        currentOrganization.id,
        isDefaultSettings ? 'default' : partner,
      ),
    ])

    const suseps = partnership.suseps as string[]

    const settingsParsed = dot.object(
      Object.assign({}, partnership.settings),
    ) as Partnership.Settings

    let details = organization

    if (!isDefaultSettings) {
      if (organization.type === 'BROKER') {
        details = await api.partners.get(partner)
      } else {
        details = await api.brokers.get(partner)
      }
    }

    handleSetDetails({
      id: details.id,
      created_at: details.created_at || partnership.created_at,
      name: details.name,
      settings: settingsParsed,
      suseps: suseps,
      activities: partnership.changelogs,
      prolabore_enabled: isDefaultSettings ? true : partnership.prolabore_enabled,
    })
  })

  const handleReset = async () => {
    let reset: boolean | undefined

    if (isDefaultSettings) {
      reset = await Modals.Confirmation({
        icon: 'rotate-ccw',
        title: 'Quer mesmo restaurar as configurações iniciais?',
        content: `Esta ação vai restaurar as configurações de todos os produtos, conforme o primeiro acesso ao Portal. Isso afetará os parceiros que estão com configurações padrão de <strong>${details.name}</strong> aplicadas.`,
        submitText: 'Restaurar configurações iniciais',
      })
    } else {
      reset = await Modals.Confirmation({
        icon: 'check-circle',
        title: 'Quer mesmo aplicar suas configurações padrão para este parceiro?',
        content: `Esta ação afetará <strong>todos os produtos </strong> personalizados de ${match.params.partner}.`,
        submitText: 'Aplicar configurações padrão',
      })
    }

    if (!reset) return

    handleResetMutation.mutate()
  }

  const handleResetMutation = useMutation(async () => {
    await api.organizations.partnerships.restoreSettings(
      currentOrganization.id,
      isDefaultSettings ? 'default' : match.params.partner,
    )

    await fetchDetailsMutation.mutateAsync()

    toast('Configurações padrão restauradas com sucesso.', {
      type: 'success',
    })
  })

  const applySettingsToAllPartners = async () => {
    const reset = await Modals.Confirmation({
      icon: 'check-circle',
      title: 'Quer mesmo aplicar suas configurações padrão a todos os parceiros?',
      content: `Esta ação afetará <strong>todos os parceiros vinculados à ${details.name}.</strong>`,
      submitText: 'Aplicar configurações padrão',
    })

    if (!reset) return

    applySettingsToAllPartnersMutation.mutateAsync()
  }

  const applySettingsToAllPartnersMutation = useMutation(async () => {
    const partners = await api.organizations.partnerships.list({
      [currentOrganization.type === 'BROKER' ? 'broker' : 'partner']: currentOrganization.id,
    })

    const partnersIds = new Set(partners.map(({ partner }) => partner.id)) as Set<string>

    await api.organizations.partnerships.applyDefaultSettings(
      currentOrganization.id,
      Array.from(partnersIds),
    )

    form.resetForm()

    await fetchDetailsMutation.mutateAsync()

    toast('As configurações foram aplicadas a todos os parceiros.', {
      type: 'success',
    })
  })

  useEffect(() => {
    fetchDetailsMutation.mutate()
    form.validateForm()
  }, [])

  const showButtons = currentOrganization.type === 'BROKER'

  if (fetchDetailsMutation.isLoading) {
    return (
      <Content.Loader
        message={`Aguarde, estamos buscando as configurações ${isDefaultSettings ? 'padrão' : 'deste parceiro'}.`}
      />
    )
  }

  if (handleResetMutation.isLoading) {
    return (
      <Content.Loader
        message={`Aguarde, estamos ${isDefaultSettings ? 'restaurando as configurações iniciais' : 'aplicando a configurações padrão'}.`}
      />
    )
  }

  if (applySettingsToAllPartnersMutation.isLoading) {
    return (
      <Content.Loader
        message={`Aguarde, estamos aplicando a configuração padrão em todos os parceiros.`}
      />
    )
  }

  return (
    <SettingsContext.Provider
      value={{
        helpers,
        details,
        user,
        organization: currentOrganization,
        isDefaultSettings,
        viewOnly,
      }}
    >
      <FormikProvider value={form}>
        <S.Form>
          <SettingsHeading>
            {showButtons && (
              <>
                <Actions.Button
                  onClick={handleReset}
                  type="button"
                  variant={isDefaultSettings ? 'primary' : 'action'}
                  kind="ghost"
                  data-test-id="aplly-config"
                >
                  {isDefaultSettings
                    ? 'Restaurar configurações iniciais'
                    : 'Aplicar configurações padrão'}
                </Actions.Button>

                {isDefaultSettings && (
                  <Actions.Button
                    kind="ghost"
                    type="button"
                    onClick={applySettingsToAllPartners}
                    isDisabled={!form.isValid}
                    data-test-id="aplly-to-all-partners"
                  >
                    Aplicar a todos os parceiros
                  </Actions.Button>
                )}
              </>
            )}
          </SettingsHeading>

          {viewOnly.enabled && (
            <S.Message kind="warning" title="Atenção" data-test-id="msg-only-view">
              Somente visualização.
            </S.Message>
          )}

          <Grid columns={['1fr', 'max-content 1fr']} gap={['48px', '16px']}>
            <S.Aside.Box>
              <S.Aside.Links data-test-id="aside-menu">
                {products.map(product => (
                  <S.Aside.Link
                    to={match.url + product.path}
                    data-test-id={'produto-' + dashText(product.title)}
                  >
                    {product.title}
                  </S.Aside.Link>
                ))}
                <S.Aside.Link to={match.url + '/historico'} data-test-id="historic">
                  Histórico de alterações
                </S.Aside.Link>
              </S.Aside.Links>
            </S.Aside.Box>

            <S.Content data-test-id="content">
              <Route exact path={match.path}>
                <Redirect to={`${match.url}/imobiliaria`} />
              </Route>

              <Route exact component={Activity} path={match.path + '/historico'} />

              {products.map((product, index) => {
                return (
                  <>
                    <Route exact path={match.path + product.path} key={index}>
                      <Heading
                        removeBackButton
                        title={product.title}
                        description={product.description}
                      />

                      {product.title === 'Fiança Locatícia' && (
                        <Form fields={['porto_aluguel.basic.enabled']} />
                      )}

                      <S.Nav.List>
                        {product.subroute.map(item => (
                          <S.Nav.Item
                            to={match.url + product.path + item.path}
                            data-test-id={'config-' + dashText(item.title)}
                          >
                            {item.title}
                          </S.Nav.Item>
                        ))}
                      </S.Nav.List>
                    </Route>

                    {product.subroute.map((subroute, index) => {
                      return (
                        <Route exact key={index} path={match.path + product.path + subroute.path}>
                          <>
                            <Heading
                              title={subroute.label ?? subroute.title}
                              description={subroute.description}
                              backLink={match.url + product.path}
                            />

                            <Form fields={subroute.fields} />
                          </>
                        </Route>
                      )
                    })}
                  </>
                )
              })}

              {Object.keys(form.errors).map(
                key =>
                  form.errors[key].suseps && (
                    <Typography
                      key={key}
                      variant="porto-text-caption-regular"
                      color="system-text-negative"
                    >
                      {form.errors[key].suseps}
                    </Typography>
                  ),
              )}
            </S.Content>
          </Grid>

          {showButtons && form.dirty && (
            <S.Footer>
              <Actions.Button
                kind="ghost"
                onClick={() => form.resetForm()}
                data-test-id="cancel-btn"
              >
                Cancelar
              </Actions.Button>

              <Actions.Button
                type="submit"
                isLoading={form.isSubmitting}
                isDisabled={!form.isValid}
                data-test-id="save-btn"
              >
                Salvar
              </Actions.Button>
            </S.Footer>
          )}
        </S.Form>
      </FormikProvider>
    </SettingsContext.Provider>
  )
}

export default Settings
