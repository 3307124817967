import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Icon } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { Forms } from '@/components'

import { AddressForm, Card } from './../../../ui'
import { ENUMS } from './../../../context'

const JuridicalPersonForm = ({ form }) => {
  return (
    <>
      <Card>
        <Typography variant="porto-title-4-semibold" content="Imobiliária" />

        <Box
          sx={{
            marginTop: '2rem',
          }}
        >
          <Typography variant="porto-title-6-medium" content="Dados básicos" />
        </Box>

        <Flex
          sx={{
            marginTop: '1rem',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: 'repeat(3, 1fr)',
              },
              gap: '1rem',
            }}
          >
            <Forms.InputGroup
              name="policyholder.creci"
              label="Número de inscrição do CRECI"
              placeholder="Digite"
            />
            <Forms.InputGroup
              name="policyholder.companyName"
              label="Razão social"
              placeholder="Digite"
            />
          </Box>

          <Card
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '1rem',
              gap: '.5rem',
              backgroundColor: 'system-primitive-off-white',
              borderColor: 'transparent',
            }}
          >
            <Icon name="idea" color="system-surface-action-primary" size="md" />
            <Typography
              variant="porto-text-body-2-regular"
              content="Para receber o pró-labore é necessário o preenchimento da inscrição municipal."
            />
          </Card>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: 'repeat(3, 1fr)',
              },
              gap: '1rem',
            }}
          >
            <Forms.SelectGroup
              name="policyholder.companyType"
              label="Tipo de empresa"
              options={[
                {
                  label: 'Selecione o tipo de empresa',
                  value: '',
                },
                ...Object.entries(ENUMS.COMPANY_TYPE).map(([value, label]) => ({ label, value })),
              ]}
            />
            <Forms.SelectGroup
              name="policyholder.patrimony"
              label="Patrimônio"
              options={[
                {
                  label: 'Selecione o patrimônio',
                  value: '',
                },
                ...Object.entries(ENUMS.PATRIMONY).map(([value, label]) => ({ label, value })),
              ]}
            />
            <Forms.SelectGroup
              name="policyholder.optingSimplesNacional"
              label="Optante pelo Simples Nacional"
              options={[
                {
                  label: 'Selecione se é optante pelo Simples Nacional',
                  value: '',
                },
                ...Object.entries(ENUMS.OPTING_SIMPLES_NACIONAL).map(([value, label]) => ({
                  label,
                  value,
                })),
              ]}
            />
          </Box>
        </Flex>

        <Box
          sx={{
            marginTop: '2rem',
          }}
        >
          <Typography variant="porto-title-6-medium" content="Endereço" />
        </Box>

        <Box
          sx={{
            marginTop: '1rem',
          }}
        >
          <AddressForm formField="policyholder.address" />
        </Box>
      </Card>

      <Card
        sx={{
          marginTop: '2rem',
        }}
      >
        <Typography variant="porto-title-4-semibold" content="Representante legal" />

        <Box
          sx={{
            marginTop: '2rem',
          }}
        >
          <Typography
            variant="porto-title-6-medium"
            content="Administrador/Procurador/Representante legal"
          />
        </Box>

        <Flex
          sx={{
            marginTop: '1rem',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: 'repeat(3, 1fr)',
              },
              gap: '1rem',
            }}
          >
            <Forms.InputGroup
              name="policyholder.representative.cpf"
              label="CPF"
              placeholder="Digite"
              mask="cpf"
            />
            <Forms.InputGroup
              name="policyholder.representative.name"
              label="Nome completo"
              placeholder="Digite"
            />
            <Forms.InputGroup
              name="policyholder.representative.email"
              label="E-mail"
              placeholder="Digite"
              type="email"
            />
          </Box>

          <Box
            sx={{
              marginTop: '1rem',
            }}
          >
            <Forms.Choice
              type="checkbox"
              name="policyholder.representative.foreign"
              label="Estrangeiro"
            />
          </Box>

          <Box
            sx={{
              marginTop: '1rem',
            }}
          >
            <AddressForm formField="policyholder.representative.address" />
          </Box>
        </Flex>
      </Card>
    </>
  )
}

export default JuridicalPersonForm
