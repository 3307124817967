import React from 'react'
import Ink from 'react-ink'

import { IButtonProps } from './interfaces'

import { Container, FeatherIcon, Loader, PortoIcon } from './styles'

const Button: React.FC<IButtonProps> = props => {
  return (
    <Container
      disabled={props.isLoading || props.isDisabled}
      {...props}
      data-test-id={props['data-test-id']}
    >
      <Ink />
      {/* TODO: Criar componente genérico de ícone */}
      {!!props.icon && (
        <>
          {props.isFeatherIcons ? (
            <FeatherIcon name={props.icon} />
          ) : (
            <PortoIcon size="xlarge" icon={props.icon} />
          )}
        </>
      )}

      {!!props.children && props.children}

      {props.isLoading && <Loader />}
    </Container>
  )
}

Button.defaultProps = {
  forwardedAs: 'button',
  variant: 'action',
  hover: 'primaryHover',
  iconLeft: false,
  isFeatherIcons: true,
}

export default Button
