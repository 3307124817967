import { Autocomplete, Forms, Grid } from '@/components'
import { getOccupations } from '@/services/api/capitalizacao'

import { ISelectGroupOption } from '@/components/Forms/SelectGroup/interfaces'
import { SocialNameField } from '@/components/Forms/SocialNameField'
import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import { api } from '@/services'
import { useFeatureEnable } from '@/tools'
import { useEnums } from '@/tools/hooks/capitalizacao'
import { useCountries } from '@/tools/hooks/capitalizacao/useCountries'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import { isCNPJ, isCPF } from 'brazilian-values'
import { FieldArray, useField, useFormikContext } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { BaseCard } from '../../../../components/BaseCard'
import { Socios } from '../../../../components/Socios'

export const Tenant = () => {
  const { data: enums } = useEnums()
  const { setFieldValue } = useFormikContext()

  const [{ value: customerValue }, , { setValue: setCustomerValue }] = useField('customer')
  const [documentField, documentFieldState] = useField<string>('customer.document')
  const [stateRegistrationIsentField] = useField<boolean>('customer.stateRegistrationIsent')

  const { value: occupationValue } = useField('customer.occupation')[0]

  const [showForm, setShowForm] = useState(documentFieldState.touched)

  const getCustomerMutation = useMutation(async () => {
    try {
      if (documentFieldState.error) return

      const results = await api.customers.getByDocument(documentField.value).catch(() => null)
      const hasPartnersOnBureau = isCNPJ(documentField.value) && !!results?.hasPartnersOnBureau

      setCustomerValue({
        ...customerValue,
        name: results?.name || results?.companyName,
        phone: results?.phone,
        birthdate: results?.birthdate || results?.foundation_date,
        hasPartnersOnBureau,
        socios: isCNPJ(documentField.value) && !hasPartnersOnBureau ? [''] : null,
      })
    } finally {
      setShowForm(true)
    }
  })

  const formToShow = useMemo(() => {
    if (!showForm) return
    if (documentFieldState.error) return
    if (!documentFieldState.touched) return
    if (getCustomerMutation.isLoading) return

    if (isCPF(documentField.value)) return 'PF'

    if (isCNPJ(documentField.value)) return 'PJ'

    return
  }, [
    documentField.value,
    documentFieldState.error,
    documentFieldState.touched,
    getCustomerMutation.isLoading,
    showForm,
  ])

  const featureEnable = useFeatureEnable()

  const incomeBracketId = useMemo(() => {
    const options: ISelectGroupOption[] =
      enums?.incomeBracket?.filter(
        ({ value }) => value !== 'NO_INCOME' && value !== 'DO_NOT_WISH_TO_INFORM',
      ) || []

    options.unshift({ label: 'Selecione', value: '' })

    const defaultValue = options[0]?.value

    return { options, default: defaultValue }
  }, [enums?.incomeBracket])

  useEffect(() => {
    if (customerValue?.strange === '0') setFieldValue('customer.nationalityId', 5)
  }, [customerValue?.strange, setFieldValue])

  useEffect(() => {
    if (stateRegistrationIsentField.value) setFieldValue('customer.stateRegistration', '')
  }, [setFieldValue, stateRegistrationIsentField.value])

  const countries = useCountries()

  const countriesOptions = useMemo(
    () =>
      countries.data?.map(country => ({
        label: capitalize(country.name),
        value: country.id,
      })),
    [countries.data],
  )

  const disabledNationalityId = useMemo(
    () => !Number(customerValue?.strange),
    [customerValue?.strange],
  )

  return (
    <BaseCard title="Dados do inquilino">
      <Grid gap="16px">
        <Grid columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap="16px">
          <Forms.InputGroup
            name="customer.document"
            label="CPF/CNPJ"
            placeholder="300.300.300-30"
            mask="document"
            showRequired
            onChange={() => setShowForm(false)}
            onBlur={() => getCustomerMutation.mutate()}
            readOnly={getCustomerMutation.isLoading}
            loading={getCustomerMutation.isLoading}
            data-test-id="consumer-document"
          />
        </Grid>

        <AnimateVerticalShow isOpen={formToShow === 'PJ'}>
          <Grid gap="16px">
            <Grid columns={['1fr', '1fr', '2fr 1fr']} gap="16px">
              <Forms.InputGroup
                name="customer.name"
                label="Razão social"
                placeholder="Digite"
                showRequired
                data-test-id="consumer-company-name"
              />
            </Grid>

            <Grid columns={['1fr', '1fr 2fr']} gap="16px">
              <Forms.InputGroup
                label="Telefone ou celular"
                name="customer.phone"
                showRequired
                mask="phone"
                placeholder="(00) 90000-0000"
                data-test-id="consumer-phone"
              />
              <Forms.InputGroup
                label="E-mail"
                name="customer.email"
                showRequired
                type="email"
                placeholder="exemplo@portoseguro.com.br"
                data-test-id="consumer-email"
              />
            </Grid>

            <Grid columns={['1fr', '1fr', 'repeat(3, 1fr)']} gap="16px">
              <Forms.SelectGroup
                name="customer.nationalityId"
                label="País de origem"
                // disabled={disabledNationalityId}
                defaultValue={5}
                options={countriesOptions}
                data-test-id="consumer-country-origin"
              />
            </Grid>

            <Grid columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
              <Forms.InputGroup
                name="customer.revenue"
                mask="money"
                placeholder="R$ 40.000"
                label="Faturamento mensal"
                showRequired
                data-test-id="consumer-monthly-billing"
              />
            </Grid>

            {!customerValue.hasPartnersOnBureau && (
              <FieldArray
                name="customer.socios"
                render={helpers =>
                  helpers.form.values.customer?.socios?.map((_, index) => {
                    return (
                      <Socios
                        entity="cedente"
                        key={`partner-${index}`}
                        index={index}
                        {...helpers}
                      />
                    )
                  })
                }
                data-test-id="consumer-partners"
              />
            )}
          </Grid>
        </AnimateVerticalShow>

        <AnimateVerticalShow isOpen={formToShow === 'PF'}>
          <Grid gap="16px">
            <Grid columns={['1fr', '1fr', '2fr 1fr']} gap="16px">
              <Forms.InputGroup
                name="customer.name"
                showRequired
                label="Nome Completo"
                placeholder="Digite"
                data-test-id="consumer-fullname"
              />
            </Grid>

            {featureEnable.socialNameProducts.socialNameCapitalizacao && (
              <AnimateVerticalShow isOpen={isCPF(customerValue.document)}>
                <SocialNameField textFieldName="customer.socialName" />
              </AnimateVerticalShow>
            )}

            <Grid columns={['1fr', '1fr', 'repeat(3, 1fr)']} gap="16px">
              <Forms.InputGroup
                name="customer.phone"
                showRequired
                label="Telefone ou Celular"
                placeholder="(00) 90000-0000"
                mask="phone"
                data-test-id="consumer-phone"
              />
              <Forms.InputGroup
                name="customer.email"
                label="E-mail"
                showRequired
                placeholder="nome@email.com.br"
                data-test-id="consumer-email"
              />
              <Autocomplete
                name="customer.occupation"
                label="Profissão"
                showRequired
                initialInnerValue={occupationValue}
                onSearch={async value => await getOccupations(value)}
                data-test-id="consumer-profession"
              />
              <Forms.SelectGroup
                name="customer.income_bracket_id"
                label="Faixa de renda"
                options={incomeBracketId.options}
                data-test-id="consumer-income-range"
              />
            </Grid>
          </Grid>
        </AnimateVerticalShow>
      </Grid>
    </BaseCard>
  )
}
