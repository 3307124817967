import { useEffect, useState } from 'react'

import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Notification } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

import { useNewAccount } from './../context'
import { Link } from '../../components'
import { BreakTextAnywere } from './styles'

const propsByHasAccreditation = () => ({
  [true]: {
    title: 'Estamos enviando as instruções em seu e-mail do credenciamento',
    notificationProps: {
      title: 'Não tem acesso ao e-mail informado?',
      description: `Entre em contato com seu consultor para solicitar a atualização.`,
      icon: 'circlequestion',
    },
  },
  [false]: {
    title: 'Estamos enviando as instruções em seu e-mail',
    notificationProps: {
      title: 'O e-mail não chegou?',
      description: `Primeiro, confira a caixa de spam. Em seguida, entre em contato com seu consultor ou acesse nosso chat;`,
      icon: 'circlequestion',
    },
  },
})

const EmailSent = () => {
  const newAccount = useNewAccount()
  const [emailSent, setEmailSent] = useState()

  useEffect(() => setEmailSent(newAccount.state.partnerLeadResponse?.maskedEmail), [newAccount])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Link label="Voltar" icon="arrowleft" to="/login" />
      <Typography
        variant="porto-title-3-semibold"
        content={propsByHasAccreditation()[newAccount.state.check.hasAccreditation]?.title}
      />
      <Typography variant="porto-text-body-1-regular">
        Acesse a mensagem que enviamos para <br />
        <BreakTextAnywere>{emailSent}</BreakTextAnywere> e valide seu e-mail para continuar a
        criação da conta.
      </Typography>
      <Box sx={{ marginTop: '1rem' }}>
        <Notification
          {...propsByHasAccreditation()[newAccount.state.check.hasAccreditation]?.notificationProps}
        />
      </Box>
    </Flex>
  )
}

export default EmailSent
