import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { CardNotice, Conditional, Content, TableBatch } from '@/components'
import TooltipError from '@/components/Utilities/TooltipError'
import { useCurrentOrganization } from '@/modules'
import { api } from '@/services'
import { formatMoneyBRL } from '@/tools'

import { IRow } from '@/components/TableBatch/interface'
import { formatCEP } from '@brazilian-utils/brazilian-utils'
import { formatToCEP, formatToCPFOrCNPJ } from 'brazilian-values'
import { IBatchBudget } from '../interfaces'
import { columns, commissionParse, percent } from '../utils'
import {
  Container,
  ContainerInfo,
  ContainerTable,
  Icon,
  InfoContent,
  ItemWrapper,
  Label,
  LabelsErrors,
  Value,
} from './styles'

const Details = () => {
  const profile = useCurrentOrganization()
  const [row, setRows] = useState<IRow[]>([])
  const [partner, setPartner] = useState()
  const { state: batchBudget } = useLocation<IBatchBudget>()
  const isBroker = profile.type === 'BROKER'

  const LabelErrors = (erro: []) => {
    if (erro.length > 0) {
      return <LabelsErrors erro>Dados incorretos</LabelsErrors>
    } else {
      return <LabelsErrors>Pronto para cálculo</LabelsErrors>
    }
  }
  const IconsErrors = (erro: []) => {
    if (erro.length > 0) {
      return (
        <TooltipError
          background="white"
          backgroundArrow="white"
          status="Dados incorretos"
          content={erro}
          color="black"
          placement="bottom"
        >
          <Icon erro name="alert-circle-outline" />
        </TooltipError>
      )
    } else {
      return <Icon name="checkmark-outline" />
    }
  }

  const formatDocument = (document: string) => {
    const formate = document.replace(/\.|-|\//g, '')

    return formatToCPFOrCNPJ(String(formate))
  }

  const getBatch = async () => {
    setRows(
      batchBudget.budgets.map((item, index) => {
        return {
          key: `row${index}`,
          errors: IconsErrors(item.errors),
          status: LabelErrors(item.errors),
          customer_document: formatDocument(item.customer_document),
          address_zipcode: formatToCEP(String(item.address_zipcode)),
          address_neighborhood: item.address_neighborhood,
          address_street: item.address_street,
          address_number: item.address_number,
          address_complement: item.address_complement,
          customer_phone_code: item.customer_phone_code,
          customer_phone_number: item.customer_phone_number,
          estate_owner: item.estate_owner,
          estate_type: item.estate_type,
          fire: !!item.fire ? formatMoneyBRL(item.fire) : '',
          eletrical_damages: !!item.eletrical_damages ? formatMoneyBRL(item.eletrical_damages) : '',
          rental_loss: !!item.rental_loss ? formatMoneyBRL(item.rental_loss) : '',
          civil_responsability: !!item.civil_responsability
            ? formatMoneyBRL(item.civil_responsability)
            : '',
          vehicle_impacts: !!item.vehicle_impacts ? formatMoneyBRL(item.vehicle_impacts) : '',
          gale: !!item.gale ? formatMoneyBRL(item.gale) : '',
          riot: !!item.riot ? formatMoneyBRL(item.riot) : '',
          contract_services_clauses: item.contract_services_clauses,
          customer_name: item.customer_name,
          customer_social_name: item.customer_social_name,
          customer_email: item.customer_email,
          beneficiary_enabled: item.beneficiary_enabled,
          beneficiary_name: item.beneficiary_name,
          beneficiary_document: !!item.beneficiary_document
            ? formatDocument(item.beneficiary_document)
            : '',
          beneficiary_zipcode: formatCEP(String(item.beneficiary_zipcode)),
          beneficiary_neighborhood: item.beneficiary_neighborhood,
          beneficiary_street: item.beneficiary_street,
          beneficiary_number: item.beneficiary_number,
          beneficiary_complement: item.beneficiary_complement,
          contract_discount: !!item.contract_discount ? percent(item.contract_discount) : '',
          contract_operation_code: !!item.contract_operation_code
            ? commissionParse(item.contract_operation_code)
            : '',
          contract_prolabore: !!item.contract_prolabore ? percent(item.contract_prolabore) : '',
          customer_activity_area: item.customer_activity_area,
          company_activity_area: item.company_activity_area,
        }
      }),
    )

    const { name } = await api.partners.get(batchBudget.partner)
    setPartner(name)
  }

  useEffect(() => {
    getBatch()
  }, [])

  return (
    <Container>
      <Content.Heading
        title="Calcular orçamento em lote"
        subtitle="Insira abaixo as informações solicitadas e o seu arquivo."
      />

      <Conditional when={!!partner}>
        <CardNotice
          icon="bellSvg"
          children="Ao sair desta tela não será mais exibido as informações dos dados incorretos em arquivo e precisará inseri-lo novamente.
          Por favor, clique em exportar para visualizar os erros e corrigir em seu arquivo. <b>Após correção insira novamente o arquivo no Portal</b>"
          onlyMessage={true}
        />

        <ContainerInfo>
          <ItemWrapper>
            <InfoContent>
              <Label>Susep</Label>
              <Value>{batchBudget.susep}</Value>
            </InfoContent>
          </ItemWrapper>
          <Conditional when={isBroker}>
            <ItemWrapper>
              <InfoContent>
                <Label>Parceiro</Label>
                <Value>{partner}</Value>
              </InfoContent>
            </ItemWrapper>
          </Conditional>

          <ItemWrapper>
            <InfoContent>
              <Label>Data de arquivo</Label>
              <Value>{dayjs(batchBudget.created_at).format('DD/MM/YYYY')}</Value>
            </InfoContent>
          </ItemWrapper>
          <ItemWrapper>
            <InfoContent>
              <Label>Início de Vigência</Label>
              <Value>{dayjs(batchBudget.period_start).format('DD/MM/YYYY')} </Value>
            </InfoContent>
          </ItemWrapper>
          <ItemWrapper>
            <InfoContent>
              <Label>Arquivo</Label>
              <Value>{batchBudget.filename}</Value>
            </InfoContent>
          </ItemWrapper>
        </ContainerInfo>
        <ContainerTable>
          <TableBatch
            columns={columns}
            fixedHeader
            onBottomReached={function noRefCheck() {}}
            rows={row}
            batch={batchBudget}
          />
        </ContainerTable>
      </Conditional>
    </Container>
  )
}

export default Details
