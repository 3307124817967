import { formatMoneyBRL } from '@/tools'
import * as yup from 'yup'

export const plansParse = {
  RESIDENTIAL: 'Residencial',
  COMMERCIAL: 'Comercial',
}

export const statusParse = {
  FAILURE: 'Falha na integração',
  PENDING: 'Em processamento',
  DONE: 'Orçamento calculado',
  calculated: 'Orçamento calculado',
  processing: 'Em processamento',
  critical: 'Crítica',
  failure: 'Falha na integração',
  done: 'Concluído',
}

export const columns = [
  {
    dataIndex: 'errors',
    key: 'header1',
    title: 'Erros',
  },
  {
    dataIndex: 'status',
    key: 'header2',
    title: 'Status',
  },
  {
    dataIndex: 'customer_document',
    key: 'header3',
    title: 'CPF/CNPJ',
  },
  {
    dataIndex: 'address_zipcode',
    key: 'header4',
    title: 'CEP',
  },
  {
    dataIndex: 'address_neighborhood',
    key: 'header5',
    title: 'Bairro',
  },
  {
    dataIndex: 'address_street',
    key: 'header6',
    title: 'Nome do Logradouro',
    width: 192,
  },
  {
    dataIndex: 'address_number',
    key: 'header7',
    title: 'Nº Logradouro',
    width: 150,
  },
  {
    dataIndex: 'address_complement',
    key: 'header8',
    title: 'Complemento',
  },
  {
    dataIndex: 'customer_phone_code',
    key: 'header9',
    title: 'DDD',
  },
  {
    dataIndex: 'customer_phone_number',
    key: 'header10',
    title: 'Telefone',
  },
  {
    dataIndex: 'estate_owner',
    key: 'header11',
    title: 'Proprietário',
  },
  {
    dataIndex: 'estate_type',
    key: 'header12',
    title: 'Tipo Imóvel',
  },
  {
    dataIndex: 'fire',
    key: 'header13',
    title: 'Incêndio',
  },
  {
    dataIndex: 'eletrical_damages',
    key: 'header14',
    title: 'Danos Elétricos',
  },
  {
    dataIndex: 'rental_loss',
    key: 'header15',
    title: 'Perda Aluguel',
  },
  {
    dataIndex: 'civil_responsability',
    key: 'header16',
    title: 'Responsabilidade civil',
  },
  {
    dataIndex: 'vehicle_impacts',
    key: 'header17',
    title: 'Impacto Veículos',
  },
  {
    dataIndex: 'gale',
    key: 'header18',
    title: 'Vendaval',
  },
  {
    dataIndex: 'riot',
    key: 'header19',
    title: 'Tumulto',
  },
  {
    dataIndex: 'contract_services_clauses',
    key: 'header20',
    title: 'Cláusula de serviço',
  },
  {
    dataIndex: 'customer_name',
    key: 'header21',
    title: 'Nome Proponente',
  },
  {
    dataIndex: 'customer_email',
    key: 'header22',
    title: 'Email Proponente',
  },
  {
    dataIndex: 'beneficiary_enabled',
    key: 'header23',
    title: 'Cláusula Beneficiária',
  },
  {
    dataIndex: 'beneficiary_name',
    key: 'header24',
    title: 'Nome Beneficiário',
  },
  {
    dataIndex: 'beneficiary_document',
    key: 'header25',
    title: 'CPF/CNPJ Beneficiário',
  },
  {
    dataIndex: 'beneficiary_zipcode',
    key: 'header26',
    title: 'CEP beneficiário',
  },
  {
    dataIndex: 'beneficiary_neighborhood',
    key: 'header27',
    title: 'Bairro beneficiário',
  },
  {
    dataIndex: 'beneficiary_street',
    key: 'header28',
    title: 'Nome do Logradouro beneficiário',
    width: 192,
  },
  {
    dataIndex: 'beneficiary_number',
    key: 'header29',
    title: 'Nº Logradouro beneficiário',
    width: 150,
  },
  {
    dataIndex: 'beneficiary_complement',
    key: 'header30',
    title: 'Complemento beneficiário',
  },
  {
    dataIndex: 'contract_discount',
    key: 'header31',
    title: 'Desconto Promocional',
  },
  {
    dataIndex: 'contract_operation_code',
    key: 'header32',
    title: 'Comissão',
  },
  {
    dataIndex: 'contract_prolabore',
    key: 'header33',
    title: 'Pró Labore',
  },
  {
    dataIndex: 'company_activity_area',
    key: 'header34',
    title: 'Atividade Econômica',
  },
  {
    dataIndex: 'customer_activity_area',
    key: 'header35',
    title: 'Atividade Econômica Pessoa Física',
  },
]

type ColumnDefinition = {
  title: string
  schema: yup.BaseSchema
  default?: unknown
}

export const columnsValidateRealEstate: Record<string, ColumnDefinition> = {
  customer_document: {
    title: 'CPF/CNPJ do Proponente',
    schema: yup.string().required(),
  },
  civil_status: {
    title: 'Estado civil',
    schema: yup.string(),
  },
  customer_occupation: {
    title: 'Profissão',
    schema: yup.string(),
  },
  customer_income: {
    title: 'Faixa de renda',
    schema: yup.string(),
  },
  customer_pep: {
    title: 'PEP',
    schema: yup.string(),
  },
  pep_document_type: {
    title: 'Tipo de Documento',
    schema: yup.string(),
  },
  pep_document: {
    title: 'Número do Documento',
    schema: yup.string(),
  },
  pep_document_dispatcher: {
    title: 'Órgão Expedidor',
    schema: yup.string(),
  },
  pep_document_date: {
    title: 'Data do Documento',
    schema: yup.string(),
  },
  pep_nationality: {
    title: 'Nacionalidade',
    schema: yup.string(),
  },
  pep_brazilian_resident: {
    title: 'Reside no Brasil',
    schema: yup.string(),
  },
  pep_cpf: {
    title: 'CPF da PEP',
    schema: yup.string(),
  },
  pep_name: {
    title: 'Nome completo da PEP',
    schema: yup.string(),
  },
  pep_relationship_type: {
    title: 'Grau de Relacionamento',
    schema: yup.string(),
  },
  address_zipcode: {
    title: 'CEP',
    schema: yup.string().required(),
  },
  address_neighborhood: {
    title: 'Bairro',
    schema: yup
      .string()
      .required()
      .matches(/^[a-zA-Z 0-9]+$/),
  },
  address_street: {
    title: 'Logradouro',
    schema: yup
      .string()
      .required()
      .matches(/^[a-zA-Z 0-9]+$/),
  },
  address_number: {
    title: 'Número',
    schema: yup.string(),
  },
  address_complement: {
    title: 'Complemento',
    schema: yup.string().required(),
  },
  customer_phone_code: {
    title: 'DDD',
    schema: yup
      .string()
      .required()
      .max(3)
      .matches(/^[0-9]+$/),
  },
  customer_phone_number: {
    title: 'Telefone',
    schema: yup.string().required(),
  },
  estate_owner: {
    title: 'Proprietário do imóvel?',
    schema: yup.string().oneOf(['SIM', 'NÃO']).required(),
  },
  estate_type: {
    title: 'Tipo de imóvel',
    schema: yup.string().required(),
  },
  fire: {
    title: 'Incêndio',
    schema: yup.number().min(40000).max(2000000).required(),
    default: 0,
  },
  eletrical_damages: {
    title: 'Danos Elétricos',
    schema: yup.number().required(),
    default: 0,
  },
  rental_loss: {
    title: 'Perda de Aluguel',
    schema: yup.number().min(1000).required(),
    default: 0,
  },
  civil_responsability: {
    title: 'Responsabilidade Civil',
    schema: yup.number().required(),
    default: 0,
  },
  vehicle_impacts: {
    title: 'Impacto de Veículos',
    schema: yup.number().required(),
    default: 0,
  },
  gale: {
    title: 'Vendaval',
    schema: yup.number().required(),
    default: 0,
  },
  riot: {
    title: 'Tumulto',
    schema: yup.number().required(),
    default: 0,
  },
  contract_services_clauses: {
    title: 'Cláusulas de Serviços (Emergênciais)',
    schema: yup.string().required(),
  },
  customer_name: {
    title: 'Nome de registro do proponente',
    schema: yup
      .string()
      .required()
      .matches(/^[a-zA-Z 0-9]+$/),
  },
  customer_email: {
    title: 'E-mail do proponente',
    schema: yup.string().required(),
  },
  beneficiary_enabled: {
    title: 'Cláusula Beneficiária',
    schema: yup.string().oneOf(['SIM', 'NÃO']).required(),
  },
  beneficiary_name: {
    title: 'Nome de registro do beneficiário',
    schema: yup
      .string()
      .required()
      .matches(/^[a-zA-Z 0-9]+$/),
  },
  beneficiary_document: {
    title: 'CPF/CNPJ beneficiário',
    schema: yup.string(),
  },
  beneficiary_zipcode: {
    title: 'CEP beneficiário',
    schema: yup.string().required(),
  },
  beneficiary_neighborhood: {
    title: 'Bairro beneficiário',
    schema: yup
      .string()
      .required()
      .matches(/^[a-zA-Z 0-9]+$/),
  },
  beneficiary_street: {
    title: 'Logradouro beneficiário',
    schema: yup
      .string()
      .required()
      .matches(/^[a-zA-Z 0-9]+$/),
  },
  beneficiary_number: {
    title: 'Número beneficiário',
    schema: yup.string(),
  },
  beneficiary_complement: {
    title: 'Complemento beneficiário',
    schema: yup.string().required(),
  },
  contract_discount: {
    title: '% Desconto Promocional',
    schema: yup.number().required(),
    default: 0,
  },
  beneficiary_social_name: {
    title: 'Nome social do beneficiário',
    schema: yup
      .string()
      .optional()
      .matches(/^[a-zA-Z 0-9]+$/),
  },
  customer_social_name: {
    title: 'Nome Social do proponente',
    schema: yup
      .string()
      .optional()
      .matches(/^[a-zA-Z 0-9]+$/),
  },

  company_activity_area: {
    title: 'Atividade Econômica',
    schema: yup.string(),
    default: null,
  },
  customer_activity_area: {
    title: 'Atividade Econômica Pessoa Física',
    schema: yup.string(),
    default: null,
  },
}

export const columnsValidateBroker: Record<string, ColumnDefinition> = {
  ...columnsValidateRealEstate,
  contract_operation_code: {
    title: 'Comissão',
    schema: yup.number().required(),
    default: null,
  },
  contract_prolabore: {
    title: 'Pró Labore',
    schema: yup.number().required(),
    default: null,
  },
}

export const columnsValidate: Record<string, ColumnDefinition> = {
  ...columnsValidateRealEstate,
  ...columnsValidateBroker,
}

export function commissionParse(percentage) {
  return (
    {
      '1': '1%',
      '2': '2%',
      '3': '3%',
      '4': '4%',
      '5': '5%',
      '6': '6%',
      '7': '7%',
      '8': '8%',
      '9': '9%',
      '10': '10%',
      '11': '11%',
      '12': '12%',
      '13': '13%',
      '14': '14%',
      '15': '15%',
      '16': '16%',
      '17': '17%',
      '18': '18%',
      '19': '19%',
      '20': '20%',
      '154': '21%',
      '155': '22%',
      '156': '23%',
      '157': '24%',
      '158': '25%',
      '159': '26%',
      '160': '27%',
      '161': '28%',
      '162': '29%',
      '163': '30%',
      '164': '31%',
      '165': '32%',
      '166': '33%',
      '167': '34%',
      '168': '35%',
      '169': '36%',
      '170': '37%',
      '171': '38%',
      '172': '39%',
      '173': '40%',
      '174': '41%',
      '175': '42%',
      '176': '43%',
      '177': '44%',
      '178': '45%',
      '179': '46%',
      '180': '47%',
      '181': '48%',
      '182': '49%',
      '183': '50%',
      '184': '51%',
      '185': '52%',
      '186': '53%',
      '187': '54%',
      '188': '55%',
      '189': '56%',
      '190': '57%',
      '191': '58%',
      '192': '59%',
      '193': '60%',
      '194': '61%',
      '195': '62%',
      '196': '63%',
      '197': '64%',
      '198': '65%',
      '199': '66%',
      '200': '67%',
      '201': '68%',
      '202': '69%',
      '203': '70%',
    }[percentage] || percentage
  )
}

export const percent = (percentage: number) => {
  return percentage + '%'
}

export const parseExportValidate = rows => {
  const parse = rows.budgets.map(item => {
    return {
      Status: item.errors.length > 0 ? 'Dados inválidos' : 'OK',
      Erros: item.errors.length > 0 ? item.errors.join() : '',
      'CPF/CNPJ': item.customer_document,
      CEP: item.address_zipcode,
      Bairro: item.address_neighborhood,
      Logradouro: item.address_street,
      Número: item.address_number,
      Complemento: item.address_complement,
      DDD: item.customer_phone_code,
      Telefone: item.customer_phone_number,
      'Proprietário do imóvel?': item.estate_owner,
      'Tipo do imóvel': item.estate_type,
      Incêndio: !!item.fire ? item.fire : '',
      'Danos Elétricos': !!item.eletrical_damages ? item.eletrical_damages : '',
      'Perda de Aluguel': !!item.rental_loss ? item.rental_loss : '',
      'Responsabilidade Civil': !!item.civil_responsability ? item.civil_responsability : '',
      'Impacto de Veículos': !!item.vehicle_impacts ? item.vehicle_impacts : '',
      Vendaval: !!item.gale ? item.gale : '',
      Tumulto: !!item.riot ? item.riot : '',
      'Cláusulas de Serviços (Emergênciais)': item.contract_services_clauses,
      'Nome de registro do proponente': item.customer_name,
      'E-mail do proponente': item.customer_email,
      'Cláusula Beneficiária': item.beneficiary_enabled,
      'Nome de registro do beneficiário': item.beneficiary_name,
      'CPF/CNPJ beneficiário': item.beneficiary_document,
      'CEP beneficiário': item.beneficiary_zipcode,
      'Bairro beneficiário': item.beneficiary_neighborhood,
      'Logradouro beneficiário': item.beneficiary_street,
      'Número beneficiário': item.beneficiary_number,
      'Complemento beneficiário': item.beneficiary_complement,
      '% Desconto Promocional': !!item.contract_discount ? percent(item.contract_discount) : '',
      Comissão: !!item.contract_operation_code ? commissionParse(item.contract_operation_code) : '',
      'Pró Labore': !!item.contract_prolabore ? percent(item.contract_prolabore) : '',
      'Atividade Econômica': item.company_activity_area,
      'Atividade Econômica Pessoa Fisica': item.customer_activity_area,
    }
  })

  return parse
}

export const parseExportBudget = rows => {
  const parse = rows.map(({ payload, policy, budgets, external_status }) => {
    const payment = budgets?.map(quotation => {
      return quotation?.quotation?.prize?.total
    })

    return {
      Proponente: payload?.customer.name,
      'Tipo de plano': plansParse[payload?.contract.plan],
      'N° do orçamento': budgets[0]?.id,
      'N° da proposta': policy?.proposal,
      'N° da apólice': policy?.id,
      'Valor a pagar': payment.length > 0 ? formatMoneyBRL(payment) : '',
      Status: statusParse[external_status],
    }
  })

  return parse
}

export function dimissModalOnclick(props) {
  window.addEventListener('mouseup', function (event) {
    var fade = document.getElementsByClassName('sc-cxpSdN gdOTxH')[0]
    if (event.target === fade) {
      props.onDismiss()
    }
  })
}
