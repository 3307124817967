import { toast } from '@/components'
import {
  firstStepForm,
  firstStepSchema,
  formatFirstStepContract,
  fullfillfirstStepContract,
} from '@/data/capitalizacao'
import { useContract, useCurrentOrganization } from '@/modules'
import { api } from '@/services'
import { Flex } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { FormikProvider, useFormik } from 'formik'
import { set } from 'lodash'
import { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import { ContractErrors } from '../../../shared/ContractErrors'
import { Contract, InsuranceBroker, Tenant, TitleAndRent } from './components'

export const First = ({
  contractProps,
  tenantProps,
  isInDetailsScreen = false,
  setStep = () => null,
}) => {
  const history = useHistory()
  const contract = useContract()
  const stateLocation = useLocation()
  const isFromEssencialPACRefused = stateLocation.state?.type === 'essencial-pac-refused'
  const [errors, setErrors] = useState([])
  const currentProfile = useCurrentOrganization()

  const firstStepFormValues = useMemo(() => {
    if (isFromEssencialPACRefused) {
      return fullfillfirstStepContract({ values: stateLocation?.state?.contract })
    }

    if (isInDetailsScreen) {
      return fullfillfirstStepContract({ values: contract })
    }

    if (currentProfile.type === 'REAL_ESTATE')
      return { ...firstStepForm, partner: currentProfile.id }

    return firstStepForm

    // As dependências que o hook critíca ocasionam um número desnecessário de execuções.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFromEssencialPACRefused, isInDetailsScreen])

  const createContractMutation = useMutation(async formattedContract => {
    return await api.contracts.create('capitalizacao', formattedContract)
  })

  const defaultSubmit = async (formattedContract, history) => {
    try {
      const contract = await createContractMutation.mutateAsync(formattedContract)
      history.push(`/contratos/${contract.id}`)
    } catch (error) {
      toast('Ocorreu um erro ao criar o contrato.', {
        type: 'error',
      })

      if (error.response?.status === 409 && error.response?.data?.cause) {
        setErrors(error.response?.data?.cause)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }

  const detailSubmit = async (formattedContract, contract, setStep) => {
    try {
      const updatedContract = contract.update(formattedContract)
      const syncedContract = await contract.sync(updatedContract)
      await api.contracts.calculate(contract.id)

      if (syncedContract) {
        contract.resetStore()

        await contract.fetch({
          id: contract.id,
        })
      }

      setStep(2)
    } catch (e) {
      console.log(e)
      toast('Ocorreu um erro ao atualizar o contrato.', {
        type: 'error',
      })
    }
  }

  const firstStepFormik = useFormik({
    initialValues: firstStepFormValues,
    initialTouched: {
      customer: {
        document:
          (isFromEssencialPACRefused || isInDetailsScreen) &&
          !!firstStepFormValues.customer.document,
      },
    },
    enableReinitialize: true,
    validate: values => {
      try {
        firstStepSchema.validateSync(values, {
          abortEarly: false,
          context: { landlordDocument: contract?.payload?.landlord?.document },
        })
      } catch (error) {
        if (error.name !== 'ValidationError') {
          throw error
        }

        return error.inner.reduce((errors, currentError) => {
          errors = set(errors, currentError.path, currentError.message)
          return errors
        }, {})
      }

      return {}
    },
    onSubmit: async values => {
      const castedValues = firstStepSchema.cast(values)

      const formattedContract = formatFirstStepContract({
        values: castedValues,
        hideBrokerAndPartner: isInDetailsScreen,
      })

      if (isInDetailsScreen) {
        await detailSubmit(formattedContract, contract, setStep)
        return
      }

      await defaultSubmit(formattedContract, history)
    },
  })

  return (
    <FormikProvider value={firstStepFormik}>
      {errors.length > 0 && (
        <ContractErrors
          icon="home"
          title="Não foi possível prosseguir devido a alterações feitas pela corretora. Revise os seguintes dados:"
          errors={errors}
        />
      )}
      <fieldset
        disabled={firstStepFormik.isSubmitting}
        style={{
          backgroundColor: 'unset',
          border: 'unset',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Tenant />

        {!firstStepFormik.errors?.customer?.document && (
          <>
            <Contract {...contractProps} />
            <TitleAndRent />
            <InsuranceBroker isInDetailsScreen={isInDetailsScreen} />
            <Flex
              sx={{
                gap: '1rem',
                flexDirection: { xs: 'column', lg: 'row' },
              }}
            >
              <Button data-test-id="cancel" kind="ghost" onClick={() => history.push('/contratos')}>
                Cancelar
              </Button>
              <Button
                data-test-id="continue"
                variant="primary"
                onClick={() => firstStepFormik.handleSubmit()}
                type="submit"
                isLoading={firstStepFormik.isSubmitting}
                isDisabled={firstStepFormik.isSubmitting || !firstStepFormik.isValid}
              >
                Continuar
              </Button>
            </Flex>
          </>
        )}
      </fieldset>
    </FormikProvider>
  )
}
