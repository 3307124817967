import { FormikProvider, useFormik } from 'formik'
import { createContext, useEffect, useMemo, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { Actions, Conditional, Content, toast } from '@/components'
import { useCurrentOrganization } from '@/modules'
import { api } from '@/services'
import { BRLMoneyToNumber, isBRLMoneyMasked, useGTM } from '@/tools'
import FocusError from '@/tools/utils/focusError'

import { Organization } from '../../shared'
import validationSchema from './schema'

import { resetStore } from '@/modules/contract/actions'
import * as Steps from './Steps'
import * as utils from './utils'

import { useSusepOptionsQuery } from '@/components/SusepCombo/hooks'
import { useIsImobiliariaEnabled } from '@/tools/partnership'
import { Loader } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { AxiosError } from 'axios'
import { Card, Icon, Typography } from 'design-system-react'
import { useQuery } from 'react-query'
import { Products } from '../../shared/entities'
import { SettingsErrorCard, SettingsErrorCardContent, SettingsErrorCardErrorsSpace } from './styles'

export const BudgetsContext = createContext(null)

export type ImobiliariaBudget = typeof utils.initialValues

const CURRENT_PRODUCT = 'porto_imobiliaria'

const Budgets = () => {
  const organization = useCurrentOrganization()
  const { state: contract } = useLocation<any>()
  const { setDataLayer, setCustomData } = useGTM()
  const history = useHistory()
  const imobiliariaEnable = useIsImobiliariaEnabled(organization)
  const susepOptionsQuery = useSusepOptionsQuery(CURRENT_PRODUCT)

  const [conflictSettings, setConflictSettings] = useState<string[] | null>(null)
  const [isMaximumValueOfCoverages, setIsMaximumValueofCoverages] = useState(false)

  const isRenewal = contract?.renewal_number

  useEffect(() => {
    setCustomData({
      page: {
        name: 'Portal Imobiliária - Orcamentos - Seguro Incendio',
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - orcamentos - seguro incendio',
      etapa: '/area-logada/orcamentos/seguro-incendio',
    })
  }, [setCustomData, setDataLayer])

  const formInitialValue = useMemo(() => {
    if (contract) return utils.parseContractToForm(contract)

    if (organization.type === 'REAL_ESTATE')
      return { ...utils.initialValues, partner: organization.id, susep: undefined }

    return { ...utils.initialValues, partner: undefined, susep: undefined }
  }, [contract, organization.id, organization.type])

  const addBeneficiariesPayload = (contractPayload: ImobiliariaBudget) => {
    if (contract?.payload?.contract?.beneficiaries) {
      contractPayload.beneficiaries = contract.payload.contract.beneficiaries.map(beneficiary => ({
        ...beneficiary,
        id: beneficiary.id ?? Math.random(),
      }))
    }
  }

  const form = useFormik<ImobiliariaBudget>({
    initialValues: formInitialValue,
    validationSchema,
    onSubmit: async formikValues => {
      if (isMaximumValueOfCoverages) {
        toast('Ajuste os valores das coberturas para continuar.', {
          type: 'error',
        })
        return
      }

      // Isso previne que façamos alterações no estado original do Formik, assim respeitando a imutabilidade
      const values = structuredClone(formikValues)

      try {
        const renewalPayload = !isRenewal
          ? {}
          : {
              renewal_policy_id: contract?.renewal_policy_id,
              partner: contract?.partner,
            }

        Object.keys(values.coverages).forEach(key => {
          if (values?.coverages[key] && isBRLMoneyMasked(values?.coverages[key])) {
            values.coverages[key] = BRLMoneyToNumber(values?.coverages[key])
          }
        })

        addBeneficiariesPayload(values)

        setConflictSettings(null)
        const _contract = await api.contracts.create('imobiliaria', {
          ...values,
          ...renewalPayload,
        })

        if (isRenewal) await api.contracts.removeRenewal(contract.id)

        setDataLayer({
          event: 'solicitacao-servico',
          nome_servico: 'seguro-incendio',
          tipo_servico: 'calcular-orcamento',
          codigo_parceiro: values?.partner,
          susep: values?.susep,
          tipo_casa: values?.estate_type,
          tipo_plano: values?.contract_plan,
          protocolo: _contract.id,
          retorno: 'sucesso',
          descricao: 'Sucesso ao calcular o orçamento',
        })

        resetStore()
        history.push(`/contratos/${_contract.id}`, {
          state: _contract?.renovation,
        })
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response.data.code === '@contract/invalid-create-contract') {
            const messages = error.response.data.cause.map(er => {
              if (er.fieldName) form.setFieldValue(er.fieldName, undefined)

              return er.description
            }) as string[]
            setConflictSettings(messages)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            settingsCurrentUpdatedQuery.refetch()
            susepOptionsQuery.refetch()
            return
          }
        }

        toast('Ocorreu um erro ao calcular o orçamento.', {
          type: 'error',
        })

        setDataLayer({
          event: 'solicitacao-servico',
          nome_servico: 'seguro-incendio',
          tipo_servico: 'calcular-orcamento',
          codigo_parceiro: values?.partner,
          susep: values?.susep,
          tipo_casa: values?.estate_type,
          tipo_plano: values?.contract_plan,
          retorno: 'erro',
          descricao: 'Ocorreu um erro ao calcular o orçamento',
          erro: {
            codigo: error?.response?.status,
            servico: 'seguro-incendio',
            mensagem: error?.response?.data?.message ?? 'Server error',
          },
        })
      }
    },
  })

  const { data: settingsCurrentUpdated, ...settingsCurrentUpdatedQuery } = useQuery(
    ['@fetch-settings', `partner-${form.values.partner}`, `susep-${form.values.susep}`],
    () => api.organizations.partnerships.getSettings(form.values.partner, form.values.susep),
    {
      enabled: !!form.values.partner && !!form.values.susep,
    },
  )

  const currentSettingByPlan = useMemo(
    () => settingsCurrentUpdated?.settings?.porto_imobiliaria?.[form.values.contract_plan],
    [form.values.contract_plan, settingsCurrentUpdated?.settings?.porto_imobiliaria],
  )

  const settingsParse = useMemo(
    () =>
      ({
        BROKER: {
          suseps: settingsCurrentUpdated?.suseps,
          plans: utils.plans,
          prolabore: {
            enabled: currentSettingByPlan?.prolabore?.enabled,
            percentage: currentSettingByPlan?.prolabore?.percentage,
            variable: currentSettingByPlan?.prolabore?.variable,
          },
          config: settingsCurrentUpdated?.config,
          clauses: utils.clauses,
          coverages: utils.coverages,
        },
        REAL_ESTATE: {
          suseps: settingsCurrentUpdated?.suseps,
          plans: [
            settingsCurrentUpdated?.settings?.porto_imobiliaria?.residential?.enabled &&
              'residential',
            settingsCurrentUpdated?.settings?.porto_imobiliaria?.commercial?.enabled &&
              'commercial',
          ].filter(Boolean),
          prolabore: {
            enabled: currentSettingByPlan?.prolabore?.enabled,
            percentage: currentSettingByPlan?.prolabore?.percentage,
            variable: currentSettingByPlan?.prolabore?.variable,
          },
          config: settingsCurrentUpdated?.config,
          clauses: currentSettingByPlan?.service_clauses || [],
          coverages: currentSettingByPlan?.coverages || [],
        },
      })[organization.type],
    [settingsCurrentUpdated, organization.type, currentSettingByPlan],
  )

  useEffect(() => {
    setIsMaximumValueofCoverages(
      Object.values(form.values.coverages).reduce((a, b) => a + b, 0) > 2700000,
    )
  }, [form.values.coverages])

  useEffect(() => {
    if (currentSettingByPlan) {
      const { comission, prolabore } = currentSettingByPlan
      form.setFieldValue('contract_commission', comission)
      if (prolabore?.enabled && !prolabore?.variable) {
        form.setFieldValue('contract_prolabore', prolabore.percentage)
      }
    }
  }, [currentSettingByPlan])

  return (
    <BudgetsContext.Provider
      value={{
        settings: settingsParse,
        rules: {
          isMaximumValueOfCoverages,
        },
      }}
    >
      {!imobiliariaEnable.result && !imobiliariaEnable.isLoading && (
        <Redirect to={`/?nao-habilitado=${Products.IMOBILIARIA}`} />
      )}
      <Content.Heading
        title={form.values.renewal_number ? 'Atualização dos dados' : 'Seguro Incêndio'}
        subtitle={
          form.values.renewal_number
            ? 'Verifique e atualize as informações sobre contrato, cobertura e vigência.'
            : 'Insira abaixo os dados do cliente para o cálculo de orçamento.'
        }
      />

      <FormikProvider value={form}>
        {Boolean(conflictSettings) && (
          <SettingsErrorCard>
            <Card mode="light">
              <SettingsErrorCardContent>
                <Icon size="xlarge" icon="icon-icon-close" color="red85" />

                <Typography as="label" type="Body1" weight="bold">
                  Não foi possível prosseguir devido a alterações feitas pela corretora. Revise os
                  seguintes dados:
                </Typography>

                <SettingsErrorCardErrorsSpace>
                  {conflictSettings.map((message, index) => (
                    <Typography key={index} as="span" type="Body2">
                      {message}
                    </Typography>
                  ))}
                </SettingsErrorCardErrorsSpace>
              </SettingsErrorCardContent>
            </Card>
          </SettingsErrorCard>
        )}

        <Conditional when={!form.values.renewal_number}>
          <Organization product={CURRENT_PRODUCT} />
        </Conditional>

        {(!!settingsCurrentUpdated || !!conflictSettings || !!contract) &&
          !settingsCurrentUpdatedQuery.isFetching && (
            <>
              <Steps.Contract />
              <Steps.Customer />
              <Steps.Estate />
              <Steps.Coverages />
              <Steps.Periods />

              <Actions.Form
                isLoading={form.isSubmitting}
                onSubmit={{
                  icon: 'check',
                  label: form.values.renewal_number ? 'Concluir renovação' : 'Calcular orçamento',
                  onClick: () => form.handleSubmit(),
                }}
              />
            </>
          )}

        <div
          style={{ display: 'flex', width: '79%', justifyContent: 'center', alignItems: 'center' }}
        >
          <Conditional when={settingsCurrentUpdatedQuery.isFetching}>
            <Loader size="48px" />
          </Conditional>
        </div>

        <FocusError />
      </FormikProvider>
    </BudgetsContext.Provider>
  )
}

export default Budgets
