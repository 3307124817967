export const occupations = {
  135: 'AÇOUGUEIRO',
  136: 'ACUPUNTURISTA',
  137: 'ADESTRADOR DE ANIMAIS',
  139: 'ADMINISTRADOR DE EMPRESAS',
  140: 'ADMINISTRADOR HOSPITALAR',
  141: 'ADMINISTRADOR OU FUNCIONÁRIO DE COOPERATIVAS',
  142: 'ADVOGADO',
  143: 'AEROMOÇA, COMISSÁRIO DE BORDO, TRIPULANTES DE AERONAVES',
  144: 'AGENCIADOR DE PROPAGANDA, CONTATO PUBLICITÁRIO',
  145: 'AGENCIADOR, VENDEDOR, PRODUTOR SEGUROS E ASSEMELHADOS',
  146: 'AGENTE ADMINISTRATIVO',
  147: 'AGENTE DE SERVICOS FUNERARIOS E EMBALSAMADOR',
  148: 'AGENTE DE VIAGEM E GUIA DE TURISMO',
  149: 'AGENTE TRIBUTOS, ARRECADADOR',
  150: 'AGENTE DE VETOR/SANITAR, GUARDA ENDEMIAS, FISCAL DE HIGIENE',
  151: 'AGENTE, VISTORIADOR DE CARGAS OU MERCADORIAS',
  152: 'AGRICOLAS, PECUARIA, FLORESTAIS, PESCA',
  153: 'AGRIMENSOR',
  155: 'AJUDANTE DE MOTORISTA, AJUDANTE DE CAMINHÃO',
  156: 'AJUDANTE GERAL , AJUD/AUX. DE SERVIÇOS GERAIS',
  157: 'ALFAIATE, COSTUREIRA, ESTILISTA, CONFECCIONISTA',
  158: 'ALMOXARIFE, ESTOQUISTAS E ATIVIDADES RELACIONADAS',
  159: 'ANALISTA DE PRODUÇÃO, ANALISTA DE MATERIAIS',
  160: 'ANALISTA DE SISTEMAS',
  161: 'ANALISTA DE TREINAMENTO',
  162: 'ANALISTA SUPORTE TÉCNICO INFORMÁTICA, TÉCNICO REDE',
  163: 'ANALISTA/ ASSESSOR/ TÉCNICO DO JUDICIÁRIO',
  164: 'ANALISTA/ ASSISTENTE FINANCEIRO, DE INVESTIMENTO, CRÉDITO',
  165: 'ANALISTA/ ASSISTENTE/ ASSESSOR/ TÉCNICO ADMINISTRATIVO',
  168: 'APOSENTADO (POR TEMPO DE SERVIÇO)',
  169: 'ARBITRO ESPORTIVO',
  170: 'ARQUEÓLOGO',
  171: 'ARQUITETO',
  172: 'ARQUIVISTA',
  173: 'ARTESÃO',
  174: 'ASCENSORISTA',
  175: 'ASSESSOR/ASSISTENTE PARLAMENTAR OU DE GABINETE',
  176: 'ASSISTENTE SOCIAL',
  177: 'ASSISTENTE/ SECRETÁRIO ESCOLAR',
  178: 'ASTROLOGO',
  179: 'ASTRONOMO E METEOROLOGISTA',
  180: 'ATENDENTE DE GUICHÊ, BILHETEIRO, EMISSOR DE PASSAGEM',
  181: 'ATENDENTE EM HOSPITAIS, CONSULTORIOS, LABORATORIOS',
  182: 'ATENDENTE/AUXILIAR ESCOLAR, DE CRECHE/BERÇARIO',
  183: 'ATIV. REMUNERADA PELA PRATICA ESPORTE AMADOR',
  184: 'ATIV. REMUNERADA PELA PRATICA ESPORTE PROFISSIO',
  185: 'ATLETA PROFISSIONAL E TECNICO EM DESPOR',
  186: 'ATOR E DIRETOR DE ESPETACULOS PUBLICOS',
  188: 'ATUARIO, MATEMÁTICO, ESTATÍSTICO',
  189: 'AUDITOR (EMPRESAS)',
  190: 'AUDITOR/FISCAL FEDERAL, ESTADUAL, MUNICIPAL',
  192: 'AUXILIAR DE ESCRITORIO',
  193: 'AUXILIAR/TÉCNICO NA AREA SAÚDE (EXCT ENFERMAGEM)',
  195: 'BANCARIO',
  196: 'BIBLIOTECARIO, MUSEÓLOGO E ATVS CORRESPONDENTES',
  197: 'BIOLOGO, GENETICISTA, FISIOLOGISTA, BIOLOGISTA',
  198: 'BIOMÉDICO',
  199: 'BIOQUIMICO',
  201: 'BOMBEIRO',
  202: 'BORRACHEIRO, BICICLETEIRO',
  203: 'CABELEREIRO, BARBEIRO, MANICURE, PEDICURE, ASSEMELHADOS',
  204: 'CANTOR, COMPOSITOR',
  205: 'CAPITALISTA, RECEBENDO RENDIMENTO DE AP',
  206: 'CARCEREIRO, AGENTE PENITENCIÁRIO',
  207: 'CARREGADORES E EMBALADORES EM GERAL',
  208: 'CARTEIRO',
  209: 'CARTÓGRAFO',
  210: 'CERAMISTA, TELHADISTA (FABRICADOR DE TELHAS), OLEIRO',
  211: 'CHAVEIRO',
  212: 'CINEASTA',
  213: 'COBRADOR DE ONIBUS E DEMAIS TRANSPORTES COLETIVOS',
  214: 'COLOCADOR DE GESSO, MARMORISTA E ATVS RELACIONADAS',
  215: 'COLOCADOR DE LUMINOSOS, OUTDOORS, LETREIROS',
  216: 'COMANDANTE DE EMBARCACOES',
  217: 'COMANDANTE/ PILOTO DE AERONAVES, INSTRUTOR DE VÔO',
  218: 'COMERCIÁRIO-BALCONISTA,CAIXA,VENDED ESTB COMERC',
  220: 'COMPRADOR - ANIMAIS/PROD AGRÍCOLAS',
  221: 'COMPRADOR - INDÚSTRIA, SERVIÇOS E COMÉRCIO',
  222: 'COMUNICOLOGO',
  223: 'CONFERENTE (PORTUARIO)',
  224: 'CONSTRUTOR',
  225: 'CONSULTOR',
  226: 'CONSULTOR ADMINISTRATIVO, CONTÁBIL, FINANCEIRO',
  227: 'CONSULTOR DE INFORMÁTICA/SISTEMAS',
  228: 'CONSULTOR JURÍDICO',
  229: 'CONSULTOR(A), VENDEDOR(A) DE COSMÉTICOS',
  231: 'CONTADOR, ANALISTA CONTÁBIL (EXCT TÉCNICOS)',
  232: 'CONTRAMESTRE DE EMBARCACOES',
  233: 'COORDENADOR DE VÔO, TECNICO TRAFEGO EM AEROPORTOS',
  234: 'COORDENADOR/ CHEFE/ SUPERVISOR ADMINISTRATIVO',
  235: 'COORDENADOR/ SUPERVISOR EM EMPRESAS DE TRANSPORTES',
  236: 'COREOGRAFO, BAILARINO, DANÇARINO',
  237: 'CORRETOR BOLSA DE MERCADORIAS',
  238: 'CORRETOR DE IMOVEIS, TITULOS E VALORES',
  239: 'CORRETOR DE SEGUROS',
  240: 'COVEIRO',
  241: 'COZINHEIRA, MERENDEIRA, DOCEIRA, CONFEITEIRA',
  242: 'DATILOSCOPISTA, PAPILOSCOPISTA POLICIAIS',
  243: 'DECORADOR, PAISAGISTA, VITRINISTA',
  244: 'DEGUSTADOR, ENOLOGO, SOMMELIER',
  245: 'DELEGADO DE POLICIA',
  246: 'DEMONSTRADOR, DIVULGADOR, PROMOTOR DE VENDAS',
  247: 'DENTISTA',
  248: 'DESENHISTA ARTÍSTICO, DESENHISTA PUBLICITÁRIO',
  249: 'DESENHISTA COMERCIAL',
  250: 'DESENHISTA TECNICO',
  251: 'DESENHISTA, PROJETISTA, DESIGNER IND OU GRÁFICO',
  252: 'DESPACHANTE (INCLUSIVE O ADUANEIRO)',
  253: 'DETETIVE, INVESTIGADOR PARTICULAR',
  254: 'DIGITADOR',
  255: 'DIRETOR DE EMPRESAS',
  256: 'DIRETOR DO ESTABELECIMENTO DE ENSINO',
  257: 'DIRETOR, CONTROLLER, SUPERINTENDENTE ADMINISTRA',
  258: 'DIRIGENTE PARTIDÁRIO, SINDICALISTAS EM GERAL',
  259: 'DJ, SONOPLASTA, OPERADOR DE SOM',
  260: 'DO LAR',
  261: 'ECONOMISTA',
  262: 'EDITOR DE LIVROS',
  263: 'ELETRICISTA DE MANUTENçãO DE REDES PúBL',
  264: 'ELETRICISTA DE MANUTENCAO DE VEICULOS A',
  265: 'ELETRICISTAS E ASSEMELHADOS',
  266: 'ELETRICISTAS EM GERAL',
  267: 'ELETRICITÁRIO (TRAB.EMPRESA ENERGIA ELÉTRICA)',
  268: 'EMBAIXADOR, DIPLOMATA, CONSUL, ASS CHANCELARIA',
  269: 'EMPREG DOMESTICO(DIARISTA,BABA,CASEIRO,GOVERNA)',
  271: 'EMPRESARIO E PRODUTOR DE ESPETACULOS PUBLICOS',
  272: 'EMPRESÁRIO ESPORTIVO',
  274: 'EMPRESÁRIO/PROPRIET ASSESSORIA EMPRESARIAL',
  275: 'EMPRESÁRIO/PROPRIET COM AUTOPEÇAS,SERV AUTOMOTIVOS',
  276: 'EMPRESARIO/PROPRIET BANCA JORNAIS,REVISTAS',
  277: 'EMPRESÁRIO/PROPRIET CLIN.MÉDICA, LABORATÓRIO,HOSPITAIS',
  278: 'EMPRESÁRIO/PROPRIET DE GRÁFICA',
  279: 'EMPRESARIO/PROPRIET EDITORA, LIVRARIA',
  280: 'EMPRESARIO/PROPRIET EMPR IMPORTAÇÃO, EXPORTAÇÃO',
  281: 'EMPRESÁRIO/PROPRIET EMPRESAS COMUNICAÇÃO,RÁDIO,TV',
  282: 'EMPRESÁRIO/PROPRIET EMPRESA DE INFORMÁTICA/TELECOM',
  283: 'EMPRESÁRIO/PROPRIET EMPRESA DE TURISMO',
  284: 'EMPRESÁRIO/PROPRIET EMPRESA MINERAÇÃO,PEDREIRA',
  285: 'EMPRESARIO/PROPRIET ESTAB AGRICOL, DA PECUAR E SIM',
  286: 'EMPRESARIO/PROPRIET ESTAB COMERCIAL, LOJISTA',
  287: 'EMPRESARIO/PROPRIET ESTAB ESCOLAR, EDUCACIONAL',
  288: 'EMPRESARIO/PROPRIET ESTAB INDUSTRIAL',
  289: 'EMPRESÁRIO/PROPRIET ESTACIONAMENTOS',
  290: 'EMPRESARIO/PROPRIET HOTEL, MOTEL, POUSADA, RESORT',
  291: 'EMPRESÁRIO/PROPRIET IMOBILIÁRIA, ADM CONDOMÍNIOS',
  292: 'EMPRESARIO/PROPRIET DE LOJAS VEÍCULOS, CONCESSIONÁR',
  293: 'EMPRESARIO/PROPRIET PADARIA, BAR, RESTAURANTE',
  294: 'EMPRESÁRIO/PROPRIET PESQUEIRO,SERV.MARÍTIMOS',
  295: 'EMPRESARIO/PROPRIET POSTO DE GASOLINA',
  296: 'EMPRESARIO/PROPRIET TRANSPORTADORA/ DISTRIBUIDORA',
  297: 'EMPRESÁRIO/PROPRIET ACADEMIA, GINÁSTICA,ESPOR',
  298: 'EMPRESARIO/TECNICO EMPRES DE SEGURANCA,',
  299: 'ENCANADOR OU INSTALADOR DE TUBULAÇÕES',
  300: 'ENFERMEIRO',
  301: 'ENGENHEIRO',
  302: 'ENGENHEIRO AGRONOMO',
  303: 'ENGENHEIRO DE MINAS',
  304: 'ENGENHEIRO FLORESTAL',
  305: 'ENGENHEIRO NUCLEAR',
  306: 'ENTREGADOR, MOTOBOY E ASSEMELHADOS',
  307: 'ESCRITOR, AUTOR DE LIVROS',
  308: 'ESCRIVÃO POLICIAL/JUDICIAL',
  309: 'ESCULTOR, PINTOR ARTIST, ARTISTA PLÁSTICO, ARTESÃO',
  310: 'ESPOLIO',
  311: 'BOLSISTA, ESTAGIARIO, TRAINEE',
  312: 'ESTENOGRAFO, DATILOGRAFO, TAQUÍGRAFO E ASSEMELHADOS',
  313: 'ESTETICISTA / MASSAGISTA / MASSOTERAPEUTA E ASSEMEL',
  314: 'ESTUDANTE',
  315: 'ESTUDANTE EM INTERCAMBIO',
  316: 'FARMACEUTICO E ATVS AUXILIARES RELACIONADAS',
  317: 'FAXINEIRA, ARRUMADEIRA, CAMAREIRA, COPEIRA',
  318: 'FEIRANTE',
  319: 'FERRAMENTEIRO',
  320: 'FILOSOFO, SOCIOLOGO, HISTORIADOR E ASSEMELHADOS',
  321: 'FISCAL',
  322: 'FISCAL/ SUPERVISOR/ MESTRE EM OBRAS CONSTR. CIVIL',
  323: 'FISICO',
  324: 'FISICO NUCLEAR',
  325: 'FISIOTERAPEUTA E TERAPEUTA OCUPACIONAL',
  326: 'FONOAUDIOLOGO',
  327: 'FOTOGRAFO',
  328: 'FRENTISTA E DEMAIS TRABALHADORES DE POSTOS DE GASOLINA',
  329: 'FUNCIONARIO PUBLICO CIVIL APOSENTADO',
  330: 'FUNCIONÁRIO/SERVIDOR PÚBLICO (FUNÇÕES BUROCRÁTICAS)',
  331: 'FUNILEIRO',
  332: 'GARAGISTA, MANOBRISTA',
  333: 'GARÇON, MAITRE, BAR MAN E ASSEMELHADOS',
  334: 'GARI, TRABALHADOR CONSERVAÇÃO ÁREAS PÚBLICAS',
  335: 'GARIMPEIRO',
  336: 'GEOGRAFO',
  337: 'GEOLOGO',
  338: 'GERENTE ADMINISTRATIVO',
  339: 'GERENTE DE VENDAS, GERENTE COMERCIAL',
  340: 'GERENTE/ADMIN ESTAB COMERCIAL(EXCT BARES E SIM)',
  341: 'GERENTE/ADMINISTRAD ESTAB AGRICOL, PECUAR E SIM',
  342: 'GERENTE/ADMINISTRAD HOTEL,MOTEL,POUSADA,RESORT',
  343: 'GERENTE/ADMINISTRAD PADARIAS,BARES,RESTAURANTES',
  344: 'GOVERNANTA DE HOTEL, CAMAREIRO, PORTEIR',
  345: 'GUARDA CIVIL E MUNICIPAL',
  346: 'GUARDA NOTURNO',
  348: 'INSPETOR DE DUTOS',
  349: 'INSPETOR/ VISITADOR NAVAL',
  350: 'INSTALADOR DE GÁS, AGUA E ESGOTO E ASSEMELHADOS',
  351: 'INSTALADOR DE PISOS, AZULEGISTA, PASTILHEIRO',
  352: 'INSTALADOR DE TV A CABO, CABISTA',
  353: 'INSTALADOR/MANTENEDOR ANTENAS, TELHADOS E ASSEM',
  354: 'INSTALADOR/ MANTENEDOR ELEVADORES',
  355: 'INSTALADOR//TÉCNICO EM EQUIPAMENTOS ELETRÔNICOS',
  356: 'INSTRUMENTADOR CIRURGICO',
  357: 'INSTRUTOR DE AUTO ESCOLA',
  358: 'INSTRUTOR, MONITOR CURSOS TÉCNICOS, DE INFORMÁTICA',
  359: 'JARDINEIRO, AUXILIAR DE JARDINEIRO',
  360: 'JOALHEIRO, OURIVES, DESIGNER DE JÓIAS',
  362: 'JORNALEIRO',
  363: 'JORNALISTA, REPORTER, ASSESSOR DE IMPRENSA',
  364: 'JUIZ DE DIREITO',
  365: 'LANTERNEIRO E PINTOR DE VEICULOS',
  366: 'LAVADOR DE AUTOS',
  368: 'LAVRADOR, TRABALHADOR RURAL ( AGRICULTURA E PECUARIA)',
  369: 'LEILOEIRO, AVALIADOR, INSPETOR DE AVARIAS',
  370: 'LEITURISTA DE AGUA, LUZ E GÁS',
  371: 'LOCUTOR, RADIALISTA, COMENTARISTA DE RADIO E TV',
  372: 'MAGISTRADO',
  374: 'MAQUINISTA E FOGUISTA DE EMBARCACOES, L',
  375: 'MAQUINISTA FERROVIÁRIO, FOGUISTA',
  376: 'MARCENEIRO, SERRALHEIRO, CARPINTEIRO E ASSEMELHADOS',
  377: 'MARCHAND, ANTIQUÁRIO, NEGOCIADOR DE ARTE',
  378: 'MARINHEIRO E ASSEMELHADO',
  381: 'MECÂNICO MANUTENÇÃO VEÍC AUTOMOTORES, AERONAVES',
  382: 'MECANICO MANUTENCAO,MONTADOR,PREPAR,OPERAD MAQUINAS',
  383: 'MEDICO',
  384: 'MERGULHADOR',
  385: 'MESTRE E CONTRAMESTRE',
  386: 'MILITAR EM GERAL',
  387: 'MILITAR REFORMADO POR INVALIDEZ',
  388: 'MILITAR REFORMADO POR TEMPO DE SERVIÇO',
  389: 'MINISTRO DO STF, DESEMBARGADOR',
  390: 'MINISTRO E SECRETÁRIO MUNICIPAL, ESTADUAL E FEDERAL',
  391: 'MODELO (MODA, FOTOGRÁFICO)',
  393: 'MONITOR, INSTRUTOR DE MENORES (FEBEM E SIMILARES)',
  394: 'MOTOQUEIRO',
  395: 'MOTORISTA BOIADEIRO',
  396: 'MOTORISTA DE GUINCHO, REBOQUE',
  397: 'MOTORISTA-TRANSP DE CARGA',
  398: 'MOTORISTA-TRANSP DE PASSAGEIROS (EXCT TAXISTAS)',
  399: 'MUSICO, MAESTRO, PROF MÚSICA, REGENTE CORAIS',
  403: 'NUTRICIONISTA E ASSISTENTE DE NUTRIÇÃO',
  404: 'OCEANÓGRAFO',
  407: 'ODONTOLOGO',
  408: 'OFFICE-BOY / CONTÍNUO',
  409: 'OFICIAIS DO EXÉRCITO, MARINHA E AERONÁUTICA',
  410: 'OFICIAL DE JUSTICA',
  411: 'OPERADOR DE COMPUTADOR/CPD, SCHEDULLER',
  413: 'OPERADOR DE CAMERAS CINEMA E TV, CINEGRAFISTA',
  414: 'OPERADOR DE IMAGEM E DE VÍDEO',
  415: 'OPERADOR DE TELEMARKETING',
  417: 'OPERADOR DE MÁQUINAS NA CONSTRUÇÃO CIVIL',
  418: 'OPERADOR MERCADO FINANCEIRO, FACTORING, CAMBIO',
  419: 'OPERADOR OFF SETT',
  420: 'OPERADOR DE PREGAO DA BOLSA DE VALORES',
  421: 'OPERADOR, INSPETOR DE COBRANÇA',
  422: 'OPERADORES DE MAQUINAS INDUSTRIAIS',
  423: 'OTICO, CONTACTOLOGO',
  435: 'OUTROS - MEDICOS, ODONTOLOGOS, ENFERMEI',
  446: 'OUTROS - TECNICOS',
  449: 'PADEIRO, PANIFICADOR',
  450: 'PARAMEDICO',
  451: 'PARLAMENTARES: SENADORES, DEPUTADOS, VEREADORES',
  452: 'PAVIMENTADOR, CALCETEIRO',
  453: 'PEAO DE RODEIO',
  454: 'PEDAGOGO, ORIENTADOR/COORDENADOR PEDAGOGICO',
  455: 'PEDREIRO E DEMAIS TRABS BRAÇAIS NA CONSTR CIVIL',
  456: 'PENSIONISTA',
  457: 'PERITO CRIMINAL/DO JUDICIARIO E AUXS CORRESPOND',
  458: 'PERITO, VISTORIADOR EM SEGUROS',
  459: 'PILOTO AUTOMOBILÍSTICO',
  460: 'PINTOR ARTISTICO',
  461: 'PINTOR (COM EQUIPAMENTOS DE SEGURANCA ADEQUADOS)',
  462: 'PINTOR (SEM EQUIPAMENTOS DE SEGURANCA ADEQUADOS)',
  463: 'POLICIAL CIVIL, MILITAR E INVESTIGADOR POLICIAL',
  464: 'POLICIAL,AGENTE FLORESTAL, AMBIENTAL',
  466: 'PORTEIRO',
  468: 'PRESIDENTE DA REPUBLICA, GOVERNADORES, PREFEITOS',
  469: 'PRESIDENTE/VICE PRESIDENTE ADMINISTRATIVO',
  471: 'PRO. E EMP',
  473: 'PROCURADOR PÚBLICO',
  474: 'PROF. E COMERC.',
  477: 'PROF/COMERC.',
  478: 'PROFESSOR',
  479: 'PROFESSOR DE ENSINO SUPERIOR',
  480: 'PROFESSOR EDUC FISICA/ESPORTES,PERSONAL TRAINER',
  481: 'PROFESSOR ENSINO FUNDAM, MÉDIO, SUPERIOR, LÍNGUAS',
  484: 'PROGRAMADOR VISUAL',
  485: 'PROGRAMADOR, TÉCNICO, TECNÓLOGO EM INFORMÁTICA',
  486: 'PROMOTOR DE EVENTOS, PROMOTER, HOSTESS',
  487: 'PROMOTOR DE JUSTIÇA, DEFENSOR PÚBLICO',
  488: 'PROPRIETARIO DE IMOVEL, RECEBENDO RENDI',
  490: 'PROTéTICO',
  491: 'PROTETICO, TÉCNICOS E AUXILIARES EM PROTESE',
  492: 'PSICOLOGO , PSICOTERAPEUTA, PSICANALISTA',
  493: 'PUBLICITARIO, PROPAGANDISTA',
  494: 'QUIMICO',
  495: 'RADIOTERAPEUTA',
  496: 'RECENSEADOR, PESQUISADOR',
  497: 'RECEPCIONISTA',
  498: 'REITOR, DIRETOR, ADMINISTRADOR ESCOLAR',
  499: 'RELACOES PUBLICAS',
  500: 'RODOVIáRIO',
  501: 'SACERDOTE, PASTOR, MEMBRO ORDENS, SEITAS RELIGIO',
  502: 'SALVA VIDAS',
  503: 'SAPATEIRO',
  504: 'SECRETÁRIA (O), TÉCNICA(O) EM SECRETARIADO',
  505: 'SECURITARIO',
  506: 'SERVENTUARIO DE JUSTICA',
  507: 'SERVIDOR PúBLICO MUNIICPAL - AGENTE DE',
  508: 'SINDICANTE/INVESTIGADOR EM SEGUROS',
  510: 'SOCIOLOGO',
  511: 'SOLDADOR,CHAPEADOR,CALDEREIRO,MONTADOR ESTR MET',
  512: 'SUPERVISOR DE PRODUÇÃO E MANUTENÇÃO INDUSTRIAL',
  513: 'SUPERVISOR/ INSPETOR DE ALUNOS',
  514: 'SUPERVISORES, INSPETOR E AGENTE DE COMP',
  515: 'TABELIAO, OFICIAIS DE REGISTRO/CARTORIO',
  516: 'TAPECEIRO, TECELÃO (MÓVEIS E DECORAÇÃO)',
  517: 'TAXISTA',
  518: 'TEC. TURISMO',
  521: 'TÉCNICO AGRÍCOLA, EM AGRONOMIA/AGRIMENSURA',
  522: 'TECNICO DE BIOLOGIA',
  523: 'TECNICO DE INSTRUMENTACAO INDUSTRIAL',
  524: 'TéCNICO EDIF.',
  525: 'TÉCNICO EM ELETRODOMÉSTICOS, REFRIGERAÇÃO E MAQS COSTUR',
  526: 'TECNICO ELETRONICO',
  527: 'TECNICO EM AGRONOMIA E AGRIMENSURA',
  528: 'TECNICO EM CONTABILIDADE OU ESTATÍSTICA',
  529: 'TECNICO EM ELETRONICA, ELETROTÉCNICO',
  530: 'TÉCNICO EM SEGURANÇA DO TRABALHO',
  531: 'TÉCNICO EQUIPAMENTOS HOSPITALARES, ODONTOLOGICOS',
  532: 'TÉCNICO EM MAQUINAS COPIADORAS (XEROX E SIMILARES)',
  533: 'TÉCNICO MINERAÇÃO (PETRÓLEO E GÁS NATURAL)',
  534: 'TECNICO, AUXILIAR LABORATORIO/PATOLOGIA CLINICA',
  535: 'TÉCNICO, TECNOLOGO EM MECANICA/ENGENHARIA',
  536: 'TÉCNICO, TECNOLOGO EM QUIMICA',
  537: 'TÉCNICO/AGENTE EM TELECOMUNICAÇÕES TELEFONIA',
  538: 'TÉCNICO/AUX MINERAÇÃO, MINERADOR (CARVÃO E METAIS)',
  539: 'TÉCNICO/AUXILIAR EM ENFERMAGEM',
  540: 'TÉCNICO/AUXILIAR EM RADIOLOGIA, RAIO X',
  541: 'TÉCNICO/ INSTALADOR DE AR CONDICIONADO',
  542: 'TÉCNICO E/OU INSTALADOR DE SOM',
  543: 'TÉCNICO/OPERARIO OBRAS ESTRADAS OU RODOVIAS',
  544: 'TÉCNICO/TECNOLOGO EM EDIFICAÇÕES',
  545: 'TECNOLOGO',
  546: 'TELEFONISTA, TELEGRAFISTA E ATVS RELACIONADAS',
  547: 'TESOUREIRO',
  548: 'TINTUREIRO, LAVADEIRA E, AUXS CORRESPONDENTES',
  550: 'TOPÓGRAFO E AUXILIAR DE TOPOGRAFIA',
  551: 'TORNEIRO MECÂNICO',
  552: 'TOSADOR DE ANIMAIS',
  553: 'TRABALHADOR EM SERVS RODOVIÁRIOS - CONTROLE DE TRÁFEGO',
  558: 'TRABALHADOR DE FABRICACAO DE CALCADOS E',
  559: 'TRABALHADOR DE FABRICACAO DE ROUPAS',
  561: 'TRABALHADOR DE USINAGEM DE METAIS',
  562: 'TRABALHADOR EM PLATAFORMAS PETROLÍFERAS',
  563: 'TRABALHADOR EM SERVIÇOS POSTAIS (EXCT CARTEIRO)',
  564: 'TRABALHADOR FABR PRODTS BORRACHA E PLASTICO',
  565: 'TRABALHADOR FABR PRODTS TEXTEIS,CALCADOS,CONFEC',
  566: 'TRABALHADOR FABRIC VEICS AUTOMOTORES, AERONAVES',
  567: 'TRABALHADOR NA FABRICAÇÃO DE ALIMENTOS E BEBIDAS',
  568: 'TRABALHADOR IND TRATAMENTO DE FUMO E FAB DE CIGARR',
  569: 'TRABALHADOR DA INDÚSTRIA GRAFICA',
  570: 'TRABALHADOR METALURGICO OU SIDERURGICO',
  571: 'TRABALHADOR NA FABRICACAO DE PAPEL E PAPELAO',
  572: 'TRABALHADOR PORTUÁRIO, ESTIVADOR - EXCT CONFERENTE',
  573: 'TRABALHADOR EM SERVS RODOVIÁRIOS - PEDÁGIOS',
  574: 'TRABALHADOR/TÉCNICO EM SANEAMENTO',
  576: 'TRABALHO RURAL',
  577: 'TRADUTOR E INTÉRPRETE',
  578: 'TRATORISTA, OPERADORES DE MÁQUINAS AGRÍCOLAS',
  579: 'TRIPULANTES DE EMBARCAÇOES',
  580: 'VAQUEIRO',
  582: 'VENDEDOR',
  583: 'VENDEDOR AMBULANTE, CAMELÔ E ASSEMELHADOS',
  584: 'VENDEDOR DE COMERCIO VAREJISTA E ATACAD',
  585: 'VENDEDOR, REPRESENTANTE COMERCIAL',
  586: 'VETERINARIO E ZOOTECNISTA',
  587: 'VIDRACEIRO',
  588: 'VIGILANTE, VIGIA, GUARDA NOTURNO, SEGURANÇA',
  589: 'WEB DESIGNER, ANALISTA/PROGRAM COMPUTAC GRAFICA',
  590: 'ZELADOR',
  591: 'EMPRESARIO DA CONSTRUCAO CIVIL, EMPREITEIRO',
  592: 'ENGENHEIRO-EXCT.MINAS,NUCLEAR,FLORES,CI',
  593: 'ESTILISTA E MODELISTA',
  594: 'MONITOR DE ALARMES',
  595: 'CIENT.POLITICO,FILOSO,SOCIOL,HISTORIADO',
  596: 'PSIQUIATRA',
  597: 'CHEFE DE COZINHA',
  598: 'POLICIAL FEDERAL E RODOVIARIO',
  599: 'NAO DESEJO INFORMAR',
  600: 'NAO IDENTIFICADO - BPN',
  602: 'AUTO ELÉTRICO',
  603: 'ESTACIONAMENTO PÚBLICO',
  604: 'PODÓLOGO',
  605: 'PROFESSOR DE INFORMÁTICA',
}

export const companyActivityArea = {
  1: 'ATIVIDADE ESPECIAL',
  2: 'ABRASIVOS FABRICAS - COM OU SEM INFLAM',
  3: 'ACADEMIA DE GINASTICA/DANCAS/LUTAS/ESCOLA DE NATACAO OU ESPORTES',
  4: 'ACOUGUE/PEIXARIA',
  5: 'ACUCAR - DEPOSITOS',
  6: 'AG. BANCÁRIAS/LOJAS OU COOP. DE CRÉDITO/CASAS DE CÂMBIO',
  7: 'AG. TURISMO (EXCLUINDO CÂMBIO DE VALORES)-TERREO OU SOBR',
  8: 'AG. TURISMO OU VIAGENS(EXCLUINDO CAMBIO DE VALORES)PRIMEIRO ANDAR',
  9: 'BEBIDAS NAO ALCOOL - DEPOSITO OU LOJA',
  10: 'BEBIDAS NAO ALCOOL - FABRICAS/ENGARRAFAM',
  11: 'ANUNCIOS LUMINOSOS,LETREIROS OU PAINEIS - FABRICA E/OU OFICINAS',
  12: 'ANTIGUIDADES, LOJAS DE',
  13: 'APARELHOS ORTOPEDICOS - DEPOSITO OU LOJ',
  14: 'ARMARINHOS - DEPOSITOS SEM VENDAS',
  15: 'ARMARINHOS - LOJAS',
  16: 'ARTIGOS P/PRESENTES/ARMARINHOS/ARTIGOS D',
  17: 'ARMAS ( LOJAS OU DEPOSITOS )',
  18: 'ARMAS (FABRICAS)',
  19: 'ARROZ-DEPOSITO C/ BENEF S/ PROCESSO DE D',
  20: 'ARTIGOS RELIGIOSOS (EXCLUIDO MATERIAIS EXPLOSIVOS)',
  21: 'ASILOS/CASA DE REPOUSO/CLÍNICAS PARA TRA',
  22: 'AUTOMÓVEIS- EXCLUISIVAMENTE LOCAÇÃO',
  23: 'AUTOMOVEIS (EXCLUSIVAMENTE CONCESSIONARI',
  24: 'AUTOMOVEIS-LOJA/DEPOSITO (EXCLUIDO MOTOS',
  25: 'AUTOMOVEIS,OFICINA.MEC,FUNILARIA,ELETRIC',
  26: 'AUTOMOVEIS -COMPONENTES OU ACESSORIOS,LO',
  27: 'AVICOLAS - LOJA (EXCLUINDO ABATEDOURO)',
  28: 'AVICULTURA - DEPOSITOS OU LOJAS',
  29: 'BANHA (DEPOSITOS E LOJAS)',
  30: 'BAR, BOTEQUIM, LANCHONETE E PASTELARIA',
  31: 'SALÃO DE BUFFET',
  32: 'BEBIDAS - DEPOSITOS SEM VENDA',
  33: 'BEBIDAS - DEPOSITOS COM VENDA (DISTRIBUI',
  34: 'BEBIDAS -LOJAS/DEPÓSITOS',
  35: 'BEBIDAS ALCOOLICAS - FABRICAS',
  36: 'BICICLETAS - DEPSSITOS/LOJA PERMITINDO O',
  37: 'BIJOUTERIAS-FÁBRICAS-EXCLUINDO METAIS,ARTEFATOS,JÓIAS,PEDRAS(PREC/SEMI',
  38: 'BIJOUTERIAS-LOJA/DEP-EXCLUINDO METAIS,ARTEFATOS (S/PREC/SEMIPRECIOSOS)',
  39: 'BILHARES OU BOLICHES, SALOES',
  40: 'BOLSAS,CINTOS,MALAS OU SIMILARES-LOJA/DEPOSITO',
  41: 'BOLSAS, CINTOS, MALAS OU CONGENERES - DE',
  42: 'BOLSAS,CINTOS,MALAS OU SIMILARES-FÁBRICA',
  43: 'BORRACHEIRO (SEM RECAUCHUTAGEM)',
  44: 'BORRACHA - LOJA OU DEPOSITO DE ARTIGOS',
  45: 'BORRACHA - FABRICA DE ARTIGOS',
  46: 'BOTOES - FABRICA (EXCLUIDO DE MADEIRA)',
  47: 'BRINQUEDOS - FABRICA DE (SEM EMPREGO DE',
  48: 'BRINQUEDOS - LOJAS OU DEPOSITOS',
  49: 'CABELEIREIROS, SALAO DE BELEZA E/OU ESTETICA',
  50: 'CAFE - DEPOSITO S/VENDA (INCLUSIVE BENEF',
  51: 'CAFE - DEPOSITO C/VENDA (INCLUSIVE BENEF',
  52: 'CALCADOS - FABRICA',
  53: 'CALCADOS - DEPOSITOS OU LOJAS',
  54: 'CALCADOS (SAPATEIRO) - OFICINA',
  55: 'CARIMBOS/CHAVEIROS -DEPÓSITOS OU LOJAS',
  56: 'CARIMBOS - FABRICAS OU OFICINAS',
  57: 'CARTORIOS',
  58: 'CASAS DE BANHO (SAUNAS)',
  59: 'HOTÉIS/MOTÉIS/POUSADAS)',
  60: 'CHAVEIRO',
  61: 'CEMITERIOS/VELORIOS/CREMATORIOS',
  62: 'CIMENTO - DEPOSITO OU LOJAS DE ARTIGOS D',
  63: 'CIMENTO/GESSO-FÁBRICA DE ARTIGOS',
  64: 'CIMENTO - DEPOSITO OU LOJA DE CIMENTO',
  65: 'CIRURG,HOSPIT,DENT,ORTOP,ART(EXCETO MEDICAMENTO/EQP.ELETR) DEP/LOJA',
  66: 'CIRURGICOS, HOSPITALARES OU DENTARIOS -',
  67: 'COLCHOES - DEPOSITOS OU LOJAS SEM OFICIN',
  68: 'CLUBE RECREATIVO',
  69: 'CONFEITARIA',
  70: 'POLAS (ENVASAMENTO E EMBALAGEM)',
  71: 'COLCHOES, FABRICA DE',
  72: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL - DEPOSITOS OU LOJAS',
  73: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL - FABRICAS',
  74: 'CONSULTORIO/CLINICA MEDICA OU DENTARIA (',
  75: 'CONSULTORIO/CLINICA MEDICA OU DENTARIA (A PARTIR DO 1º ANDAR)',
  76: 'CONVENTOS/MOSTEIROS',
  77: 'COPIADORA PERMITINDO-SE ENCADERNACAO, PLASTIFICACAO E OUTROS INERENTES',
  78: 'CORDOARIA (CORDAS, CORDOES) - DEPOSITO',
  79: 'CORDOARIA (CORDAS, CORDOES) - FABRICAS',
  80: 'CORREIOS/ ENCOMENDAS EXPRESSAS (EXCLUIND',
  81: 'COUROS OU PELES - DEPOSITO OU LOJAS DE A',
  82: 'COUROS OU PELES - FABRICA DE ARTIGOS SEM',
  83: 'DEFENSIVOS AGRICOLAS-LOJA/DEPOSITO(EXCLUI SEMENTES)',
  84: 'DESOCUPADO - IMOVEL COMERCIAL/INDUSTRIAL',
  85: 'DESPACHANTES (ESCRITORIO)',
  86: 'DESENTUPIDORA',
  87: 'CDS, DVDS E SIMILARES - LOJAS',
  88: 'DIVERSOES ELETRONICAS',
  89: 'DOCES, BALAS, CHOCOLATES E SIMILARES-FÁBRICA',
  90: 'DOCES,BALAS,CHOCOLATES E SIMILARES-LOJAS/DEPOSITOS',
  91: 'ELETRICOS/ILUMINAÇÃO OU ELETRICIDADE -DEPÓSITO/LOJAS DE ARTIGOS',
  92: 'ELETRODOMESTICOS, LOJAS / DEPOSITOS',
  93: 'ELETRODOMESTICOS - (OFICINA DE CONSERTO)',
  94: 'ELETROELET-LOJA/DEP.C/OFICINA',
  95: 'ELETROELETRONICOS - FABRICAS DE PECAS E',
  96: 'EMBARCACOES (LOJA / DEPOSITO - SEM VENDA DE MOTORES DE POPA)',
  97: 'EMPRESAS DE ONIBUS E/OU TAXIS',
  98: 'EQUIP. PROTEÇÃO INDIVIDUAL (EPI)-LOJAS/DEPÓSITO',
  99: 'ENCOMENDAS EXPRESSAS - TERREO',
  100: 'ENCOMENDAS EXPRESSAS (A PARTIR 1.ANDAR)',
  101: 'ESCOLAS PUBLICAS(ESTADUAIS, MUNICIPAIS,',
  102: 'ESCOLAS PARTICULARES E/OU FILANTROPICAS',
  103: 'ESCOLAS PARTICULARES E/OU FILANTROPICAS-',
  104: 'ESCRITORIOS DEMAIS - TERREO/SOBRADOS(EXC',
  105: 'ESCRITORIOS DEMAIS-A PARTIR PRIMEIRO AND',
  106: 'ESCRITORIOS DE SERV INFORMATICA OU PUBLIC - TERREO OU SOBRADO',
  107: 'ESCRITORIOS DE SERV INFOR OU PUBLIC -A P',
  108: 'ESTACIONAMENTO E/OU LAVA RAPIDO, SEM OFI',
  109: 'ESPORTE, ARTIGOS DE - LOJA/DEPÓSITO (EXCLUÍNDO ARMAS DE FOGO)',
  110: 'ESTACAO RETRANSMISSORA DE RADIO OU TELEVISAO / TORRE DE RETRANSMISSAO',
  111: 'EXTINTORES (LOJA/DEPOSITO,PERMITINDO OFI',
  112: 'ESTUDIO FOTOGRÁFICO E/OU DE GRAVAÇÃO SOM E IMAGEM',
  113: 'FARINHAS, DEPOSITOS OU LOJAS',
  114: 'EXPOSIÇÕES/CONVÊNCÕES -CENTRO DE (EXCLUINDO BENS DE TERCEIROS)',
  115: 'FARMACIAS OU DROGARIAS (DEPOSITOS)',
  116: 'FARMACIAS OU DROGARIAS (LOJAS)',
  117: 'FARMACEUTICOS, PRODUTOS - FÁBRICA',
  118: 'FERRAGENS OU FERRAMENTAS-SEM VENDA DE MATERIAL DE CONST (LOJA/DEPÓSITO',
  119: 'FLORES/PLANTAS NATURAIS -LOJAS (EXCLUINDO VIVEIRO/ESTUFA)',
  120: 'FLORES ARTIFICIAIS, FABRICA OU LOJAS',
  121: 'ESTUDIO FOTOGRAFICO, FOTOGRAFO, LABORATO',
  122: 'FOTOGRAFICO, DEPOSITO OU LOJA DE ARTIGO',
  123: 'FRIGORIFICO (MERC. EM CAMARAS DE REFRIG.',
  124: 'EMBUTIDOS (SEM MATADOURO) -FÁBRICA',
  125: 'FRUTAS, DEPOSITOS OU LOJAS',
  126: 'FUMOS,CHARUTOS/CIGARROS (LOJAS PERMITINDO-SE ART.P/FUMANTES)',
  127: 'FUNEBRES (ARTIGOS) S/OFICINA/ESTOFAMENTO',
  128: 'FUNEBRES (LOJAS C/OFIC. DE ARMADORES, S/',
  129: 'GELO -FAB DEP OU LOJAS',
  130: 'GRAFICAS OU TIPOGRAFIAS (COM OU SEM ROTO',
  131: 'GUARDA-CHUVAS, GUARDA-SOIS OU BENGALAS -',
  132: 'GUARDA-CHUVAS,GUARDA-SOIS OU BENGALAS -',
  133: 'HOSPITAIS',
  134: 'IGREJAS/MOSTEIROS/CONVENTOS',
  135: 'IMOB., CORRET. E ADM. DE IMOVEIS - TERRE',
  136: 'INSTRUMENTOS MUSICAIS-LOJA/DEPOSITO',
  137: 'INFORMÁTICA/ELETROELETRONICO-FABRICA OU MONTAGEM DE PEÇAS/EQUIP',
  138: 'INFORMATICA - PROVEDORES DE INTERNET',
  139: 'JOIAS, FABRICA DE',
  140: 'JOIAS, COM OU SEM OFICINA, LOJA DE',
  141: 'INFORMATICA/ELETROELETRONICO-LOJA/DEPOSI',
  142: 'JOIAS, FABRICA DE (PREDIO E MAQUINISMO)',
  143: 'JOIAS, COM OU SEM OFICINA, LOJA DE (PRED',
  144: 'KARTODROMO',
  145: 'LAB. ANALISE CLINICAS, QUIMICAS OU FARMA',
  146: 'LADRILHOS OU CERAMICAS, FABRICA DE',
  147: 'LATICINIOS, LOJAS / DEPOSITOS',
  148: 'LATICINIOS, FABRICA',
  149: 'LAN HOUSE/CYBER CAFE (EXCLUSIVAMENTE EM',
  150: 'LAN HOUSE/CYBER CAFE (FORA DE SHOPPING CENTERS OU HIPERMERCADOS)',
  151: 'LAVANDERIAS, SEM TINTURARIA (EXCETO INDUSTRIAL)',
  152: 'LIMPEZA, LOJA OU DEPOSITO DE ARTIGOS DE',
  153: 'LIMPEZA, FABRICA DE ARTIGOS DE',
  154: 'LIVROS,REVISTAS,JORNAIS,CDS E DVDS-LOJA/DEPOSITO (S/BANCA DE JORNAL',
  155: 'LOCADORA',
  156: 'SHOPPINGS, LOJAS EM - ENQUADR. P/ ATIVI',
  157: 'LONA, FABRICAS DE ARTIGOS DE',
  158: 'LONA, ARTIGOS DE LOJA',
  159: 'LOTERICAS',
  160: 'LOUÇAS/PORCELANAS/LADRILHOS/CERAMICAS (E',
  161: 'LUSTRES - DEPOSITOS OU LOJAS',
  162: 'LUSTRES - FABRICA',
  163: 'MADEIRA (LOJA/DEPOSITO)',
  164: 'MARCENARIA (CARPINTARIA)',
  165: 'MARMORARIA',
  166: 'MÁQ ELETROMECANICAS COMERCIAIS/INDRUTRIAIS -LOJA/DEPÓSITO',
  167: 'MARMORE/GRANITO- LOJA/DEPOSITO PERMITINDO FABRICAÇÃO DE ARTIGOS',
  168: 'MASSAS ALIMENTICIAS -FABRICAS OU LOJAS',
  169: 'MATERIAL PARA CONSTRUÇÃO-LOJA/DEPÓSITO',
  170: 'MEIAS, FABRICA DE',
  171: 'MEIAS, DEPOSITOS E/OU LOJAS DE',
  172: 'METAL, FABRICA DE (FERRO OU ACO EM BLOCO',
  173: 'METAL(EXCLUINDO DE PRECIOSO/SEMIPRECIOSO E SUCATAS)-DEPÓSITO',
  174: 'METAL(EXCLUINDO PRECIOSO/SEMIPRECIOSO E SUCATAS)FAB DE ART E MÁQ.',
  175: 'MOLDURAS, DEPOSITO OU LOJA COM OU SEM OF',
  176: 'MOLDURAS SEM OFICINA -LOJA/DEPÓSITO',
  177: 'MOTOCICLETAS (EXCLUSIVAMENTE CONCESSIONA',
  178: 'MÓVEIS COM OFICINA LOJA/DEPÓSITO',
  179: 'MÓVEIS SEM OFICINA -LOJA/DEPÓSITO',
  180: 'MOVEIS, FABRICA COM ESTOFAMENTO',
  181: 'MOVEIS, FABRICA SEM ESTOFAMENTO',
  182: 'ONGSS / ENTIDADES FILANTROPICAS',
  183: 'MAQ.ELETROMEC COMERC E INDUSTRIAIS-OFICINAS',
  184: 'OLEOS VEGETAIS, DEPOSITOS OU LOJAS DE',
  185: 'FABRICA',
  186: 'ÓTICA-LOJA/DEPÓSITO',
  187: 'PADARIAS/CONFEITARIAS',
  188: 'PAPEL - LOJA OU DEPOSITO (EXCLUIDO DEPOSITO DE DOCUMENTOS) ARTIGOS DE',
  189: 'PAPEL/PAPELAO-FAB DE ART (S/PROC RECICLAGEM E/OU UTILIZ APARAS/SUCATA)',
  190: 'VEDACOES, FABRICAS (EXCLUSIVAMENTE METAL',
  191: 'PEIXARIA',
  192: 'PERFUMARIA/COSMÉTICOS -LOJA/DEPÓSITO',
  193: 'PERFUMARIA/COSMÉTICOS (S/FABRICAÇÃO DE SABONETES OU VERNIZ) FABRICA',
  194: 'PLASTICOS (SEM CELULOIDE) DEPOSITO OU LOJA - EXCLUINDO SUCATAS',
  195: 'PLASTICOS (SEM CELULOIDE), FABRICA DE ARTIGOS - EXCLUINDO SUCATAS',
  196: 'PET SHOP/AVICULTURA',
  197: 'POSTOS DE GASOLINA / SERVICOS',
  198: 'INSTRUMENTOS PRECISAO OU MEDICAO (LOJAS',
  199: 'FARMACEUTICOS PROD, - LOJAS/DEP.VIDE FAR',
  200: 'PRODUTOS QUIMICOS (SEM INFLAMAVEIS) - LOJA/DEPOSITO',
  201: 'PRODUTOS QUIMICOS (SEM INFLAMAVEIS) -FABRICA',
  202: 'PRODUTOS QUIMICOS, DEPOSITO DE PRODUTOS',
  203: 'PROTESE, OFICINAS',
  204: 'QUITANDAS(FRUTAS,VERDURAS E LEGUMES) - LOJAS/DEPÓSITO',
  205: 'RAÇÕES BALANCEADAS-FÁBRICA',
  206: 'RAÇÕES BALANCEADAS -LOJAS/DEPÓSITOS',
  207: 'RESTAURANTE, CHOPERIA, CHURRASCARIA, PIZZARIA',
  208: 'RESIDENCIA',
  209: 'ROUPAS, FABRICAS OU OFICINAS DE',
  210: 'ROUPAS, LOJAS DE',
  211: 'ROUPAS, DEPOSITO DE',
  212: 'ROUPAS PARA LOCAÇÃO -LOJA/DEPOSITO (PERMITINDO FÁBRICA)',
  213: 'RECARGA DE CARTUCHOS DE IMPRESSAO',
  214: 'SABÃO OU SABONETE, FÁBRICA',
  215: 'SABAO OU SABONETE, DEPOSITOS OU LOJAS DE',
  216: 'SAL- DEPÓSITO',
  217: 'SERRALHERIAS- (S/TRABALHO COM MADEIRA)',
  218: 'SHOPPING CENTER',
  219: 'SILK SCREEN/ESTAMPARIA DE ROUPAS E TECIDOS-PERMITINDO A VENDA ARTIGOS',
  220: 'SINDICATO DE EMPRESAS E/OU TRABALHADORES (SEM MARCENARIA)',
  221: 'SORVETE/GELO - LOJA/DEPÓSITO',
  222: 'SUPERMERCADOS,MERCEARIAS/EMPORIOS (SECOS E MOLHADOS) - LOJAS/DEPOSITOS',
  223: 'TAPETES,CARPETES,FORRACOES OU CORTINAS (',
  224: 'TAPETES,CARPETES,FORRACÕES OU CORTINAS (S/MADEIRA)-LOJA/DEPÓSITO',
  225: 'TEATROS, SALOES DE CONCERTOS OU CINEMAS(EXCLUIDOS CIRCOS OU SIMILARES)',
  226: 'TECIDOS OU FIOS (FIACAO OU TECELAGEM COM FIBRAS NATURAIS)',
  227: 'TECIDOS OU FIOS (FIACAO OU TECELAGEM COM FIBRAS ARTIFICIAIS)',
  228: 'TECIDOS OU FIOS (PERMITE ARTIGOS CAMA/MESA/BANHO)-LOJA/DEPOSITO',
  229: 'TELEFONES, INCLUSIVE CELULARES - LOJA (EXCLUSIVAMENTE EM SHOPPINGS)',
  230: 'TINTAS, LOJA/DEPOSITO, S/ MANIP, PERMITINDO-SE MAQUINA P/MISTURA CORES',
  231: 'TINTAS (SEM INFLÁMAVEIS) - FÁBRICA',
  232: 'TINTURARIAS (INCLUSIVE LAVANDERIAS INDUSTRIAIS) DE ROUPAS/FIOS/TECIDOS',
  233: 'TRANSPORTADORA - EXCLUIDO DE INFLAMAVEIS, TOXICOS OU CORROSIVOS',
  234: 'VETERINARIO',
  235: 'VIDROS E/OU ARTIGOS DE VIDROS, FÁBRICA',
  236: 'VIDROS OU ESPELHOS (VIDRACEIRO)-LOJAS',
  237: 'TRANSPORTE DE PASSAGEIROS',
  238: 'FATURAMENTO VIDA - DIVERSAS ATIVIDADES',
  239: 'SEGURO PARA EDIFICIO COM VARIAS EMPRESAS',
  240: 'LOTERICAS - (ACORDO CEF)',
  241: 'IMOBIL./CORRET. E ADM DE IMOVEIS A PARTI',
  242: 'CONCESSIONÁRIA -EXCLUSIVAMENTE REVENDA AUTORIZADA)',
  243: 'GUARDA MOVEIS OU STORAGE',
  244: 'ESCOLA DE ENSINO FUNDAMENTAL, MÉDIO E CRECHES',
  245: 'UNIVERSIDADES / FACULDADES',
  246: 'ESCOLA - PROFISSIONALIZANTES / TECNICOS',
  247: 'ESCOLA DE IDIOMAS, CURSO PREPARATÓRIO OU AUTO ESCOLA',
  248: 'ESCOLA DE INFORMATICA',
  249: 'CONDOMÍNIO HORIZONTAL/EMPRESARIAL',
  250: 'BANCAS DE JORNAL',
  251: 'BEBIDAS NAO ALCOOLICAS - FABRICA/ENGARRAFAMENTO',
  252: 'BIBLIOTECA/SEBO (EXCLUSIVO PARA PREDIO)',
  253: 'COZINHA INDUSTRIAL/MASSAS ALIMENTICIAS/ROTISSERIE',
  254: 'LABORATORIO DE DIAGNOSTICO/ANALISES CLINICAS',
  255: 'LABORATORIO DE PESQUISA/ANALISE FISICA/QUIMICA',
  256: 'LOJAS DE DEPTO. (EXCETO ALIMENTOS) - PERMITINDO DEPOSITOS',
  257: 'MERCADO PUBLICO (EXCLUSIVO PARA PREDIO)',
  258: 'MUSEU/ANTIQUIDADE (EXCLUSIVO PARA PREDIO)',
  259: 'SORVETE/GELO (SEM EMPREGO DE MAT. INFLAMAVEL) - FABRICA',
  260: 'MULTILET (EXCLUSIVO PARA PREDIO)',
  261: 'FARINHAS E FARELOS - FÁBRICA',
  262: 'GRÃOS E CEREAIS - DEPÓSITO OU LOJA (EXCETO A GRANEL)',
  263: 'FRUTAS, VERDURAS E LEGUMES, COM BENEFICIAMENTO (EXCLUINDO ESTUFA)',
  264: 'SEMENTES - DEPÓSITO OU LOJA (EXCETO A GRANEL)',
  265: 'AUTOMOVEIS - AUTO ELETRICO, PERMITINDO VENDA DE PECAS DE AUTO-ELETRICO',
  266: 'CONSULTORIO/CLINICA DENTARIA (PAVTO TERREO OU SOBRADO)',
  267: 'CONSULTORIO/CLINICA DENTARIA (A PARTIR DO 1 ANDAR)',
  268: 'CORTICA - DEPOSITO OU LOJA DE ARTIGOS',
  269: 'DOCES, IND. E COM.',
  270: 'ELETRODOMESTICOS - LOJA E DEPOSITO',
  271: 'ELETROELET-LOJA/DEP.C/OFIC.CONSERTO',
  272: 'ESCRITORIOS - LIMPEZA,VIGILANC,COBRANCA,ENGENH,CONSTRUT-TERREO/SOBRADO',
  273: 'ESCRIT ENGENHARIA/SERV LIMPEZA, VIGILANCIA, COBRANCA- A PARTIR 1. AND',
  274: 'SERV.TEMP, COBR, PREST.SERV DE VIG/CONST(TERREO/SOBRADO)',
  275: 'DEMAIS ESCRIT, EXCETO DESP,BCO,SINDICATO (TERREO/SOBRADO)',
  276: 'AGENCIA DE TURISMO E VIAGENS (A PARTIR 1.ANDAR)',
  277: 'SERV.TEMP,COB,PREST.SERVICO,VIG,CONSTR.(A PARTIR 1.ANDAR)',
  278: 'DEMAIS ESCRIT,EXCETO DESP,BCO,SINDICATO(A PARTIR 1.ANDAR)',
  279: 'ESCRITORIOS DE SERVICOS DE INFORMATICA',
  280: 'ESCRIT (EXCETO MICROS/PERIF.) DEP E LOJAS C/EXISTENCIA DE MAQ ART',
  281: 'FERRAGENS OU FERRAMENTAS, DEPOSITO DE',
  282: 'ESTUDIO FOTOGRAFICO, FOTOGRAFO, LABORATORIO FOTOGRAFICO',
  283: 'GRAFICAS OU TIPOGRAFIAS COM ROTOGRAVURA',
  284: 'PRODUTO PROPRIO - HOTEIS E POUSADAS',
  285: 'INFORMATICA - ESCRITORIO DE SERVICOS DE INFORMATICA',
  286: 'INFORMATICA - LOJA OU DEPOSITO SEM OFICINA DE CONSERTO',
  287: 'MAQ./APARELHOS ELETRO-MECANICOS C/MOTOR INCORP. (LOJAS/DEP.)',
  288: 'OFIC. MECANICA E/OU ELETRICA S/VENDA DE PECAS E ACESSORIOS',
  289: 'PECAS E ACESSORIOS P/VEICULOS (EXCETO MOTOC) DEP OU LOJAS',
  290: 'TORRE DE RETRANSMISSAO',
  291: 'SECOS E MOLHADOS (SUPERMERCADO, MERCEARIA), LOJA (SEM VENDA)',
  292: 'COMERCIO VAREJISTA',
  293: 'COMERCIO ATACADISTA',
  294: 'OUTROS',
  295: 'CONSULTORIOS, ESCOLAS, ESCRITORIOS E HOSPITAIS',
  296: 'COMERCIO E SERVICOS',
  297: 'INDUSTRIAS',
  298: 'IMOVEIS DESOCUPADOS NAO LOCADOS',
  299: 'COMERCIO - 2',
  300: 'TRANSPORTADORA',
  301: 'LEGADO OUTROS',
  302: 'Atividades auxiliares dos seguros, da previdência complement',
}

export const customerActivityArea = {
  1: 'ATIVIDADE ESPECIAL',
  2: 'ABRASIVOS FÁBRICAS - COM OU SEM INFLAMÁVEIS',
  3: 'ACADEMIA DE GINÁSTICA/DANÇAS/LUTAS/ESCOLA DE NATAÇÃO OU ESPO',
  4: 'AÇOUGUE/PEIXARIA',
  5: 'AÇUCAR - DEPÓSITOS',
  6: 'AGÊNCIAS BANCÁRIAS/LOJAS OU COOP. DE CRÉDITO/CASAS DE CÂMBIO',
  7: 'AGÊNCIA DE TURISMO (EXCLUINDO CÂMBIO DE VALORES) - TERREO OU',
  99: 'FUNEBRES (ARTIGOS) S/OFICINA/ESTOFAMENTO',
  100: 'FUNEBRES (LOJAS C/OFIC. DE ARMADORES)',
  8: 'AGÊNCIA DE TURISMO OU VIAGENS (EXCLUINDO CÂMBIO DE VALORES)',
  9: 'BEBIDAS NÃO ALCOOLICAS (DEPÓSITO OU LOJA)',
  10: 'ANTIGUIDADES (LOJAS)',
  11: 'ARMARINHOS DEPÓSITOS SEM VENDAS',
  12: 'ARMARINHOS (LOJAS)',
  13: 'ARTIGOS P/PRESENTES/ARMARINHOS/ARTIGOS D',
  14: 'ARMAS (LOJAS OU DEPÓSITOS)',
  15: 'ARMAS (FÁBRICAS)',
  16: 'ARROZ (DEPÓSITO C/ BENEF S/ PROCESSO DE D)',
  17: 'ASILOS/CASA DE REPOUSO/CLÍNICAS PARA TRATAMENTO',
  18: 'AUTOMÓVEIS (EXCLUSIVAMENTE LOCAÇÃO)',
  19: 'AUTOMÓVEIS (EXCLUSIVAMENTE CONCESSIONARIA)',
  20: 'AUTOMÓVEIS - LOJA/DEPÓSITO (EXCLUIDO MOTOS)',
  21: 'AUTOMÓVEIS, OFICINA MEC, FUNILARIA, ELÉTRICA',
  22: 'AUTOMÓVEIS - COMPONENTES OU ACESSÓRIOS, LO',
  23: 'AVÍCOLAS - LOJA (EXCLUINDO ABATEDOURO)',
  24: 'BANHA (DEPÓSITOS E LOJAS)',
  25: 'SALÃO DE BUFFET',
  26: 'BEBIDAS DEPÓSITOS SEM VENDA',
  27: 'BEBIDAS DEPÓSITOS COM VENDA (DISTRIBUIDORA)',
  28: 'BEBIDAS (LOJAS OU DEPÓSITOS)',
  29: 'BEBIDAS ALCOOLICAS (FÁBRICAS)',
  30: 'BIJOUTERIAS FÁBRICAS - EXCLUINDO METAIS, ARTEFATOS, JÓIAS, P',
  31: 'BIJOUTERIAS LOJA/DEP - EXCLUINDO METAIS, ARTEFATOS (S/PREC/S',
  32: 'BILHARES OU BOLICHES, SALÕES',
  33: 'BOLSAS, CINTOS, MALAS OU CONGENERES - DE',
  34: 'BOLSAS, CINTOS, MALAS OU SIMILARES (FÁBRICA)',
  35: 'BORRACHEIRO (SEM RECAUCHUTAGEM)',
  36: 'BORRACHA (LOJA OU DEPÓSITO DE ARTIGOS)',
  37: 'BRINQUEDOS - FÁBRICA DE (SEM EMPREGO DE',
  38: 'CABELEIREIROS, SALÃO DE BELEZA E/OU ESTÉTICA',
  39: 'BEBIDAS NÃO ALCOOLICAS (FÁBRICA/ENGARRAFAMENTO)',
  40: 'CAFÉ - DEPÓSITO C/VENDA (INCLUSIVE BENEF',
  41: 'CALÇADOS (FÁBRICA)',
  42: 'CALÇADOS (DEPÓSITOS OU LOJAS)',
  43: 'CALCADOS (SAPATEIRO) - OFICINA',
  44: 'BEBIDAS NÃO ALCOOLICAS (FÁBRICAS/ENGARRAFAM)',
  45: 'CARIMBOS (FÁBRICAS OU OFICINAS)',
  46: 'CARTÓRIOS',
  47: 'CASAS DE BANHO (SAUNAS)',
  48: 'HOTÉIS/MOTÉIS/POUSADAS',
  49: 'ANUNCIOS LUMINOSOS, LETREIROS OU PAINEIS (FÁBRICA E/OU OFICI',
  50: 'CEMITÉRIOS/VELÓRIOS/CREMATÓRIOS',
  51: 'CIMENTO (DEPÓSITO OU LOJAS DE ARTIGOS)',
  52: 'CIMENTO/GESSO (FÁBRICA DE ARTIGOS)',
  53: 'CIMENTO (DEPÓSITO OU LOJA DE CIMENTO)',
  54: 'CIRURGICOS, HOSPITALARES OU DENTÁRIOS',
  55: 'COLCHÕES (DEPÓSITOS OU LOJAS SEM OFICINA)',
  56: 'CLUBE RECREATIVO',
  57: 'CONFEITARIA',
  58: 'APARELHOS ORTOPÉDICOS (DEPÓSITO OU LOJA)',
  59: 'COLCHÕES, FÁBRICA DE',
  60: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL (DEPÓSITOS OU LOJAS',
  61: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL (FÁBRICAS)',
  62: 'CONSULTÓRIO/CLINICA MEDICA OU DENTÁRIA',
  63: 'CONSULTÓRIO/CLINICA MEDICA OU DENTÁRIA (A PARTIR DO 1º ANDAR',
  64: 'CONVENTOS/MOSTEIROS',
  65: 'COPIADORA PERMITINDO (SE ENCADERNAÇÃO, PLASTIFICAÇÃO E OUTRO',
  66: 'CORDOARIA (CORDAS, CORDÕES) - DEPÓSITO',
  67: 'CORDOARIA (CORDAS, CORDÕES) - FÁBRICAS',
  68: 'COUROS OU PELES (FÁBRICA DE ARTIGOS)',
  69: 'DESOCUPADO - IMOVEL COMERCIAL/INDUSTRIAL',
  70: 'DIVERSÕES ELETRÔNICAS',
  71: 'DOCES, BALAS, CHOCOLATES E SIMILARES (FÁBRICA)',
  72: 'ELÉTRICOS/ILUMINAÇÃO OU ELETRICIDADE (DEPÓSITO/LOJAS DE ARTI',
  73: 'ELETRODOMESTICOS (LOJAS/DEPÓSITOS)',
  74: 'ELETRODOMESTICOS - (OFICINA DE CONSERTO)',
  75: 'ELETROELET (LOJA/DEP.C/OFICINA)',
  76: 'ELETROELETRONICOS (FÁBRICAS DE PECAS)',
  77: 'EMBARCACÕES (LOJA/DEPÓSITO - SEM VENDA DE MOTORES DE POPA)',
  78: 'EMPRESAS DE ONIBUS E/OU TAXIS',
  79: 'EQUIP. PROTEÇÃO INDIVIDUAL (EPI) (LOJAS/DEPÓSITO)',
  80: 'ENCOMENDAS EXPRESSAS - TERREO',
  81: 'ARTIGOS RELIGIOSOS (EXCLUIDO MATERIAIS EXPLOSIVOS)',
  82: 'ESCOLAS PARTICULARES E/OU FILANTROPICAS',
  83: 'AUTOMÓVEIS - AUTO ELETRICO, PERMITINDO VENDA DE PECAS DE AUT',
  84: 'EXTINTORES (LOJA/DEPÓSITO, PERMITINDO OFINA)',
  85: 'ESTUDIO FOTOGRÁFICO E/OU DE GRAVAÇÃO SOM E IMAGEM',
  86: 'FARINHAS (DEPÓSITOS OU LOJAS)',
  87: 'EXPOSIÇÕES/CONVÊNÇÕES (EXCLUINDO BENS DE TERCEIROS)',
  88: 'FARMACIAS OU DROGARIAS (DEPÓSITOS)',
  89: 'FARMACIAS OU DROGARIAS (LOJAS)',
  90: 'FARMACEUTICOS, PRODUTOS (FÁBRICA)',
  91: 'FERRAGENS OU FERRAMENTAS - SEM VENDA DE MATERIAL DE CONST (L',
  92: 'FLORES/PLANTAS NATURAIS - LOJAS (EXCLUINDO VIVEIRO/ESTUFA)',
  93: 'AVICULTURA (DEPÓSITOS OU LOJAS)',
  94: 'ESTUDIO FOTOGRÁFICO, FOTOGRAFO, LABORATORIO',
  95: 'ESTUDIO FOTOGRÁFICO (DEPÓSITO OU LOJA DE ARTIGO)',
  96: 'EMBUTIDOS (SEM MATADOURO) - FÁBRICA',
  97: 'FRUTAS (DEPÓSITOS OU LOJAS)',
  98: 'FUMOS, CHARUTOS/CIGARROS (LOJAS APENAS PERMITIDO - SE ART.P/',
  101: 'GELO (FÁBRICA, DEPÓSITO OU LOJAS)',
  102: 'HOSPITAIS',
  103: 'IGREJAS/MOSTEIROS/CONVENTOS',
  104: 'IMOBILIÁRIA, CORRETORA E OU ADM DE IMÓVEIS - TERREO',
  105: 'INSTRUMENTOS MUSICAIS (LOJA/DEPÓSITO)',
  106: 'INFORMÁTICA/ELETROELETRÔNICO (FÁBRICA OU MONTAGEM DE PEÇAS/E',
  107: 'INFORMÁTICA - PROVEDORES DE INTERNET',
  108: 'JOIAS, FÁBRICA',
  109: 'INFORMATICA/ELETROELETRONICO (LOJA OU DEPÓSITO)',
  110: 'JOIAS, FÁBRICA DE (PRÉDIO E MAQUINISMO)',
  111: 'JOIAS, COM OU SEM OFICINA, LOJA',
  112: 'KARTODROMO',
  113: 'LAB. ANALISE CLINICAS, QUIMICAS OU FARMA',
  114: 'LADRILHOS OU CERAMICAS, FÁBRICA',
  115: 'LATICINIOS (LOJAS OU DEPÓSITOS)',
  116: 'LATICÍNIOS (FÁBRICA)',
  117: 'LAN HOUSE/CYBER CAFÉ',
  118: 'BAR, BOTEQUIM, LANCHONETE E PASTELARIA',
  119: 'LIMPEZA (LOJA OU DEPÓSITO DE ARTIGOS DE LIMPEZA)',
  120: 'LIMPEZA (FÁBRICA)',
  121: 'LONA (FÁBRICA)',
  122: 'LONA (LOJA)',
  123: 'LOTÉRICAS',
  124: 'MADEIRA (LOJA OU DEPÓSITO)',
  125: 'MARCENARIA (CARPINTARIA)',
  126: 'MÁQ ELETROMECÂNICAS COMERCIAIS/INDRUTRIAIS (LOJA OU DEPÓSITO',
  194: 'MUSEU/ANTIQUIDADE (EXCLUSIVO PARA PRÉDIO)',
  195: 'SORVETE/GELO (SEM EMPREGO DE MAT. INFLAMÁVEL) - FÁBRICA',
  127: 'MARMORE/GRANITO (LOJA/DEPÓSITO PERMITINDO FÁBRICAÇÃO DE ARTI',
  128: 'MASSAS ALIMENTÍCIAS (FÁBRICA OU LOJA)',
  129: 'MATERIAL PARA CONSTRUÇÃO (LOJA OU DEPÓSITO)',
  130: 'MEIAS, DEPÓSITOS E/OU LOJAS',
  131: 'METAL, FÁBRICA DE (FERRO OU ACO EM BLOCO)',
  132: 'METAL (EXCLUINDO DE PRECIOSO/SEMIPRECIOSO E SUCATAS) DEPÓSIT',
  133: 'METAL (EXCLUINDO PRECIOSO/SEMIPRECIOSO E SUCATAS) FAB DE ART',
  134: 'MOLDURAS, DEPÓSITO OU LOJA COM OU SEM OFICINA',
  135: 'MOLDURAS SEM OFICINA (LOJA OU DEPÓSITO)',
  136: 'MOTOCICLETAS (EXCLUSIVAMENTE CONCESSIONÁRIAS)',
  137: 'MÓVEIS COM OFICINA (LOJA OU DEPÓSITO)',
  138: 'MÓVEIS SEM OFICINA (LOJA OU DEPÓSITO)',
  139: 'MAQ.ELETROMEC COMERC E INDUSTRIAIS (OFICINAS)',
  140: 'ÓLEOS VEGETAIS (DEPÓSITO OU LOJA)',
  141: 'FÁBRICA',
  142: 'ÓTICA (LOJA OU DEPÓSITO)',
  143: 'PADARIAS E OU CONFEITARIAS',
  144: 'PAPEL - LOJA OU DEPÓSITO (EXCLUIDO DEPÓSITO DE DOCUMENTOS)',
  145: 'PAPEL/PAPELÃO - FAB DE ART (S/PROC RECICLAGEM E/OU UTILIZ AP',
  146: 'PEIXARIA',
  147: 'PERFUMARIA/COSMÉTICOS (LOJA OU DEPÓSITO)',
  148: 'PERFUMARIA/COSMÉTICOS (S/FÁBRICAÇÃO DE SABONETES OU VERNIZ -',
  149: 'PLÁSTICOS (SEM CELULOIDE) DEPÓSITO OU LOJA - EXCLUINDO SUCAT',
  150: 'PLÁSTICOS (SEM CELULOIDE), FÁBRICA DE ARTIGOS - EXCLUINDO SU',
  151: 'PET SHOP/AVICULTURA',
  152: 'POSTOS DE GASOLINA/SERVIÇOS',
  153: 'INSTRUMENTOS PRECISÃO OU MEDIÇÃO (LOJAS)',
  154: 'FARMACEUTICOS PROD (LOJAS/DEP.VIDE FAR)',
  155: 'QUITANDAS - FRUTAS, VERDURAS E LEGUMES (LOJA OU DEPÓSITO)',
  156: 'BORRACHA (FÁBRICA DE ARTIGOS)',
  157: 'BOTÕES - FÁBRICA (EXCLUIDO DE MADEIRA)',
  158: 'ROUPAS, DEPÓSITO DE',
  159: 'ROUPAS PARA LOCAÇÃO (LOJA OU DEPÓSITO - PERMITINDO FÁBRICA)',
  160: 'RECARGA DE CARTUCHOS DE IMPRESSÃO',
  161: 'SABÃO OU SABONETE (FÁBRICA)',
  162: 'SABÃO OU SABONETE (DEPÓSITOS OU LOJAS)',
  163: 'SAL (DEPÓSITO)',
  164: 'SERRALHERIAS - (S/TRABALHO COM MADEIRA)',
  165: 'SHOPPING CENTER',
  166: 'SILK SCREEN/ESTAMPARIA DE ROUPAS E TECIDOS (PERMITINDO A VEN',
  167: 'BRINQUEDOS (LOJA OU DEPÓSITO)',
  168: 'TAPETES, CARPETES, FORRAÇÕES OU CORTINAS',
  169: 'TAPETES, CARPETES, FORRAÇÕES OU CORTINAS (S/MADEIRA) - LOJA',
  170: 'TEATROS, SALÕES DE CONCERTOS OU CINEMAS (EXCLUIDOS CIRCOS OU',
  171: 'TECIDOS OU FIOS (FIAÇÃO OU TECELAGEM COM FIBRAS NATURAIS)',
  172: 'TECIDOS OU FIOS (FIAÇÃO OU TECELAGEM COM FIBRAS ARTIFICIAIS)',
  173: 'TECIDOS OU FIOS (PERMITE ARTIGOS CAMA/MESA/BANHO) - LOJA/DEP',
  174: 'TELEFONES, INCLUSIVE CELULARES (LOJA EXCLUSIVAMENTE EM SHOPP',
  175: 'TINTAS (SEM INFLÁMAVEIS) - FÁBRICA',
  176: 'TINTURARIAS (INCLUSIVE LAVANDERIAS INDUSTRIAIS) DE ROUPAS/FI',
  177: 'TRANSPORTADORA - EXCLUÍDO DE INFLAMÁVEIS, TOXICOS OU CORROSI',
  178: 'VETERINÁRIO',
  179: 'VIDROS E/OU ARTIGOS DE VIDROS (FÁBRICA)',
  180: 'VIDROS OU ESPELHOS (VIDRACEIRO) - LOJA',
  181: 'TRANSPORTE DE PASSAGEIROS',
  182: 'SEGURO PARA EDIFICIO COM VARIAS EMPRESAS',
  183: 'CAFÉ - DEPÓSITO S/VENDA (INCLUSIVE BENEF',
  184: 'CONCESSIONÁRIA (EXCLUSIVAMENTE REVENDA AUTORIZADA)',
  185: 'GUARDA MÓVEIS OU STORAGE',
  186: 'ESCOLA DE ENSINO FUNDAMENTAL, MÉDIO E CRECHES',
  187: 'ESCOLA DE IDIOMAS, CURSO PREPARATÓRIO OU AUTO ESCOLA',
  188: 'ESCOLA DE INFORMÁTICA',
  189: 'CONDOMÍNIO HORIZONTAL/EMPRESARIAL',
  190: 'LABORATÓRIO DE DIAGNÓSTICO/ANÁLISES CLÍNICAS',
  191: 'LABORATÓRIO DE PESQUISA/ANÁLISE FISÍCA/QUIMICA',
  192: 'LOJAS DE DEPTO. (EXCETO ALIMENTOS) - PERMITINDO DEPÓSITOS',
  193: 'MERCADO PÚBLICO (EXCLUSIVO PARA PRÉDIO)',
  196: 'CARIMBOS/CHAVEIROS (DEPÓSITO OU LOJA)',
  197: 'FRUTAS, VERDURAS E LEGUMES, COM BENEFICIAMENTO (EXCLUINDO ES',
  198: 'SEMENTES (DEPÓSITO OU LOJA -EXCETO A GRANEL)',
  199: 'CONSULTÓRIO/CLÍNICA DENTÁRIA (PAVIMENTO TERRÉO OU SOBRADO)',
  200: 'CONSULTÓRIO/CLÍNICA DENTÁRIA (A PARTIR DO 1º ANDAR)',
  201: 'CORTIÇA (DEPÓSITO OU LOJA DE ARTIGOS)',
  202: 'DOCES, INDUSTRIA E COMÉRCIO',
  203: 'ELETROELETRÔNICO (LOJA OU DEPÓSITO C/OFIC DE CONSERTO)',
  204: 'ESCRITÓRIOS - LIMPEZA, VIGILANC, COBRANCA, ENGENH, CONSTRUT',
  205: 'ESCRIT ENGENHARIA/SERV LIMPEZA, VIGILÂNCIA, COBRANÇA (A PART',
  206: 'DEMAIS ESCRIT, EXCETO DESP, BCO, SINDICATO (TERREO OU SOBRAD',
  207: 'AGÊNCIA DE TURISMO E VIAGENS (A PARTIR 1º ANDAR)',
  208: 'SERV.TEMP, COB, PREST.SERVICO',
  209: 'DEMAIS ESCRIT, EXCETO DESP, BCO, SINDICATO (A PARTIR 1º ANDA',
  210: 'ESCRITÓRIOS DE SERVIÇOS DE INFORMÁTICA',
  211: 'FERRAGENS OU FERRAMENTAS (DEPÓSITO)',
  212: 'GRáFICAS OU TIPOGRAFIAS COM ROTOGRAVURA',
  213: 'PRODUTO PRÓPRIO (HOTÉIS E POUSADAS)',
  214: 'INFORMÁTICA - ESCRITÓRIO DE SERVICOS DE INFORMÁTICA',
  215: 'INFORMÁTICA (LOJA OU DEPÓSITO SEM OFICINA DE CONSERTO)',
  216: 'MAQ./APARELHOS ELETRO - MECÂNICOS C/MOTOR INCORP. (LOJA OU D',
  217: 'OFIC. MECANICA E/OU ELÉTRICA S/VENDA DE PEÇAS E ACESSÓRIOS',
  218: 'PEÇAS E ACESSÓRIOS P/VEICULOS (EXCETO MOTO - DEPÓSITO OU LOJ',
  219: 'COMÉRCIO ATACADISTA',
  220: 'OUTROS',
  221: 'CONSULTÓRIOS, ESCOLAS, ESCRITÓRIOS E HOSPITAIS',
  222: 'COMÉRCIO E SERVIÇOS',
  223: 'INDÚSTRIAS',
  224: 'IMÓVEIS DESOCUPADOS NÃO LOCADOS',
  225: 'CHAVEIRO',
  226: 'CIRURG, HOSPIT, DENT, ORTOP, ART (EXCETO MEDICAMENTO/EQP.ELE',
  227: 'POLAS (ENVASAMENTO E EMBALAGEM)',
  228: 'CORREIOS/ ENCOMENDAS EXPRESSAS',
  229: 'COUROS OU PELES (DEPÓSITO OU LOJA)',
  230: 'DEFENSIVO AGRÍCOLA E/OU SEMENTES (LOJA/DEPÓS EXCETO A GRANEL',
  231: 'DESPACHANTES (ESCRITÓRIO)',
  232: 'DESENTUPIDORA',
  233: 'CDS, DVDS E SIMILARES (LOJAS)',
  234: 'DOCES, BALAS, CHOCOLATES E SIMILARES (LOJAS OU DEPÓSITOS)',
  235: 'ELETRODOMESTICOS (LOJA E DEPÓSITO)',
  236: 'ENCOMENDAS EXPRESSAS (A PARTIR 1º ANDAR)',
  237: 'ESCOLAS PUBLICAS (ESTADUAIS, MUNICIPAIS)',
  238: 'ESCOLAS PARTICULARES E/OU FILANTRÓPICAS',
  239: 'ESCRITÓRIOS DEMAIS - TERREO/SOBRADOS',
  240: 'ESCRITÓRIOS DEMAIS - A PARTIR 1º ANDAR',
  241: 'ESCRITÓRIOS DE SERV INFOR OU PUBLIC',
  242: 'ESCRITÓRIOS DE SERV INFORMATICA OU PUBLIC (TERRÉO OU SOBRADO',
  243: 'ESCRIT (EXCETO MICROS/PERIF.) DEP E LOJAS C/EXISTENCIA DE MA',
  244: 'ESTACIONAMENTO E/OU LAVA RAPIDO, SEM OFICINA OU ABASTECIMENT',
  245: 'ESPORTE, ARTIGOS DE - LOJA/DEPÓSITO (EXCLUÍNDO ARMAS DE FOGO',
  246: 'ESTAÇÃO RETRANSMISSORA DE RÁDIO OU TELEVISÃO/TORRE DE RETRAN',
  247: 'FLORES ARTIFICIAIS (FÁBRICA OU LOJAS)',
  248: 'ESTUDIO FOTOGRÁFICO, FOTÓGRAFO, LABORATÓRIO FOTOGRÁFICO',
  249: 'FRIGORÍFICO - DEPÓSITOS (SEM BENEFICIAMENTO)',
  250: 'GRÁFICAS OU TIPOGRAFIAS (COM OU SEM ROTOGRAVURA)',
  251: 'GUARDA-CHUVAS, GUARDA-SOIS OU BENGALAS (LOJAS OU OFICINAS)',
  252: 'GUARDA-CHUVAS, GUARDA-SOIS OU BENGALAS (FÁBRICA - EXCLUINDO',
  253: 'JÓIAS, COM OU SEM OFICINA, LOJA DE',
  254: 'LAN HOUSE/CYBER CAFé (FORA DE SHOPPING CENTERS OU HIPERMERCA',
  255: 'LAVANDERIAS, SEM TINTURARIA (EXCETO INDUSTRIAL)',
  256: 'LIVROS, REVISTAS, JORNAIS, CDS E DVDS (LOJA OU DEPÓSITO - S/',
  257: 'LOCADORA',
  258: 'SHOPPINGS, LOJAS EM - ENQUADR. P/ ATIVI',
  259: 'LUSTRES (DEPÓSITO OU LOJA)',
  260: 'LUSTRES (FÁBRICA)',
  261: 'MARMORARIA',
  262: 'MEIAS, FÁBRICA DE',
  263: 'MÓVEIS, FÁBRICA COM ESTOFAMENTO',
  264: 'MÓVEIS, FÁBRICA SEM ESTOFAMENTO',
  265: 'ONGS/ENTIDADES FILANTRÓPICAS',
  266: 'VEDAÇÕES, FÁBRICAS (EXCLUSIVAMENTE METAL)',
  267: 'PRODUTOS QUÍMICOS - SEM INFLAMÁVEIS (LOJA OU DEPÓSITO)',
  268: 'PRODUTOS QUÍMICOS - SEM INFLAMÁVEIS (FÁBRICA)',
  269: 'PRODUTOS QUÍMICOS (DEPÓSITO DE PRODUTOS)',
  270: 'PRÓTESE, OFICINAS',
  271: 'TORRE DE RETRANSMISSÃO',
  272: 'RAÇÕES BALANCEADAS (FÁBRICA)',
  273: 'RAÇÕES BALANCEADAS (LOJAS OU DEPÓSITO)',
  274: 'RESTAURANTE, CHOPERIA, CHURRASCARIA, PIZZARIA',
  275: 'RESIDÊNCIA',
  276: 'ROUPAS (FÁBRICAS OU OFICINAS)',
  277: 'ROUPAS (LOJAS)',
  278: 'SINDICATO DE EMPRESAS E/OU TRABALHADORES (SEM MARCENARIA)',
  279: 'SORVETE/GELO (LOJA OU DEPÓSITO)',
  280: 'SECOS E MOLHADOS (SUPERMERCADO, MERCEARIA), LOJA (SEM VENDA)',
  281: 'SUPERMERCADOS, MERCEARIAS/EMPÓRIOS (SECOS E MOLHADOS) - LOJA',
  282: 'TINTAS, LOJA/DEPÓSITO, SEM MANIPULAÇÃO, PERMITINDO - SE MAQU',
  283: 'COMÉRCIO VAREJISTA',
  284: 'TRANSPORTADORA',
  285: 'LOTÉRICAS (ACORDO CEF)',
  286: 'IMOBILIÁRIA/CORRETORA E ADM DE IMÓVEIS A PARTIR DO PRIMEIRO',
  287: 'UNIVERSIDADES/FACULDADES',
  288: 'ESCOLA - PROFISSIONALIZANTES/TÉCNICOS',
  289: 'HOLDING DE INSTITUIÇÃO NÃO FINANCEIRA',
  290: 'HOLDING DE INSTITUIÇÃO NÃO FINANCEIRA',
  291: 'BANCAS DE JORNAL',
  292: 'BIBLIOTECA/SEBO (EXCLUSIVO PARA PRÉDIO)',
  293: 'COZINHA INDUSTRIAL/MASSAS ALIMENTÍCIAS/ROTISSERIE',
  294: 'MULTILET (EXCLUSIVO PARA PRÉDIO)',
  295: 'FARINHAS E FARELOS (FÁBRICA)',
  296: 'GRÃOS/CEREAIS (EXCLUINDO SEMENTES) LOJA/DEPÓSITO EXCETO A GR',
  297: 'BICICLETAS - DEPÓSITOS/LOJA PERMITINDO O',
  298: 'LOUÇAS/PORCELANAS/LADRILHOS/CERAMICAS',
  299: 'BOLSAS, CINTOS, MALAS OU SIMILARES (LOJA OU DEPÓSITO)',
  300: 'AGRICULTURA - CAFÉ COM SECADOR',
  301: 'AGRICULTURA - CAFÉ SEM SECADOR',
  302: 'AGRICULTURA - CANA DE AÇÚCAR (EXCETO USINA)',
  303: 'AGRICULTURA - FUMO/CHÁ/ERVA MATE',
  304: 'AGRICULTURA - GRÃOS /CEREAIS NÃO OLEAGINOSOS - COM SECADOR',
  305: 'AGRICULTURA - GRÃOS /CEREAIS NÃO OLEAGINOSOS - SEM SECADOR',
  306: 'AGRICULTURA - GRÃOS /CEREAIS OLEAGINOSOS - COM SECADOR',
  307: 'AGRICULTURA - GRÃOS /CEREAIS OLEAGINOSOS - SEM SECADOR',
  308: 'APICULTURA',
  309: 'AQUICULTURA',
  310: 'ATIVIDADES FLORESTAIS',
  311: 'AVICULTURA',
  312: 'BOVINOCULTURA (CORTE)',
  313: 'BOVINOCULTURA (LEITE)',
  314: 'CRIAÇÃO DE ANIMAIS SILVESTRES',
  315: 'CRIAÇÃO DE BÚFALOS',
  316: 'CRIAÇÃO DE COELHOS',
  317: 'EQUINOCULTURA',
  318: 'FLORICULTURA/PLANTAS ORNAMENTAIS',
  319: 'FRUTICULTURA',
  320: 'FUNGICULTURA',
  321: 'HORTICULTURA/OLERICULTURA',
  322: 'OVINOCULTURA/CAPRINOCULTURA',
  323: 'SUINOCULTURA',
  324: 'TURISMO RURAL',
}
