import * as S from './styles'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '../Button'
import { Fragment, useEffect } from 'react'
import { useBodyScrollLock, useIsMobile } from '@/tools'
import {
  personalizationStore,
  tourStore,
  useCurrentOrganization,
} from '@/modules'
import { useMenuButtons } from './menuButtons'
import { useUnit } from 'effector-react'
import { menuStore, setIsMenuOpen } from '@/modules'

export const Menu = ({ essencialEnabled, capitalizacaoEnabled, imobiliariaEnabled, orcamentoLoteEnabled }: { essencialEnabled: boolean, capitalizacaoEnabled: boolean, imobiliariaEnabled: boolean, orcamentoLoteEnabled: boolean }) => {
  const [menu, tour, setIsMenuOpenFn] = useUnit([
    menuStore,
    tourStore,
    setIsMenuOpen,
  ])
  const { broker } = useUnit(personalizationStore)
  const isMobile = useIsMobile()
  const profile = useCurrentOrganization()
  const { setIsLocked } = useBodyScrollLock()

  const menuButtons = useMenuButtons(profile, essencialEnabled, capitalizacaoEnabled, imobiliariaEnabled, orcamentoLoteEnabled)

  useEffect(() => {
    if (isMobile) {
      setIsLocked(menu.isMenuOpen)
    }
  }, [isMobile, menu.isMenuOpen, setIsLocked])

  return (
    <>
      <S.WidthTransitionContainer
        $isJoyrideRunning={!tour.completed}
        onMouseOver={
          !menu.isMenuOpen && !isMobile ? () => setIsMenuOpen(true) : undefined
        }
        onMouseLeave={
          menu.isMenuOpen && !isMobile ? () => setIsMenuOpen(false) : undefined
        }
        $isOpen={menu.isMenuOpen}
      >
        <S.Container>
          <S.Header>
            <S.Logo
              $hidden={!!broker}
              src="/logo-portobank-2023.svg"
              alt="Porto Seguro Bank"
            />
            <S.Name
              $hidden={!!broker}
              color="system-primitive-neutral-800"
              variant="porto-text-caption-regular"
              content="Portal das Imobiliárias"
            />
          </S.Header>
          <S.Content $isJoyrideRunning={!tour.completed}>
            {menuButtons.filter(({ hidden }) => !hidden)
              .map(
                ({
                  label,
                  icon,
                  sectionTitle,
                  menuButtons,
                  url,
                  disabled,
                  tag,
                }) => (
                  <Fragment key={label}>
                    {sectionTitle && (
                      <>
                        <S.Divider />
                        <S.Title>
                          <Typography
                            color="#2C3DA8"
                            variant="porto-text-body-2-bold"
                            content={sectionTitle}
                          />
                        </S.Title>
                      </>
                    )}
                    <Button
                      label={label}
                      icon={icon}
                      tag={tag}
                      subItems={menuButtons}
                      url={disabled ? '#' : url}
                      disabled={disabled}
                    />
                  </Fragment>
                )
              )}
          </S.Content>
          <S.Footer
            color="system-primitive-neutral-700"
            variant="porto-text-label-regular"
            content="Desenvolvido por Porto Bank"
          />
        </S.Container>
      </S.WidthTransitionContainer>
      {menu.isMenuOpen && <S.Overlay onClick={() => setIsMenuOpenFn(false)} />}
    </>
  )
}
