import { Forms, Grid } from '@/components'
import { SocialNameField } from '@/components/Forms/SocialNameField'
import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import { getByDocument } from '@/services/api/customers'
import { Box, Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { RadioGroup } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { isCNPJ, isCPF } from 'brazilian-values'
import { FieldArray, useField, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { BaseCard } from '../../../../components/BaseCard'
import { Socios } from '../../../../components/Socios'

export const Owner = ({ addressType }) => {
  const form = useFormikContext()

  const [landlordField, , landlordHelpers] = useField('landlord')
  const [{ value }, _, { setValue: setPropertyType }] = useField('landlord.address_type')

  const [documentField, documentMetada] = useField('landlord.document')

  const personType = useMemo(() => {
    if (isCPF(documentField.value)) return 'PF'
    if (isCNPJ(documentField.value)) return 'PJ'
  }, [documentField.value])

  const fetchDocumentMutation = useMutation(async () => {
    if (documentMetada.error) return

    const landlordBureauData = await getByDocument(documentField.value).catch(() => null)
    const hasPartnersOnBureau =
      isCNPJ(documentField.value) && !!landlordBureauData?.hasPartnersOnBureau

    landlordHelpers.setValue({
      ...landlordField.value,
      name: landlordBureauData?.name || landlordBureauData?.companyName || '',
      phone: landlordBureauData?.phone || '',
      hasPartnersOnBureau,
      socios: isCNPJ(documentField.value) && !hasPartnersOnBureau ? [''] : null,
    })
  })

  return (
    <BaseCard title="Dados do proprietário">
      <Grid gap="16px">
        <Grid columns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}>
          <Forms.InputGroup
            name="landlord.document"
            label="CPF/CNPJ"
            placeholder="000.000.000-00"
            mask="document"
            onBlur={() => fetchDocumentMutation.mutate()}
            readOnly={fetchDocumentMutation.isLoading}
            loading={fetchDocumentMutation.isLoading}
            data-test-id="owner-cpf"
          />
        </Grid>

        <AnimateVerticalShow
          isOpen={!fetchDocumentMutation.isLoading && documentMetada.touched && !!personType}
        >
          <Grid gap="16px">
            <Grid columns={['1fr', '2fr 1fr']}>
              <Forms.InputGroup
                data-test-id="owner-name"
                name="landlord.name"
                label={personType === 'PF' ? 'Nome Completo' : 'Razão social'}
                placeholder="Digite"
              />
            </Grid>

            <AnimateVerticalShow isOpen={personType === 'PF'}>
              <SocialNameField
                textFieldName="landlord.socialName"
                toggleName="landlord.hasSocialName"
                data-test-id="owner-social-name"
              />
            </AnimateVerticalShow>

            <Grid gap="16px" columns={['1fr', '1fr 2fr']}>
              <Forms.InputGroup
                name="landlord.phone"
                label="Telefone ou Celular"
                placeholder="(00) 90000-0000"
                mask="phone"
                data-test-id="owner-phone"
              />

              <Forms.InputGroup
                name="landlord.email"
                label="E-mail"
                placeholder="nome@email.com.br"
                data-test-id="owner-email"
              />
            </Grid>
          </Grid>
        </AnimateVerticalShow>

        <Typography content="Endereço do proprietário" variant="porto-title-6-medium" />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', lg: 'repeat(3, 1fr)' },
            gap: '1rem',
          }}
        >
          <Forms.InputGroup
            name="landlord.address.zipcode"
            label="CEP"
            placeholder="00000-000"
            mask="zipcode"
            data-test-id="owner-cep"
          />
          <Forms.InputGroup
            name="landlord.address.number"
            label="Número"
            type="number"
            placeholder="Informe o número"
            data-test-id="owner-number"
          />
        </Box>

        <RadioGroup
          titleProps={{ content: 'Tipo de imóvel' }}
          initialChecked={value || 'RESIDENCIAL'}
          options={addressType.map(({ label, value }) => ({
            text: label,
            value,
          }))}
          onChange={setPropertyType}
        />

        <AnimateVerticalShow
          isOpen={
            personType === 'PJ' &&
            !fetchDocumentMutation.isLoading &&
            !landlordField.value?.hasPartnersOnBureau
          }
        >
          <FieldArray
            name="landlord.socios"
            render={helpers =>
              helpers.form.values.landlord?.socios?.map((_, index) => {
                return (
                  <Socios entity="locador" key={`partner-${index}`} index={index} {...helpers} />
                )
              })
            }
            data-test-id="landlord-partners"
          />
        </AnimateVerticalShow>
      </Grid>
    </BaseCard>
  )
}
