import ModalConfirmation from '@/components/Modals/Confirmation'
import { useCurrentUser, useOrganizations } from '@/modules/auth/hooks'
import { useContract } from '@/modules/contract/useContract'
import { api } from '@/services'
import { dashText } from '@/tools'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Button, Icon, Typography } from 'design-system-react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ActionContainer,
  Container,
  DataContainer,
  DataWrapper,
  Divider,
  Header,
  MessageContainer,
  TitleContainer,
} from './styles'

export type NewErrorParams = {
  product: string
  errorTitle?: string
  errorMessage?: string | string[]
  showProductReturnLabel?: boolean
  resendCallback?: Function
  showResendButton?: boolean
  backToRoute?: string
  resendButtonText?: string
  backButtonText?: string
}

const NewError = ({
  product,
  errorTitle,
  errorMessage,
  showProductReturnLabel,
  resendCallback,
  showResendButton = true,
  backToRoute = '/contratos',
  resendButtonText = 'Reenviar',
  backButtonText = 'Voltar ao início',
}: NewErrorParams) => {
  const contract = useContract()
  const currentUser = useCurrentUser()
  const org = useOrganizations()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const partnerName = org.store.current.find(org => org.type === 'REAL_ESTATE')?.name

  const budget = contract.budgets?.find(budget => budget.id === contract.payload?.payment?.budget)

  const data = {
    'ID do contrato:': contract?.id ?? '',
    'Orçamento ou Proposta:': budget?.id ?? contract?.policy?.id ?? '',
    'Data e hora:': format(new Date(), 'Pp', {
      locale: ptBR,
    }),
    'Produto:': product,
    'Susep:': contract.broker?.id ?? '',
    'Nome:': partnerName ?? '',
    'E-mail:': currentUser.store.email ?? '',
  }

  const handleCopy = () => {
    const text = Object.entries(data).reduce((acc, [label, data]) => `${acc}\n${label} ${data}`, '')

    navigator.clipboard.writeText(text.trim())
  }

  const handleResendClick = async () => {
    try {
      setIsLoading(true)
      if (resendCallback) {
        await resendCallback()
      } else {
        await api.contracts.calculate(contract.id)
        history.go(0)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleBackClick = async () => {
    const confirm = await ModalConfirmation({
      icon: 'alert-triangle',
      title: 'Quer mesmo voltar?',
      content:
        'Você será direcionado para a tela de Gestão de Carteira, perdendo as alterações que realizou na proposta.',
      submitText: 'Voltar',
      dismissText: 'Cancelar',
    })

    if (confirm) {
      history.push(backToRoute)
    }
  }

  return (
    <>
      <Header>
        <Typography color="black85" as="h2" type="Title4">
          {product}
        </Typography>
      </Header>
      <Container>
        <TitleContainer>
          <Icon icon="icon-Alert" size="xlarge" color="redPrimary" />
          <Typography color="black75" as="h5" type="Title5">
            {errorTitle || 'Algo deu errado.'}
          </Typography>
        </TitleContainer>

        {!!errorMessage && (
          <MessageContainer>
            {showProductReturnLabel && (
              <Typography color="black85" as="span" type="Body1" weight="bold">
                Retorno do produto:
              </Typography>
            )}

            {Array.isArray(errorMessage) ? (
              errorMessage.map(message => (
                <Typography key={dashText(message)} color="black85" as="p" type="Body1">
                  {message}
                </Typography>
              ))
            ) : (
              <Typography color="black85" as="p" type="Body1">
                {errorMessage}
              </Typography>
            )}
          </MessageContainer>
        )}

        <ActionContainer>
          {showResendButton && (
            <Button
              styles="primary"
              variant="insurance"
              disabled={contract?.metadata?.error?.code === '@aluguel/missing-documents'}
              isLoading={isLoading}
              onClick={handleResendClick}
              data-test-id="resend"
            >
              {resendButtonText}
            </Button>
          )}
          <Button
            styles="secondary"
            variant="insurance"
            data-test-id="back-start"
            onClick={handleBackClick}
          >
            {backButtonText}
          </Button>
        </ActionContainer>
      </Container>
      <DataContainer>
        <Typography color="black85" as="p" type="Body1">
          Você pode solicitar a tratativa deste erro com seu consultor ou direto pelo chat. Basta
          seguir as orientações e enviar as informações abaixo quando solicitadas.
        </Typography>
        <Divider />
        <Button
          icon="icon-Duplicate"
          styles="ghost"
          variant="insurance"
          type="button"
          onClick={handleCopy}
          data-test-id="copy-information"
        >
          Copiar informações para a área de transferência
        </Button>
        <DataWrapper>
          {Object.entries(data).map(([label, data], index) => (
            <Typography
              key={`${index}-${label}`}
              color="black85"
              as="span"
              type="Caption"
              data-test-id={`information-${label}-${data}`}
            >{`${label} ${data}`}</Typography>
          ))}
        </DataWrapper>
      </DataContainer>
    </>
  )
}

export default NewError
