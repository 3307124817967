import * as yup from 'yup'

const organizationSchema = {
  partner: yup
    .string()
    .nullable()
    .notOneOf([''], 'Selecione o parceiro.')
    .required('Selecione o parceiro.'),
  susep: yup
    .string()
    .nullable()
    .notOneOf(['', null], 'Selecione a SUSEP.')
    .required('Selecione a SUSEP.'),
}

export default organizationSchema
