import { motion } from 'framer-motion'
import { rem, size } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'

import { themes } from '@/styles'

import { Ionicons } from '../../Icons'

export const Dialog = styled(motion.div)`
  max-width: 500px;
  min-width: 350px;

  background-color: #fff;
  border-radius: 20px 20px 0 0;

  ${media.greaterThan('large')`
    border-radius: 12px;
  `}
`

export const Head = styled.header`
  display: flex;
  justify-content: flex-end;
`

export const Close = {
  Action: styled.button`
    all: unset;

    padding: 10px;

    cursor: pointer;
    transition: 0.3s;
    color: ${themes.text.low};

    &:hover {
      transition: 0.3s;
      color: ${themes.text.medium};
    }
  `,

  Icon: styled(Ionicons).attrs(() => ({ name: 'close-outline' }))`
    ${size('14px')}

    ${media.greaterThan('huge')`
      ${size('18px')}
    `}
  `,
}

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 0 32px 32px;

  ${media.greaterThan('large')`
    border-radius: 0 0 12px 12px;
  `}
`

export const Icon = styled(Ionicons)`
  font-size: ${rem('60px')};

  stroke-width: 1.5px;
  margin-bottom: 10px;

  color: ${themes.palletes.primary};

  ${media.greaterThan('huge')`
    font-size: ${rem('60px')};
  `}
`

export const Title = styled.p`
  margin-top: ${rem('16px')};
  font-weight: bold;
  font-size: ${rem('16px')};
  color: ${themes.text.higher};
  font-family: ${themes.fonts.title};

  ${media.greaterThan('huge')`
    font-size: ${rem('18px')};
  `}
`

export const Content = styled.div`
  margin-top: ${rem('8px')};

  line-height: 180%;
  font-size: ${rem('11px')};
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}
`
