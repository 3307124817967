import React, { HTMLAttributes } from 'react'

import Tooltip from '../Tooltip'

import { Icon } from 'design-system-react'
import * as S from './styles'

const Info: React.FC<Info.Props> = ({ content, iconName = 'icon-info', ...props }) => (
  <S.Container {...props}>
    <Tooltip maxWidth={400} content={content}>
      <Icon size="large" icon={iconName} />
    </Tooltip>
  </S.Container>
)

export namespace Info {
  export type Props = {
    content: string
    iconName?: string
  } & HTMLAttributes<HTMLDivElement>
}

export default Info
