import { toast } from '@/components'
import {
  formatSecondStepContract,
  payment,
  secondStepForm,
  secondStepSchema,
} from '@/data/capitalizacao'
import { useContract, useCurrentOrganization } from '@/modules'
import { api } from '@/services'
import { usePartnershipQuery } from '@/tools/hooks/partnership'
import { Flex } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Form, FormikProvider, useFormik } from 'formik'
import { set } from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import { ContractErrors } from '../../../shared/ContractErrors'
import { Products } from '../../../shared/entities'
import { Loading } from '../../components'
import { Owner, Payment, Property } from './components'

export const Second = ({ propertyProps, ownerProps, setStep }) => {
  const contract = useContract<Products.CAPITALIZACAO>()
  const history = useHistory()
  const profile = useCurrentOrganization()

  const [errors, setErrors] = useState([])

  const partnershipsQuery = usePartnershipQuery()

  const secondStepFormik = useFormik({
    initialValues: secondStepForm,
    validate: values => {
      try {
        secondStepSchema.validateSync(values, {
          abortEarly: false,
          context: { customerDocument: contract?.payload?.customer?.document },
        })
      } catch (error) {
        if (error.name !== 'ValidationError') {
          throw error
        }

        return error.inner.reduce((errors, currentError) => {
          errors = set(errors, currentError.path, currentError.message)
          return errors
        }, {})
      }

      return {}
    },
    onSubmit: async values => {
      const castedValues = secondStepSchema.cast(values)

      const formattedValues = formatSecondStepContract({ values: castedValues })

      try {
        await api.contracts.update(contract.id, { ...contract.payload, ...formattedValues })

        api.elegibility
          .verifyElegibilityFromContract({
            contractId: contract.id,
          })
          .catch(err => {
            // A chamada desse endpoint não deve impactar o fluxo
            console.error(err)
          })

        const proposal = await api.contracts.order(contract.id, {
          payment: payment[values.payment.type],
        })

        if (proposal) {
          await contract.fetch({
            id: contract.id,
          })

          setStep(3)
        }
      } catch (error) {
        toast('Ocorreu um erro ao tentar transmitir a proposta.', {
          type: 'error',
        })

        if (error.response?.status === 409 && error.response?.data?.cause) {
          setErrors(error.response?.data?.cause)
          window.scrollTo({ top: 0, behavior: 'smooth' })
          partnershipsQuery.refetch()
        }

        throw error
      }
    },
  })

  useQuery(
    ['elegibility', 'verifyElegibility', contract.id],
    () =>
      api.elegibility.verifyElegibilityFromContract({
        contractId: contract.id,
      }),
    {
      enabled: !!contract.id,
      refetchOnWindowFocus: false,
    },
  )

  return (
    <FormikProvider value={secondStepFormik}>
      {errors.length > 0 && (
        <ContractErrors
          icon="home"
          title="Não foi possível prosseguir devido a alterações feitas pela corretora. Revise os seguintes dados:"
          errors={errors}
        />
      )}
      <Form>
        <Loading isLoading={!contract.policy?.draftId && contract.status === 'PENDING'}>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Property {...propertyProps} />
            <Owner {...ownerProps} />
            <Payment contract={contract} />
            <Flex
              sx={{
                gap: '1rem',
                flexDirection: { xs: 'column', lg: 'row' },
              }}
            >
              <Button data-test-id="cancel" kind="ghost" onClick={() => history.push('/contratos')}>
                Cancelar
              </Button>
              <Button
                data-test-id="forward-proposal"
                variant="primary"
                type="submit"
                isLoading={secondStepFormik.isSubmitting}
                isDisabled={secondStepFormik.isSubmitting || !secondStepFormik.isValid}
              >
                Transmitir Proposta
              </Button>
            </Flex>
          </Flex>
        </Loading>
      </Form>
    </FormikProvider>
  )
}
