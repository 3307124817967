import { useHistory } from 'react-router'

import {
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { ButtonShortcut } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useCurrentOrganization } from '@/modules/auth'
import { IHeaderProps } from './interfaces'
import { useIsCapitalizacaoEnabled, useIsEssencialEnabled, useIsImobiliariaBatchEnabled, useIsImobiliariaEnabled } from '@/tools/partnership'
import { Loading } from '@/components'

export const Header = ({
  children,
  side,
  description,
  shortcuts,
  subtitle,
  title,
}: IHeaderProps) => {
  const history = useHistory()
  const profile = useCurrentOrganization()

  const { result: isEssencialEnabled, isLoading: isEssencialEnabledLoading } =
    useIsEssencialEnabled(profile)

  const { result: isCapitalizacaoEnabled, isLoading: isCapitalizacaoEnabledLoading } =
    useIsCapitalizacaoEnabled(profile)

  const { result: isImobiliariaEnabled, isLoading: isImobiliariaEnabledLoading } =
    useIsImobiliariaEnabled(profile)

  const { result: isImobiliariaBatchEnabled, isLoading: isImobiliariaBatchEnabledLoading } =
    useIsImobiliariaBatchEnabled(profile)

  const actions = shortcuts?.filter(({ hide, ...action }) => {
    if (typeof hide === 'function') {
      return !hide(profile) && { ...action }
    }

    return !hide && { ...action }
  })

  return (
    <Flex
      sx={{
        backgroundColor: 'porto-primitive-white',
        padding: {
          xs: '7rem 1rem 2.5rem',
          md: '7rem 2rem 2.5rem',
          lg: '7rem 4rem 2.5rem',
        },
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {/* {isEssencialEnabledLoading && <Loading />} */}
      <Flex
        sx={{
          gap: '4rem',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            gap: '1.5rem',
            flexDirection: 'column',
          }}
        >
          <Flex
            sx={{
              gap: '1rem',
              flexDirection: 'column',
            }}
          >
            {title && (
              <Typography
                content={title}
                variant="porto-title-3-bold"
                color="porto-primitive-black-85"
              />
            )}
            {subtitle && (
              <Typography
                content={subtitle}
                variant="porto-title-5-medium"
                color="porto-primitive-black-85"
              />
            )}
            {description && (
              <Typography
                content={description}
                variant="porto-text-body-1-regular"
                color="porto-primitive-black-85"
              />
            )}
          </Flex>
          {children &&
            children.map(({ Component, props }) => (
              <Component {...props} key={JSON.stringify(props)} />
            ))}
          {actions && (
            <Flex
              sx={{
                flexDirection: { xs: 'column', lg: 'row' },
                gap: '1rem',
              }}
            >
              {actions.map(({ color = 'default', label, url, icon, product }) => {
                if (product === 'capitalizacao' && !isCapitalizacaoEnabled) return null
                if (product === 'fianca' && !isEssencialEnabled) return null
                if (product === 'seguro-incendio' && !isImobiliariaEnabled) return null

                return (
                  <ButtonShortcut
                    key={`${label}_${url}`}
                    color={color}
                    icon={icon}
                    onClick={() => history.push(url)}
                  >
                    {label}
                  </ButtonShortcut>
                )
              })}
            </Flex>
          )}
        </Flex>
        {side && (
          <Flex
            sx={{
              display: { xs: 'none', lg: 'flex' },
              flexDirection: 'column',
              gap: '1.5rem',
              width: '100%',
              maxWidth: '296px',
            }}
          >
            {side.map(({ Component, props }) => (
              <Component {...props} key={JSON.stringify(props)} />
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
