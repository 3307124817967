import { Actions, Forms, Grid, Icons } from '@/components'

import Button from '@/components/Actions/Button'
import { AnimateVerticalShow } from '@/components/Utilities/Animations/AnimateVerticalShow'
import { SociosCapitalizacao } from '@/data/capitalizacao/forms/schemas/firstStepSchema'
import { api } from '@/services'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Card } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { parseToNumber } from 'brazilian-values'
import { FieldArrayRenderProps, useField } from 'formik'
import { useMutation } from 'react-query'
import * as S from './styles'

export const Socios: React.FC<
  {
    index: number
    entity: string
  } & FieldArrayRenderProps
> = ({ index, push, remove, form, name: arrayFieldName, ...props }) => {
  const [partnersField, partnersFieldMeta] = useField<SociosCapitalizacao[]>(arrayFieldName)
  const [documentField, documentMeta] = useField(`${arrayFieldName}[${index}].document`)
  const [, stakeMeta] = useField(`${arrayFieldName}[${index}].stake`)
  const [zipcodeField, zipcodeMeta] = useField(`${arrayFieldName}[${index}].address.zipcode`)

  const socioPosition = index + 1
  const showPositionsLabels = partnersField.value?.length > 1
  const lastPosition = partnersField.value?.length === socioPosition

  const fetchCustomerMutation = useMutation(async () => {
    if (!documentField.value || !!documentMeta.error) throw new Error('Documento inválido')

    const person = await api.customers.getByDocument(documentField.value)

    await Promise.all([
      form.setFieldValue(
        `${arrayFieldName}[${index}].name`,
        person.name || person.companyName || '',
      ),
    ])
  })

  const fetchAddressMutation = useMutation(async () => {
    if (!zipcodeField.value || !!zipcodeMeta.error) throw new Error('Endereço inválido')

    const address = await api.addresses.getByZipcode(zipcodeField.value)

    await Promise.all([
      form.setFieldValue(`${arrayFieldName}[${index}].address.state`, address?.state),
      form.setFieldValue(`${arrayFieldName}[${index}].address.city`, address?.city),
      form.setFieldValue(`${arrayFieldName}[${index}].address.neighborhood`, address?.neighborhood),
      form.setFieldValue(`${arrayFieldName}[${index}].address.street`, address?.street),
    ])

    return address
  })

  const disabledAdd =
    !!partnersFieldMeta.error ||
    partnersField.value.reduce((currentValue, socio) => {
      const numericValue = parseToNumber(socio?.stake?.toString()?.replace('%', '') || '0')

      return currentValue + numericValue
    }, 0) >= 100

  return (
    <Grid gap="16px">
      <Grid columns={['1fr', 'repeat(2, max-content)']} gap="16px">
        <Typography variant="porto-title-6-medium">
          Sócio {props.entity} {showPositionsLabels && socioPosition}
        </Typography>

        {partnersField.value?.length > 1 && (
          <Actions.Link
            as="button"
            type="button"
            variant="danger"
            kind="ghost"
            onClick={() => remove(index)}
            data-test-id="partner-remove"
          >
            Remover sócio
          </Actions.Link>
        )}
      </Grid>

      <Grid gap="16px" columns={['1fr', '1fr', '1fr 2fr']}>
        <Forms.InputGroup
          label="CPF/CNPJ"
          name={`${arrayFieldName}[${index}].document`}
          mask="document"
          placeholder="000.000.000-00"
          onBlur={() => fetchCustomerMutation.mutate()}
          loading={fetchCustomerMutation.isLoading}
          readOnly={fetchCustomerMutation.isLoading}
          data-test-id="partner-document"
        />
      </Grid>
      <Grid gap="16px" columns={['1fr', '1fr', '2fr 1fr']}>
        <Forms.InputGroup
          label="Nome completo"
          name={`${arrayFieldName}[${index}].name`}
          placeholder="Digite"
          data-test-id="partner-name"
        />
      </Grid>
      <Grid gap="16px" columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
        <Forms.SelectGroup
          resetValueOnUnmount={false}
          label="Papel de sociedade"
          name={`${arrayFieldName}[${index}].role`}
          options={[
            { label: 'Selecione', value: '' },
            { label: 'Administrador', value: 39 },
            { label: 'Controlador', value: 30 },
            { label: 'Procurador', value: 24 },
          ]}
          data-test-id="partner-role-society"
        />
        <Forms.InputGroup
          label="Participação (%)"
          name={`${arrayFieldName}[${index}].stake`}
          mask="percentage"
          min="0"
          max="100"
          placeholder="0,01%"
          data-test-id="partner-participation"
        />
      </Grid>

      <Typography variant="porto-title-6-medium">Endereço do sócio {props.entity}</Typography>
      <Grid gap="16px" columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
        <Forms.SelectGroup
          resetValueOnUnmount={false}
          label="Tipo de imóvel"
          name={`${arrayFieldName}[${index}].address.type`}
          options={[
            { label: 'Selecione', value: '' },
            { label: 'Residencial', value: 'RESIDENCIAL' },
            { label: 'Comercial', value: 'COMERCIAL' },
          ]}
          data-test-id="partner-tipo-property"
        />
        <Forms.InputGroup
          label="CEP"
          name={`${arrayFieldName}[${index}].address.zipcode`}
          placeholder="00000-000"
          mask="zipcode"
          onBlur={() => fetchAddressMutation.mutate()}
          loading={fetchAddressMutation.isLoading}
          readOnly={fetchAddressMutation.isLoading}
          data-test-id="partner-cep"
        />
      </Grid>

      <AnimateVerticalShow
        isOpen={!fetchAddressMutation.isLoading && fetchAddressMutation.isSuccess}
      >
        <Grid gap="16px">
          <Grid gap="16px" columns={['1fr', '1fr', 'repeat(2, 1fr)']}>
            <Forms.InputGroup
              label="Endereço"
              name={`${arrayFieldName}[${index}].address.street`}
              placeholder="Digite"
              readOnly={fetchAddressMutation.isSuccess && fetchAddressMutation.data.street}
              data-test-id="partner-address-full"
            />
            <Grid gap="16px" columns="1fr 2fr">
              <Forms.InputGroup
                label="Número"
                name={`${arrayFieldName}[${index}].address.number`}
                placeholder="Digite"
                data-test-id="partner-address-number"
              />
              <Forms.InputGroup
                label="Complemento"
                name={`${arrayFieldName}[${index}].address.complement`}
                placeholder="Digite"
                data-test-id="partner-address-complement"
              />
            </Grid>
          </Grid>
          <Grid gap="16px" columns={['1fr', '1fr', 'repeat(3, 1fr)']}>
            <Forms.InputGroup
              label="Bairro"
              name={`${arrayFieldName}[${index}].address.neighborhood`}
              placeholder="Digite"
              readOnly={!!fetchAddressMutation.isSuccess && fetchAddressMutation.data.neighborhood}
              data-test-id="partner-address-neighborhood"
            />
            <Forms.InputGroup
              label="Cidade"
              name={`${arrayFieldName}[${index}].address.city`}
              readOnly={!!fetchAddressMutation.isSuccess && fetchAddressMutation.data.city}
              data-test-id="partner-address-city"
            />
            <Forms.InputGroup
              label="Estado"
              name={`${arrayFieldName}[${index}].address.state`}
              readOnly={!!fetchAddressMutation.isSuccess && fetchAddressMutation.data.state}
              data-test-id="partner-address-state"
            />
          </Grid>
        </Grid>
      </AnimateVerticalShow>

      {!!stakeMeta.touched && typeof form.errors.stake === 'string' && lastPosition && (
        <Card
          sx={{
            background: 'var(--system-surface-warning)',
            border: 0,
          }}
        >
          <Grid columns={'max-content 1fr'} gap="8px">
            <Icons.FeatherIcons color="var(--porto-primitive-yellow-100)" name="info" />
            <Typography color="var(--porto-primitive-yellow-100)" variant="">
              {form.errors.stake}
            </Typography>
          </Grid>
        </Card>
      )}

      <Grid columns={['1fr', 'max-content']}>
        {lastPosition && (
          <Button
            data-test-id="partner-new"
            isDisabled={disabledAdd}
            kind="ghost"
            type="button"
            onClick={() => push('')}
          >
            Adicionar sócio {props.entity}
          </Button>
        )}
      </Grid>

      {!lastPosition && <S.Divisor />}
    </Grid>
  )
}
