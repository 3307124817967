import { useCurrentOrganization } from '@/modules'
import { useEnums, usePlans } from '@/tools/hooks/capitalizacao'
import { useCountries } from '@/tools/hooks/capitalizacao/useCountries'
import { useIsCapitalizacaoEnabled } from '@/tools/partnership'
import { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { Error, Loading } from '../components'
import { BudgetsScreen } from './Budget'
import { Products } from '../../shared/entities'

export const Budgets = () => {
  const profile = useCurrentOrganization()
  const enums = useEnums()
  const plans = usePlans()
  const countries = useCountries()
  const isCapEnable = useIsCapitalizacaoEnabled(profile)

  const isLoading = useMemo(() => {
    return enums.isLoading || plans.isLoading || countries.isLoading || isCapEnable.isLoading
  }, [countries.isLoading, enums.isLoading, isCapEnable.isLoading, plans.isLoading])

  const isError = useMemo(() => {
    return enums.isError || plans.isError || countries.isError
  }, [countries.isError, enums.isError, plans.isError])

  const errorMessage = useMemo(() => {
    return enums?.error?.message || plans?.error?.message || countries?.error?.message
  }, [countries?.error?.message, enums?.error?.message, plans?.error?.message])

  return (
    <>
      {!isCapEnable.result && !isCapEnable.isLoading && (
        <Redirect to={`/?nao-habilitado=${Products.CAPITALIZACAO}`} />
      )}
      {isError && <Error error={errorMessage} />}

      {!isError && (
        <Loading isLoading={isLoading}>
          <BudgetsScreen enums={enums.data} plans={plans.data} />
        </Loading>
      )}
    </>
  )
}
