import { motion } from 'framer-motion'
import { rem, size } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import themes from '@/styles/themes'

import { FeatherIcons } from '../../Icons'
import Base from '../Base'
import { IConfirmationProps } from './interfaces'

export const Dialog = styled(motion.div)`
  max-width: ${rem('540px')};
  min-width: ${rem('350px')};
  overflow-y: hidden;

  background-color: #fff;
  border-radius: 20px 20px 0 0;

  ${media.greaterThan('large')`
    border-radius: 12px;
  `}
`

export const Head = styled.div`
  padding: ${rem('24px')} ${rem('24px')};

  text-align: center;

  ${media.greaterThan('huge')`
    padding: ${rem('32px')} ${rem('32px')} ${rem('28px')} ${rem('32px')};
  `}
`

export const Icon = styled(FeatherIcons)`
  ${size('32px')}

  stroke-width: 1.5px;
  margin-bottom: 16px;

  ${media.greaterThan('huge')`
    ${size('48px')}
  `}
`

export const Title = styled.p`
  font-weight: bold;
  font-size: ${rem('20px')};
  color: ${themes.text.higher};
  font-family: ${themes.fonts.title};

  ${media.greaterThan('huge')`
    font-size: ${rem('18px')};
  `}
`

export const Body = styled.div`
  padding: ${rem('32px')} ${rem('32px')};

  background-color: ${themes.page.colors.content};
  border-top: 1px solid ${themes.utilities.border};

  ${media.greaterThan('large')`
    border-radius: 0 0 12px 12px;
  `}
`

export const Content = styled.div`
  line-height: 180%;
  font-size: ${rem('14px')};
  color: ${themes.text.high};

  ${media.greaterThan('huge')`
    font-size: ${rem('13px')};
  `}

  p {
    margin-top: ${rem('8px')};
  }
`

export const Footer = styled.div`
  padding-top: ${rem('52px')};

  display: flex;

  button {
    border: none;
  }
`

export const Container = styled(Base)<Pick<IConfirmationProps, 'kind'>>`
  div: {
    overflow-y: hidden !important;
  }
  ${props =>
    props.kind &&
    {
      success: css`
        ${Icon},
        ${Title} {
          color: ${themes.palletes.success};
        }
      `,

      warning: css`
        ${Icon},
        ${Title} {
          color: ${themes.palletes.warning};
        }
      `,

      danger: css`
        ${Icon},
        ${Title} {
          color: ${themes.palletes.terciary};
        }
      `,
    }[props.kind]}
`
