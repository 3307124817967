import { useField } from 'formik'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import { useDebounce, useOutsideClick } from '@/tools'

import Input from '../Input'
import Label from '../Label'
import Message from '../Message'

import { Container, Dropdown, Field, Loader } from './styles'
import { AutoCompleteProps, Suggestion, Suggestions } from './types'

const variants = {
  hidden: { height: 0, opacity: 0, transition: { ease: [0.7, 0, 0.84, 0] } },
  visible: {
    height: 'auto',
    'max-height': '40vh',
    opacity: 1,
    transition: { ease: [0.7, 0, 0.84, 0] },
  },
}

const Autocomplete = ({
  name,
  label,
  placeholder,
  emptyMessage,
  initialValue = '',
  showRequired,
  onSearch,
  onSelect,
  options,
}: AutoCompleteProps) => {
  const [field, meta, helpers] = useField(name)

  const ref = useRef()

  const [loading, setLoading] = useState<boolean>(false)

  const [sentence, setSentence] = useState<string>(initialValue)
  const debouncedValue = useDebounce(sentence)

  const [suggestions, setSuggestions] = useState<Suggestions>([])
  const [visible, setVisible] = useState<boolean>(false)

  const [selecting, setSelecting] = useState<boolean>(null)

  const handleSentence = event => {
    const { value } = event.target

    setSentence(value)

    if (!value) {
      return helpers.setValue('')
    }

    setLoading(true)
    setVisible(false)
    setSelecting(false)
  }

  useEffect(() => {
    if (field.value) {
      const selected = options.find(e => e.value === field.value)
      handleSelected(null, selected || field.value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelected = (event, selected: Suggestion) => {
    event?.stopPropagation()

    setVisible(false)
    setSentence(selected.label)
    setSuggestions([])

    setSelecting(true)

    helpers.setValue(selected.value, true)

    onSelect && onSelect(selected)
  }

  const handleAsync = async (value: string) => {
    try {
      const response = await onSearch(value)
      setSuggestions(response)
      setVisible(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!selecting && sentence && debouncedValue && initialValue !== sentence)
      handleAsync(debouncedValue)
  }, [sentence, debouncedValue])

  useOutsideClick(ref, () => {
    setVisible(false)
    setSuggestions([])
  })

  return (
    <Container visible={visible} focused={visible} ref={ref}>
      <Field>
        <Label showRequired={showRequired}>{label || 'Buscar'}</Label>

        <Input
          {...field}
          name={field.name}
          autoComplete="off"
          onChange={handleSentence}
          value={sentence}
          placeholder={placeholder || 'Digite para buscar'}
          error={(meta.touched && !!meta.error) || undefined}
        />

        {loading && <Loader />}
      </Field>

      <AnimatePresence>
        {meta.touched && !!meta.error && <Message kind="danger">{meta.error}</Message>}
      </AnimatePresence>

      <AnimatePresence>
        {visible && (
          <Dropdown.Wrapper exit="hidden" initial="hidden" animate="visible" variants={variants}>
            {suggestions?.length > 0 ? (
              suggestions.map(suggestion => (
                <Dropdown.Item
                  key={suggestion.value}
                  onClick={event => handleSelected(event, suggestion)}
                >
                  {suggestion.label} <Dropdown.Icon />
                </Dropdown.Item>
              ))
            ) : (
              <Dropdown.Empty>{emptyMessage || 'Nenhum item encontrado'}</Dropdown.Empty>
            )}
          </Dropdown.Wrapper>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default Autocomplete
