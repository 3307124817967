import { toast } from '@/components'
import { contractStatus } from '@/data/capitalizacao'
import { api } from '@/services'
import { Button } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'

const SUPPORT_URL = 'https://atendimento.solucoesparalocacao.com.br/hc/pt-br/requests/new'

const DOWNLOAD_ENUMS = {
  PROPOSAL: {
    success: 'A proposta está sendo gerada e será baixada em seguida.',
    error: 'Não foi possível gerar a proposta.',
  },
  BOLETO: {
    success: 'O boleto está sendo gerado e será baixado em seguida.',
    error: 'Não foi possível gerar o boleto.',
  },
  PAYMENT_LINK: {
    success: 'Link copiado para a área de transferência.',
    error: 'Não foi possível gerar o link.',
  },
}

const handleDownload = async ({ type, setIsLoading, id }) => {
  try {
    setIsLoading(true)
    toast(DOWNLOAD_ENUMS[type].success)
    await api.contracts.print(id, type)
  } catch (e) {
    console.log(e)
    toast(DOWNLOAD_ENUMS[type].error, { type: 'error' })
  } finally {
    setIsLoading(false)
  }
}

const handleDownloadWelcomeKit = async ({ setIsLoading, proposal }) => {
  try {
    setIsLoading(true)
    toast('Fazendo download do welcome kit.')
    await api.contracts.getWelcomeKit(proposal)
  } catch (e) {
    toast('Não foi possível fazer o download do welcome kit.', { type: 'error' })
  } finally {
    setIsLoading(false)
  }
}

const handlePaymentLink = paymentLink => {
  navigator.clipboard.writeText(paymentLink)
  return toast(DOWNLOAD_ENUMS.PAYMENT_LINK.success)
}

const handleOpenUrl = url => {
  window.open(url, '_blank')
}

const resendContract = async contract => {
  try {
    const response = await api.instance.v2.post(
      `/contracts/${contract.id}/orders`,
      contract.payment,
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const handleButtons = ({ contract, history, isLoading, setIsLoading }) => ({
  [contractStatus.AWAITING_PAYMENT_LINK]: (
    <>
      <Button
        icon="copy"
        onClick={() => handlePaymentLink(contract.metadata.payment_link)}
        data-test-id="copy-link"
      >
        Copiar link
      </Button>
      <Button
        kind="ghost"
        onClick={() => handleOpenUrl(`https://wa.me/${contract.payload.customer.phone}`)}
        data-test-id="open-whatsapp"
      >
        Abrir WhatsApp
      </Button>
    </>
  ),
  [contractStatus.AWAITING_PAYMENT]: (
    <>
      <Button
        data-test-id="download-ticket"
        icon="download"
        onClick={() => handleDownload({ type: 'BOLETO', setIsLoading, id: contract.id })}
        isLoading={isLoading}
      >
        Baixar boleto
      </Button>
      <Button
        data-test-id="open-whatsapp"
        kind="ghost"
        onClick={() => handleOpenUrl(`https://wa.me/${contract.payload.customer.phone}`)}
      >
        Abrir WhatsApp
      </Button>
    </>
  ),
  [contractStatus.ISSUED]: (
    <>
      <Button
        data-test-id="download-proposal"
        icon="download"
        kind="ghost"
        onClick={() => handleDownload({ type: 'PROPOSAL', setIsLoading, id: contract.id })}
      >
        Baixar Proposta
      </Button>
      <Button
        data-test-id="open-whatsapp"
        kind="ghost"
        onClick={() => handleOpenUrl(`https://wa.me/${contract.payload.customer.phone}`)}
      >
        Abrir WhatsApp
      </Button>
      <Button
        data-test-id="view-welcome-kit"
        icon="download"
        kind="ghost"
        onClick={() =>
          handleDownloadWelcomeKit({ setIsLoading, proposal: contract.policy?.proposal })
        }
      >
        Visualização/impressão Welcome Kit
      </Button>
    </>
  ),
  [contractStatus.INTEGRATION_FAILURE]: (
    <>
      <Button
        icon="refresh"
        kind="ghost"
        onClick={() => resendContract(contract)}
        data-test-id="try-again"
      >
        Tentar novamente
      </Button>
      {/* <Button
        kind="ghost"
        onClick={() => handleOpenUrl(SUPPORT_URL)}
      >
        Suporte
      </Button> */}
    </>
  ),
  [contractStatus.REGISTERING_ERROR]: (
    <>
      <Button
        onClick={() => history.push('/contratos/novo/capitalizacao')}
        data-test-id="new-proposal"
      >
        Nova proposta
      </Button>
      <Button kind="ghost" onClick={() => handleOpenUrl(SUPPORT_URL)} data-test-id="support">
        Suporte
      </Button>
    </>
  ),
  [contractStatus.CANCELLED]: (
    <>
      <Button
        onClick={() => history.push('/contratos/novo/capitalizacao')}
        data-test-id="new-proposal"
      >
        Nova proposta
      </Button>
    </>
  ),
})
