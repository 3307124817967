import ThemeProvider from '@pol-npm/riscos-financeiros-dashboard-ui/dist/styles/Provider'
import { StrictMode, useLayoutEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'

import 'design-system-react/src/assets/icons/style.css'
import 'design-system-react/src/styles/keen-slider.min.css'
import 'design-system-react/src/styles/porto-fonts.css'

import '@pol-npm/riscos-financeiros-dashboard-icons/src/style.css'
import '@pol-npm/riscos-financeiros-porto-icons/src/style.css'

import { ThemeProvider as Theme } from '@/styles'

import { useCurrentOrganization, useCurrentUser } from './modules'
import Router from './router'
import { queryClient } from './services/react-query/query-client'
import { store } from './store'
import { encrypt, useGTM } from './tools'

const App = () => {
  const { setCustomData } = useGTM()
  const { store: user } = useCurrentUser()
  const profile = useCurrentOrganization()

  useLayoutEffect(() => {
    // ? Configurações do glassbox. Manteremos no default.
    // if (typeof window._cls_config == 'object') {
    //   window._cls_config.clientAttributesEnabled = true
    //   window._cls_config.webVitalsRecordEnabled = true
    // }

    setCustomData({
      user: {
        logado: !!user.email,
        user_id: !!user.email ? encrypt(profile?.document) : undefined,
      },
      page: {
        product: 'aluguel-fianca',
      },
      site: {
        brand: 'portoseguro',
      },
    })

    if (profile.id) {
      setCustomData({
        user: {
          perfil: profile.type === 'BROKER' ? 'corretor' : 'parceiro',
          ...{
            BROKER: {
              susep: profile.metadata.susep?.porto[0],
              parceiro_id: undefined,
            },
            REAL_ESTATE: {
              parceiro_id: profile.id,
              susep: undefined,
            },
          }[profile.type],
        },
      })
    } else {
      setCustomData({
        user: {
          perfil: undefined,
          parceiro_id: undefined,
          susep: undefined,
        },
      })
    }
  }, [profile, setCustomData, user])

  return (
    <Theme>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Router />
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </ThemeProvider>
    </Theme>
  )
}

// Se houver script da Glassbox. Implementa o script.
if (Boolean(process.env.REACT_APP_GLASSBOX_URL)) {
  const scriptElement = document.createElement('script')
  scriptElement.setAttribute('id', '_cls_detector')
  scriptElement.setAttribute('src', process.env.REACT_APP_GLASSBOX_URL)

  document.head.insertAdjacentElement('beforeend', scriptElement)
}

if (+process.env.REACT_APP_ENABLE_SSC) {
  const cspMetadata = document.createElement('meta')
  cspMetadata.setAttribute('http-equiv', 'Content-Security-Policy')
  cspMetadata.setAttribute(
    'content',
    "default-src https: 'self' 'unsafe-inline'; script-src blob: https: 'self' 'unsafe-inline' 'unsafe-eval'; object-src; img-src data: *",
  )
  document.head.insertAdjacentElement('afterbegin', cspMetadata)
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider {...{ store }}>
    <StrictMode>
      <App />
    </StrictMode>
  </Provider>,
)
