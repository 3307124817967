import { useContract } from '@/modules'
import { Products } from '@/screens/Contracts/Products/shared/entities'
import { formatToCPFOrCNPJ, formatToPhone, isCPF } from 'brazilian-values'
import dayjs from 'dayjs'
import { TextContentGroup } from '../TextContentGroup'

export const Tenant = ({ pepEnum }) => {
  const contract = useContract<Products.CAPITALIZACAO>()

  return (
    <TextContentGroup
      title="Dados do inquilino"
      values={[
        [
          {
            title: isCPF(contract.payload.customer.document) ? 'CPF' : 'CNPJ',
            description: formatToCPFOrCNPJ(contract.payload.customer.document),
          },
          {
            title: isCPF(contract.payload.customer.document) ? 'Nome completo' : 'Razão social',
            description: contract.payload.customer.name,
          },
          contract.payload.customer.socialName && {
            title: 'Nome social',
            description: contract.payload.customer.socialName,
          },
          { title: 'E-mail', description: contract.payload.customer.email },
        ],
        [
          {
            title: 'Telefone/celular',
            description: formatToPhone(contract.payload.customer.phone) || '-',
          },
        ],
      ]}
    />
  )
}
