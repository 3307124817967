export const CIVIL_STATUS_OPTIONS = [
  { label: 'Casado(a)', value: 2 },
  { label: 'Solteiro(a)', value: 1 },
  { label: 'Viúvo(a)', value: 3 },
  { label: 'Divorciado(a)', value: 5 },
  { label: 'Separado(a) Judicialmente', value: 4 },
]
export const INCOME_OPTIONS = [
  { label: 'Até R$ 2.500,00', value: 2 },
  { label: 'De R$2.500,01 a R$5.000,00', value: 3 },
  { label: 'De R$5.000,01 a R$10.000,00', value: 4 },
  { label: 'Acima de R$10.000,01', value: 5 },
]
export const PEP_OPTIONS = [
  { label: 'Sim', value: 1 },
  { label: 'Não e não tem relacionamento próximo com PEP', value: 2 },
  { label: 'Não, mas possui relacionamento próximo com PEP', value: 3 },
]
export const DOCUMENT_TYPE_OPTIONS = [
  { label: 'RG', value: 1 },
  { label: 'RNE', value: 5 },
  { label: 'Classe', value: 40 },
  { label: 'CNH', value: 2 },
  { label: 'Passaporte', value: 39 },
]
export const NATIONALITY_OPTIONS = [
  { label: 'Brasileira', value: 1 },
  { label: 'Estrangeira', value: 2 },
]
export const BRAZILIAN_RESIDENT_OPTIONS = [
  { label: 'Sim', value: 1 },
  { label: 'Não', value: 2 },
]
export const RELATIONSHIP_OPTIONS = [
  { label: 'Pai ou Mãe', value: 1 },
  { label: 'Cônjuge', value: 2 },
  { label: 'Companheiro(a)', value: 3 },
  { label: 'Filho(a)', value: 4 },
  { label: 'Enteado(a)', value: 5 },
  { label: 'Outros', value: 6 },
]
export const ACTIVITIESPJ_OPTIONS = [
  { label: 'ATIVIDADE ESPECIAL', value: 1 },
  { label: 'ABRASIVOS FABRICAS - COM OU SEM INFLAM', value: 2 },
  { label: 'ACADEMIA DE GINASTICA/DANCAS/LUTAS/ESCOLA DE NATACAO OU ESPORTES', value: 3 },
  { label: 'ACOUGUE/PEIXARIA', value: 4 },
  { label: 'ACUCAR - DEPOSITOS', value: 5 },
  { label: 'AG. BANCÁRIAS/LOJAS OU COOP. DE CRÉDITO/CASAS DE CÂMBIO', value: 6 },
  { label: 'AG. TURISMO (EXCLUINDO CÂMBIO DE VALORES)-TERREO OU SOBR', value: 7 },
  { label: 'AG. TURISMO OU VIAGENS(EXCLUINDO CAMBIO DE VALORES)PRIMEIRO ANDAR', value: 8 },
  { label: 'BEBIDAS NAO ALCOOL - DEPOSITO OU LOJA', value: 9 },
  { label: 'BEBIDAS NAO ALCOOL - FABRICAS/ENGARRAFAM', value: 10 },
  { label: 'ANUNCIOS LUMINOSOS,LETREIROS OU PAINEIS - FABRICA E/OU OFICINAS', value: 11 },
  { label: 'ANTIGUIDADES, LOJAS DE', value: 12 },
  { label: 'APARELHOS ORTOPEDICOS - DEPOSITO OU LOJ', value: 13 },
  { label: 'ARMARINHOS - DEPOSITOS SEM VENDAS', value: 14 },
  { label: 'ARMARINHOS - LOJAS', value: 15 },
  { label: 'ARTIGOS P/PRESENTES/ARMARINHOS/ARTIGOS D', value: 16 },
  { label: 'ARMAS ( LOJAS OU DEPOSITOS )', value: 17 },
  { label: 'ARMAS (FABRICAS)', value: 18 },
  { label: 'ARROZ-DEPOSITO C/ BENEF S/ PROCESSO DE D', value: 19 },
  { label: 'ARTIGOS RELIGIOSOS (EXCLUIDO MATERIAIS EXPLOSIVOS)', value: 20 },
  { label: 'ASILOS/CASA DE REPOUSO/CLÍNICAS PARA TRA', value: 21 },
  { label: 'AUTOMÓVEIS- EXCLUISIVAMENTE LOCAÇÃO', value: 22 },
  { label: 'AUTOMOVEIS (EXCLUSIVAMENTE CONCESSIONARI', value: 23 },
  { label: 'AUTOMOVEIS-LOJA/DEPOSITO (EXCLUIDO MOTOS', value: 24 },
  { label: 'AUTOMOVEIS,OFICINA.MEC,FUNILARIA,ELETRIC', value: 25 },
  { label: 'AUTOMOVEIS -COMPONENTES OU ACESSORIOS,LO', value: 26 },
  { label: 'AVICOLAS - LOJA (EXCLUINDO ABATEDOURO)', value: 27 },
  { label: 'AVICULTURA - DEPOSITOS OU LOJAS', value: 28 },
  { label: 'BANHA (DEPOSITOS E LOJAS)', value: 29 },
  { label: 'BAR, BOTEQUIM, LANCHONETE E PASTELARIA', value: 30 },
  { label: 'SALÃO DE BUFFET', value: 31 },
  { label: 'BEBIDAS - DEPOSITOS SEM VENDA', value: 32 },
  { label: 'BEBIDAS - DEPOSITOS COM VENDA (DISTRIBUI', value: 33 },
  { label: 'BEBIDAS -LOJAS/DEPÓSITOS', value: 34 },
  { label: 'BEBIDAS ALCOOLICAS - FABRICAS', value: 35 },
  { label: 'BICICLETAS - DEPSSITOS/LOJA PERMITINDO O', value: 36 },
  { label: 'BIJOUTERIAS-FÁBRICAS-EXCLUINDO METAIS,ARTEFATOS,JÓIAS,PEDRAS(PREC/SEMI', value: 37 },
  { label: 'BIJOUTERIAS-LOJA/DEP-EXCLUINDO METAIS,ARTEFATOS (S/PREC/SEMIPRECIOSOS)', value: 38 },
  { label: 'BILHARES OU BOLICHES, SALOES', value: 39 },
  { label: 'BOLSAS,CINTOS,MALAS OU SIMILARES-LOJA/DEPOSITO', value: 40 },
  { label: 'BOLSAS, CINTOS, MALAS OU CONGENERES - DE', value: 41 },
  { label: 'BOLSAS,CINTOS,MALAS OU SIMILARES-FÁBRICA', value: 42 },
  { label: 'BORRACHEIRO (SEM RECAUCHUTAGEM)', value: 43 },
  { label: 'BORRACHA - LOJA OU DEPOSITO DE ARTIGOS', value: 44 },
  { label: 'BORRACHA - FABRICA DE ARTIGOS', value: 45 },
  { label: 'BOTOES - FABRICA (EXCLUIDO DE MADEIRA)', value: 46 },
  { label: 'BRINQUEDOS - FABRICA DE (SEM EMPREGO DE', value: 47 },
  { label: 'BRINQUEDOS - LOJAS OU DEPOSITOS', value: 48 },
  { label: 'CABELEIREIROS, SALAO DE BELEZA E/OU ESTETICA', value: 49 },
  { label: 'CAFE - DEPOSITO S/VENDA (INCLUSIVE BENEF', value: 50 },
  { label: 'CAFE - DEPOSITO C/VENDA (INCLUSIVE BENEF', value: 51 },
  { label: 'CALCADOS - FABRICA', value: 52 },
  { label: 'CALCADOS - DEPOSITOS OU LOJAS', value: 53 },
  { label: 'CALCADOS (SAPATEIRO) - OFICINA', value: 54 },
  { label: 'CARIMBOS/CHAVEIROS -DEPÓSITOS OU LOJAS', value: 55 },
  { label: 'CARIMBOS - FABRICAS OU OFICINAS', value: 56 },
  { label: 'CARTORIOS', value: 57 },
  { label: 'CASAS DE BANHO (SAUNAS)', value: 58 },
  { label: 'HOTÉIS/MOTÉIS/POUSADAS)', value: 59 },
  { label: 'CHAVEIRO', value: 60 },
  { label: 'CEMITERIOS/VELORIOS/CREMATORIOS', value: 61 },
  { label: 'CIMENTO - DEPOSITO OU LOJAS DE ARTIGOS D', value: 62 },
  { label: 'CIMENTO/GESSO-FÁBRICA DE ARTIGOS', value: 63 },
  { label: 'CIMENTO - DEPOSITO OU LOJA DE CIMENTO', value: 64 },
  { label: 'CIRURG,HOSPIT,DENT,ORTOP,ART(EXCETO MEDICAMENTO/EQP.ELETR) DEP/LOJA', value: 65 },
  { label: 'CIRURGICOS, HOSPITALARES OU DENTARIOS -', value: 66 },
  { label: 'COLCHOES - DEPOSITOS OU LOJAS SEM OFICIN', value: 67 },
  { label: 'CLUBE RECREATIVO', value: 68 },
  { label: 'CONFEITARIA', value: 69 },
  { label: 'POLAS (ENVASAMENTO E EMBALAGEM)', value: 70 },
  { label: 'COLCHOES, FABRICA DE', value: 71 },
  { label: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL - DEPOSITOS OU LOJAS', value: 72 },
  { label: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL - FABRICAS', value: 73 },
  { label: 'CONSULTORIO/CLINICA MEDICA OU DENTARIA (', value: 74 },
  { label: 'CONSULTORIO/CLINICA MEDICA OU DENTARIA (A PARTIR DO 1º ANDAR)', value: 75 },
  { label: 'CONVENTOS/MOSTEIROS', value: 76 },
  { label: 'COPIADORA PERMITINDO-SE ENCADERNACAO, PLASTIFICACAO E OUTROS INERENTES', value: 77 },
  { label: 'CORDOARIA (CORDAS, CORDOES) - DEPOSITO', value: 78 },
  { label: 'CORDOARIA (CORDAS, CORDOES) - FABRICAS', value: 79 },
  { label: 'CORREIOS/ ENCOMENDAS EXPRESSAS (EXCLUIND', value: 80 },
  { label: 'COUROS OU PELES - DEPOSITO OU LOJAS DE A', value: 81 },
  { label: 'COUROS OU PELES - FABRICA DE ARTIGOS SEM', value: 82 },
  { label: 'DEFENSIVOS AGRICOLAS-LOJA/DEPOSITO(EXCLUI SEMENTES)', value: 83 },
  { label: 'DESOCUPADO - IMOVEL COMERCIAL/INDUSTRIAL', value: 84 },
  { label: 'DESPACHANTES (ESCRITORIO)', value: 85 },
  { label: 'DESENTUPIDORA', value: 86 },
  { label: 'CDS, DVDS E SIMILARES LOJAS', value: 87 },
  { label: 'DIVERSOES ELETRONICAS', value: 88 },
  { label: 'DOCES, BALAS, CHOCOLATES E SIMILARES-FÁBRICA', value: 89 },
  { label: 'DOCES,BALAS,CHOCOLATES E SIMILARES-LOJAS/DEPOSITOS', value: 90 },
  { label: 'ELETRICOS/ILUMINAÇÃO OU ELETRICIDADE -DEPÓSITO/LOJAS DE ARTIGOS', value: 91 },
  { label: 'ELETRODOMESTICOS, LOJAS / DEPOSITOS', value: 92 },
  { label: 'ELETRODOMESTICOS - (OFICINA DE CONSERTO)', value: 93 },
  { label: 'ELETROELET-LOJA/DEP.C/OFICINA', value: 94 },
  { label: 'ELETROELETRONICOS - FABRICAS DE PECAS E', value: 95 },
  { label: 'EMBARCACOES (LOJA / DEPOSITO - SEM VENDA DE MOTORES DE POPA)', value: 96 },
  { label: 'EMPRESAS DE ONIBUS E/OU TAXIS', value: 97 },
  { label: 'EQUIP. PROTEÇÃO INDIVIDUAL (EPI)-LOJAS/DEPÓSITO', value: 98 },
  { label: 'ENCOMENDAS EXPRESSAS - TERREO', value: 99 },
  { label: 'ENCOMENDAS EXPRESSAS (A PARTIR 1.ANDAR)', value: 100 },
  { label: 'ESCOLAS PUBLICAS(ESTADUAIS, MUNICIPAIS,', value: 101 },
  { label: 'ESCOLAS PARTICULARES E/OU FILANTROPICAS', value: 102 },
  { label: 'ESCOLAS PARTICULARES E/OU FILANTROPICAS-', value: 103 },
  { label: 'ESCRITORIOS DEMAIS - TERREO/SOBRADOS(EXC', value: 104 },
  { label: 'ESCRITORIOS DEMAIS-A PARTIR PRIMEIRO AND', value: 105 },
  { label: 'ESCRITORIOS DE SERV INFORMATICA OU PUBLIC - TERREO OU SOBRADO', value: 106 },
  { label: 'ESCRITORIOS DE SERV INFOR OU PUBLIC -A P', value: 107 },
  { label: 'ESTACIONAMENTO E/OU LAVA RAPIDO, SEM OFI', value: 108 },
  { label: 'ESPORTE, ARTIGOS DE - LOJA/DEPÓSITO (EXCLUÍNDO ARMAS DE FOGO)', value: 109 },
  { label: 'ESTACAO RETRANSMISSORA DE RADIO OU TELEVISAO / TORRE DE RETRANSMISSAO', value: 110 },
  { label: 'EXTINTORES (LOJA/DEPOSITO,PERMITINDO OFI', value: 111 },
  { label: 'ESTUDIO FOTOGRÁFICO E/OU DE GRAVAÇÃO SOM E IMAGEM', value: 112 },
  { label: 'FARINHAS, DEPOSITOS OU LOJAS', value: 113 },
  { label: 'EXPOSIÇÕES/CONVÊNCÕES -CENTRO DE (EXCLUINDO BENS DE TERCEIROS)', value: 114 },
  { label: 'FARMACIAS OU DROGARIAS (DEPOSITOS)', value: 115 },
  { label: 'FARMACIAS OU DROGARIAS (LOJAS)', value: 116 },
  { label: 'FARMACEUTICOS, PRODUTOS - FÁBRICA', value: 117 },
  { label: 'FERRAGENS OU FERRAMENTAS-SEM VENDA DE MATERIAL DE CONST (LOJA/DEPÓSITO', value: 118 },
  { label: 'FLORES/PLANTAS NATURAIS -LOJAS (EXCLUINDO VIVEIRO/ESTUFA)', value: 119 },
  { label: 'FLORES ARTIFICIAIS, FABRICA OU LOJAS', value: 120 },
  { label: 'ESTUDIO FOTOGRAFICO, FOTOGRAFO, LABORATO', value: 121 },
  { label: 'FOTOGRAFICO, DEPOSITO OU LOJA DE ARTIGO', value: 122 },
  { label: 'FRIGORIFICO (MERC. EM CAMARAS DE REFRIG.', value: 123 },
  { label: 'EMBUTIDOS (SEM MATADOURO) -FÁBRICA', value: 124 },
  { label: 'FRUTAS, DEPOSITOS OU LOJAS', value: 125 },
  { label: 'FUMOS,CHARUTOS/CIGARROS (LOJAS PERMITINDO-SE ART.P/FUMANTES)', value: 126 },
  { label: 'FUNEBRES (ARTIGOS) S/OFICINA/ESTOFAMENTO', value: 127 },
  { label: 'FUNEBRES (LOJAS C/OFIC. DE ARMADORES, S/', value: 128 },
  { label: 'GELO -FAB DEP OU LOJAS', value: 129 },
  { label: 'GRAFICAS OU TIPOGRAFIAS (COM OU SEM ROTO', value: 130 },
  { label: 'GUARDA-CHUVAS, GUARDA-SOIS OU BENGALAS -', value: 131 },
  { label: 'GUARDA-CHUVAS,GUARDA-SOIS OU BENGALAS -', value: 132 },
  { label: 'HOSPITAIS', value: 133 },
  { label: 'IGREJAS/MOSTEIROS/CONVENTOS', value: 134 },
  { label: 'IMOB., CORRET. E ADM. DE IMOVEIS - TERRE', value: 135 },
  { label: 'INSTRUMENTOS MUSICAIS-LOJA/DEPOSITO', value: 136 },
  { label: 'INFORMÁTICA/ELETROELETRONICO-FABRICA OU MONTAGEM DE PEÇAS/EQUIP', value: 137 },
  { label: 'INFORMATICA - PROVEDORES DE INTERNET', value: 138 },
  { label: 'JOIAS, FABRICA DE', value: 139 },
  { label: 'JOIAS, COM OU SEM OFICINA, LOJA DE', value: 140 },
  { label: 'INFORMATICA/ELETROELETRONICO-LOJA/DEPOSI', value: 141 },
  { label: 'JOIAS, FABRICA DE (PREDIO E MAQUINISMO)', value: 142 },
  { label: 'JOIAS, COM OU SEM OFICINA, LOJA DE (PRED', value: 143 },
  { label: 'KARTODROMO', value: 144 },
  { label: 'LAB. ANALISE CLINICAS, QUIMICAS OU FARMA', value: 145 },
  { label: 'LADRILHOS OU CERAMICAS, FABRICA DE', value: 146 },
  { label: 'LATICINIOS, LOJAS / DEPOSITOS', value: 147 },
  { label: 'LATICINIOS, FABRICA', value: 148 },
  { label: 'LAN HOUSE/CYBER CAFE (EXCLUSIVAMENTE EM', value: 149 },
  { label: 'LAN HOUSE/CYBER CAFE (FORA DE SHOPPING CENTERS OU HIPERMERCADOS)', value: 150 },
  { label: 'LAVANDERIAS, SEM TINTURARIA (EXCETO INDUSTRIAL)', value: 151 },
  { label: 'LIMPEZA, LOJA OU DEPOSITO DE ARTIGOS DE', value: 152 },
  { label: 'LIMPEZA, FABRICA DE ARTIGOS DE', value: 153 },
  { label: 'LIVROS,REVISTAS,JORNAIS,CDS E DVDS-LOJA/DEPOSITO (S/BANCA DE JORNAL', value: 154 },
  { label: 'LOCADORA', value: 155 },
  { label: 'SHOPPINGS, LOJAS EM - ENQUADR. P/ ATIVI', value: 156 },
  { label: 'LONA, FABRICAS DE ARTIGOS DE', value: 157 },
  { label: 'LONA, ARTIGOS DE LOJA', value: 158 },
  { label: 'LOTERICAS', value: 159 },
  { label: 'LOUÇAS/PORCELANAS/LADRILHOS/CERAMICAS (E', value: 160 },
  { label: 'LUSTRES - DEPOSITOS OU LOJAS', value: 161 },
  { label: 'LUSTRES - FABRICA', value: 162 },
  { label: 'MADEIRA (LOJA/DEPOSITO)', value: 163 },
  { label: 'MARCENARIA (CARPINTARIA)', value: 164 },
  { label: 'MARMORARIA', value: 165 },
  { label: 'MÁQ ELETROMECANICAS COMERCIAIS/INDRUTRIAIS -LOJA/DEPÓSITO', value: 166 },
  { label: 'MARMORE/GRANITO- LOJA/DEPOSITO PERMITINDO FABRICAÇÃO DE ARTIGOS', value: 167 },
  { label: 'MASSAS ALIMENTICIAS -FABRICAS OU LOJAS', value: 168 },
  { label: 'MATERIAL PARA CONSTRUÇÃO-LOJA/DEPÓSITO', value: 169 },
  { label: 'MEIAS, FABRICA DE', value: 170 },
  { label: 'MEIAS, DEPOSITOS E/OU LOJAS DE', value: 171 },
  { label: 'METAL, FABRICA DE (FERRO OU ACO EM BLOCO', value: 172 },
  { label: 'METAL(EXCLUINDO DE PRECIOSO/SEMIPRECIOSO E SUCATAS)-DEPÓSITO', value: 173 },
  { label: 'METAL(EXCLUINDO PRECIOSO/SEMIPRECIOSO E SUCATAS)FAB DE ART E MÁQ.', value: 174 },
  { label: 'MOLDURAS, DEPOSITO OU LOJA COM OU SEM OF', value: 175 },
  { label: 'MOLDURAS SEM OFICINA -LOJA/DEPÓSITO', value: 176 },
  { label: 'MOTOCICLETAS (EXCLUSIVAMENTE CONCESSIONA', value: 177 },
  { label: 'MÓVEIS COM OFICINA LOJA/DEPÓSITO', value: 178 },
  { label: 'MÓVEIS SEM OFICINA -LOJA/DEPÓSITO', value: 179 },
  { label: 'MOVEIS, FABRICA COM ESTOFAMENTO', value: 180 },
  { label: 'MOVEIS, FABRICA SEM ESTOFAMENTO', value: 181 },
  { label: 'ONGSS / ENTIDADES FILANTROPICAS', value: 182 },
  { label: 'MAQ.ELETROMEC COMERC E INDUSTRIAIS-OFICINAS', value: 183 },
  { label: 'OLEOS VEGETAIS, DEPOSITOS OU LOJAS DE', value: 184 },
  { label: 'FABRICA', value: 185 },
  { label: 'ÓTICA-LOJA/DEPÓSITO', value: 186 },
  { label: 'PADARIAS/CONFEITARIAS', value: 187 },
  { label: 'PAPEL - LOJA OU DEPOSITO (EXCLUIDO DEPOSITO DE DOCUMENTOS) ARTIGOS DE', value: 188 },
  { label: 'PAPEL/PAPELAO-FAB DE ART (S/PROC RECICLAGEM E/OU UTILIZ APARAS/SUCATA)', value: 189 },
  { label: 'VEDACOES, FABRICAS (EXCLUSIVAMENTE METAL', value: 190 },
  { label: 'PEIXARIA', value: 191 },
  { label: 'PERFUMARIA/COSMÉTICOS -LOJA/DEPÓSITO', value: 192 },
  { label: 'PERFUMARIA/COSMÉTICOS (S/FABRICAÇÃO DE SABONETES OU VERNIZ) FABRICA', value: 193 },
  { label: 'PLASTICOS (SEM CELULOIDE) DEPOSITO OU LOJA - EXCLUINDO SUCATAS', value: 194 },
  { label: 'PLASTICOS (SEM CELULOIDE), FABRICA DE ARTIGOS - EXCLUINDO SUCATAS', value: 195 },
  { label: 'PET SHOP/AVICULTURA', value: 196 },
  { label: 'POSTOS DE GASOLINA / SERVICOS', value: 197 },
  { label: 'INSTRUMENTOS PRECISAO OU MEDICAO (LOJAS', value: 198 },
  { label: 'FARMACEUTICOS PROD, - LOJAS/DEP.VIDE FAR', value: 199 },
  { label: 'PRODUTOS QUIMICOS (SEM INFLAMAVEIS) - LOJA/DEPOSITO', value: 200 },
  { label: 'PRODUTOS QUIMICOS (SEM INFLAMAVEIS) -FABRICA', value: 201 },
  { label: 'PRODUTOS QUIMICOS, DEPOSITO DE PRODUTOS', value: 202 },
  { label: 'PROTESE, OFICINAS', value: 203 },
  { label: 'QUITANDAS(FRUTAS,VERDURAS E LEGUMES) - LOJAS/DEPÓSITO', value: 204 },
  { label: 'RAÇÕES BALANCEADAS-FÁBRICA', value: 205 },
  { label: 'RAÇÕES BALANCEADAS -LOJAS/DEPÓSITOS', value: 206 },
  { label: 'RESTAURANTE, CHOPERIA, CHURRASCARIA, PIZZARIA', value: 207 },
  { label: 'RESIDENCIA', value: 208 },
  { label: 'ROUPAS, FABRICAS OU OFICINAS DE', value: 209 },
  { label: 'ROUPAS, LOJAS DE', value: 210 },
  { label: 'ROUPAS, DEPOSITO DE', value: 211 },
  { label: 'ROUPAS PARA LOCAÇÃO -LOJA/DEPOSITO (PERMITINDO FÁBRICA)', value: 212 },
  { label: 'RECARGA DE CARTUCHOS DE IMPRESSAO', value: 213 },
  { label: 'SABÃO OU SABONETE, FÁBRICA', value: 214 },
  { label: 'SABAO OU SABONETE, DEPOSITOS OU LOJAS DE', value: 215 },
  { label: 'SAL- DEPÓSITO', value: 216 },
  { label: 'SERRALHERIAS- (S/TRABALHO COM MADEIRA)', value: 217 },
  { label: 'SHOPPING CENTER', value: 218 },
  { label: 'SILK SCREEN/ESTAMPARIA DE ROUPAS E TECIDOS-PERMITINDO A VENDA ARTIGOS', value: 219 },
  { label: 'SINDICATO DE EMPRESAS E/OU TRABALHADORES (SEM MARCENARIA)', value: 220 },
  { label: 'SORVETE/GELO - LOJA/DEPÓSITO', value: 221 },
  { label: 'SUPERMERCADOS,MERCEARIAS/EMPORIOS (SECOS E MOLHADOS) - LOJAS/DEPOSITOS', value: 222 },
  { label: 'TAPETES,CARPETES,FORRACOES OU CORTINAS (', value: 223 },
  { label: 'TAPETES,CARPETES,FORRACÕES OU CORTINAS (S/MADEIRA)-LOJA/DEPÓSITO', value: 224 },
  { label: 'TEATROS, SALOES DE CONCERTOS OU CINEMAS(EXCLUIDOS CIRCOS OU SIMILARES)', value: 225 },
  { label: 'TECIDOS OU FIOS (FIACAO OU TECELAGEM COM FIBRAS NATURAIS)', value: 226 },
  { label: 'TECIDOS OU FIOS (FIACAO OU TECELAGEM COM FIBRAS ARTIFICIAIS)', value: 227 },
  { label: 'TECIDOS OU FIOS (PERMITE ARTIGOS CAMA/MESA/BANHO)-LOJA/DEPOSITO', value: 228 },
  { label: 'TELEFONES, INCLUSIVE CELULARES - LOJA (EXCLUSIVAMENTE EM SHOPPINGS)', value: 229 },
  { label: 'TINTAS, LOJA/DEPOSITO, S/ MANIP, PERMITINDO-SE MAQUINA P/MISTURA CORES', value: 230 },
  { label: 'TINTAS (SEM INFLÁMAVEIS) - FÁBRICA', value: 231 },
  { label: 'TINTURARIAS (INCLUSIVE LAVANDERIAS INDUSTRIAIS) DE ROUPAS/FIOS/TECIDOS', value: 232 },
  { label: 'TRANSPORTADORA - EXCLUIDO DE INFLAMAVEIS, TOXICOS OU CORROSIVOS', value: 233 },
  { label: 'VETERINARIO', value: 234 },
  { label: 'VIDROS E/OU ARTIGOS DE VIDROS, FÁBRICA', value: 235 },
  { label: 'VIDROS OU ESPELHOS (VIDRACEIRO)-LOJAS', value: 236 },
  { label: 'TRANSPORTE DE PASSAGEIROS', value: 237 },
  { label: 'FATURAMENTO VIDA - DIVERSAS ATIVIDADES', value: 238 },
  { label: 'SEGURO PARA EDIFICIO COM VARIAS EMPRESAS', value: 239 },
  { label: 'LOTERICAS - (ACORDO CEF)', value: 240 },
  { label: 'IMOBIL./CORRET. E ADM DE IMOVEIS A PARTI', value: 241 },
  { label: 'CONCESSIONÁRIA -EXCLUSIVAMENTE REVENDA AUTORIZADA)', value: 242 },
  { label: 'GUARDA MOVEIS OU STORAGE', value: 243 },
  { label: 'ESCOLA DE ENSINO FUNDAMENTAL, MÉDIO E CRECHES', value: 244 },
  { label: 'UNIVERSIDADES / FACULDADES', value: 245 },
  { label: 'ESCOLA - PROFISSIONALIZANTES / TECNICOS', value: 246 },
  { label: 'ESCOLA DE IDIOMAS, CURSO PREPARATÓRIO OU AUTO ESCOLA', value: 247 },
  { label: 'ESCOLA DE INFORMATICA', value: 248 },
  { label: 'CONDOMÍNIO HORIZONTAL/EMPRESARIAL', value: 249 },
  { label: 'BANCAS DE JORNAL', value: 250 },
  { label: 'BEBIDAS NAO ALCOOLICAS - FABRICA/ENGARRAFAMENTO', value: 251 },
  { label: 'BIBLIOTECA/SEBO (EXCLUSIVO PARA PREDIO)', value: 252 },
  { label: 'COZINHA INDUSTRIAL/MASSAS ALIMENTICIAS/ROTISSERIE', value: 253 },
  { label: 'LABORATORIO DE DIAGNOSTICO/ANALISES CLINICAS', value: 254 },
  { label: 'LABORATORIO DE PESQUISA/ANALISE FISICA/QUIMICA', value: 255 },
  { label: 'LOJAS DE DEPTO. (EXCETO ALIMENTOS) - PERMITINDO DEPOSITOS', value: 256 },
  { label: 'MERCADO PUBLICO (EXCLUSIVO PARA PREDIO)', value: 257 },
  { label: 'MUSEU/ANTIQUIDADE (EXCLUSIVO PARA PREDIO)', value: 258 },
  { label: 'SORVETE/GELO (SEM EMPREGO DE MAT. INFLAMAVEL) - FABRICA', value: 259 },
  { label: 'MULTILET (EXCLUSIVO PARA PREDIO)', value: 260 },
  { label: 'FARINHAS E FARELOS - FÁBRICA', value: 261 },
  { label: 'GRÃOS E CEREAIS - DEPÓSITO OU LOJA (EXCETO A GRANEL)', value: 262 },
  { label: 'FRUTAS, VERDURAS E LEGUMES, COM BENEFICIAMENTO (EXCLUINDO ESTUFA)', value: 263 },
  { label: 'SEMENTES - DEPÓSITO OU LOJA (EXCETO A GRANEL)', value: 264 },
  { label: 'AUTOMOVEIS - AUTO ELETRICO, PERMITINDO VENDA DE PECAS DE AUTO-ELETRICO', value: 265 },
  { label: 'CONSULTORIO/CLINICA DENTARIA (PAVTO TERREO OU SOBRADO)', value: 266 },
  { label: 'CONSULTORIO/CLINICA DENTARIA (A PARTIR DO 1 ANDAR)', value: 267 },
  { label: 'CORTICA - DEPOSITO OU LOJA DE ARTIGOS', value: 268 },
  { label: 'DOCES, IND. E COM.', value: 269 },
  { label: 'ELETRODOMESTICOS - LOJA E DEPOSITO', value: 270 },
  { label: 'ELETROELET-LOJA/DEP.C/OFIC.CONSERTO', value: 271 },
  { label: 'ESCRITORIOS - LIMPEZA,VIGILANC,COBRANCA,ENGENH,CONSTRUT-TERREO/SOBRADO', value: 272 },
  { label: 'ESCRIT ENGENHARIA/SERV LIMPEZA, VIGILANCIA, COBRANCA- A PARTIR 1. AND', value: 273 },
  { label: 'SERV.TEMP, COBR, PREST.SERV DE VIG/CONST(TERREO/SOBRADO)', value: 274 },
  { label: 'DEMAIS ESCRIT, EXCETO DESP,BCO,SINDICATO (TERREO/SOBRADO)', value: 275 },
  { label: 'AGENCIA DE TURISMO E VIAGENS (A PARTIR 1.ANDAR)', value: 276 },
  { label: 'SERV.TEMP,COB,PREST.SERVICO,VIG,CONSTR.(A PARTIR 1.ANDAR)', value: 277 },
  { label: 'DEMAIS ESCRIT,EXCETO DESP,BCO,SINDICATO(A PARTIR 1.ANDAR)', value: 278 },
  { label: 'ESCRITORIOS DE SERVICOS DE INFORMATICA', value: 279 },
  { label: 'ESCRIT (EXCETO MICROS/PERIF.) DEP E LOJAS C/EXISTENCIA DE MAQ ART', value: 280 },
  { label: 'FERRAGENS OU FERRAMENTAS, DEPOSITO DE', value: 281 },
  { label: 'ESTUDIO FOTOGRAFICO, FOTOGRAFO, LABORATORIO FOTOGRAFICO', value: 282 },
  { label: 'GRAFICAS OU TIPOGRAFIAS COM ROTOGRAVURA', value: 283 },
  { label: 'PRODUTO PROPRIO - HOTEIS E POUSADAS', value: 284 },
  { label: 'INFORMATICA - ESCRITORIO DE SERVICOS DE INFORMATICA', value: 285 },
  { label: 'INFORMATICA - LOJA OU DEPOSITO SEM OFICINA DE CONSERTO', value: 286 },
  { label: 'MAQ./APARELHOS ELETRO-MECANICOS C/MOTOR INCORP. (LOJAS/DEP.)', value: 287 },
  { label: 'OFIC. MECANICA E/OU ELETRICA S/VENDA DE PECAS E ACESSORIOS', value: 288 },
  { label: 'PECAS E ACESSORIOS P/VEICULOS (EXCETO MOTOC) DEP OU LOJAS', value: 289 },
  { label: 'TORRE DE RETRANSMISSAO', value: 290 },
  { label: 'SECOS E MOLHADOS (SUPERMERCADO, MERCEARIA), LOJA (SEM VENDA)', value: 291 },
  { label: 'COMERCIO VAREJISTA', value: 292 },
  { label: 'COMERCIO ATACADISTA', value: 293 },
  { label: 'OUTROS', value: 294 },
  { label: 'CONSULTORIOS, ESCOLAS, ESCRITORIOS E HOSPITAIS', value: 295 },
  { label: 'COMERCIO E SERVICOS', value: 296 },
  { label: 'INDUSTRIAS', value: 297 },
  { label: 'IMOVEIS DESOCUPADOS NAO LOCADOS', value: 298 },
  { label: 'COMERCIO - 2', value: 299 },
  { label: 'TRANSPORTADORA', value: 300 },
  { label: 'LEGADO OUTROS', value: 301 },
  { label: 'Atividades auxiliares dos seguros, da previdência complement', value: 302 },
]
export const ACTIVITIESPF_OPTIONS = [
  { label: 'ATIVIDADE ESPECIAL', value: 1 },
  { label: 'ABRASIVOS FÁBRICAS - COM OU SEM INFLAMÁVEIS', value: 2 },
  { label: 'ACADEMIA DE GINÁSTICA/DANÇAS/LUTAS/ESCOLA DE NATAÇÃO OU ESPO', value: 3 },
  { label: 'AÇOUGUE/PEIXARIA', value: 4 },
  { label: 'AÇUCAR - DEPÓSITOS', value: 5 },
  { label: 'AGÊNCIAS BANCÁRIAS/LOJAS OU COOP. DE CRÉDITO/CASAS DE CÂMBIO', value: 6 },
  { label: 'AGÊNCIA DE TURISMO (EXCLUINDO CÂMBIO DE VALORES) - TERREO OU', value: 7 },
  { label: 'FUNEBRES (ARTIGOS) S/OFICINA/ESTOFAMENTO', value: 99 },
  { label: 'FUNEBRES (LOJAS C/OFIC. DE ARMADORES)', value: 100 },
  { label: 'AGÊNCIA DE TURISMO OU VIAGENS (EXCLUINDO CÂMBIO DE VALORES)', value: 8 },
  { label: 'BEBIDAS NÃO ALCOOLICAS (DEPÓSITO OU LOJA)', value: 9 },
  { label: 'ANTIGUIDADES (LOJAS)', value: 10 },
  { label: 'ARMARINHOS DEPÓSITOS SEM VENDAS', value: 11 },
  { label: 'ARMARINHOS (LOJAS)', value: 12 },
  { label: 'ARTIGOS P/PRESENTES/ARMARINHOS/ARTIGOS D', value: 13 },
  { label: 'ARMAS (LOJAS OU DEPÓSITOS)', value: 14 },
  { label: 'ARMAS (FÁBRICAS)', value: 15 },
  { label: 'ARROZ (DEPÓSITO C/ BENEF S/ PROCESSO DE D)', value: 16 },
  { label: 'ASILOS/CASA DE REPOUSO/CLÍNICAS PARA TRATAMENTO', value: 17 },
  { label: 'AUTOMÓVEIS (EXCLUSIVAMENTE LOCAÇÃO)', value: 18 },
  { label: 'AUTOMÓVEIS (EXCLUSIVAMENTE CONCESSIONARIA)', value: 19 },
  { label: 'AUTOMÓVEIS - LOJA/DEPÓSITO (EXCLUIDO MOTOS)', value: 20 },
  { label: 'AUTOMÓVEIS, OFICINA MEC, FUNILARIA, ELÉTRICA', value: 21 },
  { label: 'AUTOMÓVEIS - COMPONENTES OU ACESSÓRIOS, LO', value: 22 },
  { label: 'AVÍCOLAS - LOJA (EXCLUINDO ABATEDOURO)', value: 23 },
  { label: 'BANHA (DEPÓSITOS E LOJAS)', value: 24 },
  { label: 'SALÃO DE BUFFET', value: 25 },
  { label: 'BEBIDAS DEPÓSITOS SEM VENDA', value: 26 },
  { label: 'BEBIDAS DEPÓSITOS COM VENDA (DISTRIBUIDORA)', value: 27 },
  { label: 'BEBIDAS (LOJAS OU DEPÓSITOS)', value: 28 },
  { label: 'BEBIDAS ALCOOLICAS (FÁBRICAS)', value: 29 },
  { label: 'BIJOUTERIAS FÁBRICAS - EXCLUINDO METAIS, ARTEFATOS, JÓIAS, P', value: 30 },
  { label: 'BIJOUTERIAS LOJA/DEP - EXCLUINDO METAIS, ARTEFATOS (S/PREC/S', value: 31 },
  { label: 'BILHARES OU BOLICHES, SALÕES', value: 32 },
  { label: 'BOLSAS, CINTOS, MALAS OU CONGENERES - DE', value: 33 },
  { label: 'BOLSAS, CINTOS, MALAS OU SIMILARES (FÁBRICA)', value: 34 },
  { label: 'BORRACHEIRO (SEM RECAUCHUTAGEM)', value: 35 },
  { label: 'BORRACHA (LOJA OU DEPÓSITO DE ARTIGOS)', value: 36 },
  { label: 'BRINQUEDOS - FÁBRICA DE (SEM EMPREGO DE', value: 37 },
  { label: 'CABELEIREIROS, SALÃO DE BELEZA E/OU ESTÉTICA', value: 38 },
  { label: 'BEBIDAS NÃO ALCOOLICAS (FÁBRICA/ENGARRAFAMENTO)', value: 39 },
  { label: 'CAFÉ - DEPÓSITO C/VENDA (INCLUSIVE BENEF', value: 40 },
  { label: 'CALÇADOS (FÁBRICA)', value: 41 },
  { label: 'CALÇADOS (DEPÓSITOS OU LOJAS)', value: 42 },
  { label: 'CALCADOS (SAPATEIRO) - OFICINA', value: 43 },
  { label: 'BEBIDAS NÃO ALCOOLICAS (FÁBRICAS/ENGARRAFAM)', value: 44 },
  { label: 'CARIMBOS (FÁBRICAS OU OFICINAS)', value: 45 },
  { label: 'CARTÓRIOS', value: 46 },
  { label: 'CASAS DE BANHO (SAUNAS)', value: 47 },
  { label: 'HOTÉIS/MOTÉIS/POUSADAS', value: 48 },
  { label: 'ANUNCIOS LUMINOSOS, LETREIROS OU PAINEIS (FÁBRICA E/OU OFICI', value: 49 },
  { label: 'CEMITÉRIOS/VELÓRIOS/CREMATÓRIOS', value: 50 },
  { label: 'CIMENTO (DEPÓSITO OU LOJAS DE ARTIGOS)', value: 51 },
  { label: 'CIMENTO/GESSO (FÁBRICA DE ARTIGOS)', value: 52 },
  { label: 'CIMENTO (DEPÓSITO OU LOJA DE CIMENTO)', value: 53 },
  { label: 'CIRURGICOS, HOSPITALARES OU DENTÁRIOS', value: 54 },
  { label: 'COLCHÕES (DEPÓSITOS OU LOJAS SEM OFICINA)', value: 55 },
  { label: 'CLUBE RECREATIVO', value: 56 },
  { label: 'CONFEITARIA', value: 57 },
  { label: 'APARELHOS ORTOPÉDICOS (DEPÓSITO OU LOJA)', value: 58 },
  { label: 'COLCHÕES, FÁBRICA DE', value: 59 },
  { label: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL (DEPÓSITOS OU LOJAS', value: 60 },
  { label: 'CONSERVAS ALIMENTICIAS DE ORIGEM VEGETAL (FÁBRICAS)', value: 61 },
  { label: 'CONSULTÓRIO/CLINICA MEDICA OU DENTÁRIA', value: 62 },
  { label: 'CONSULTÓRIO/CLINICA MEDICA OU DENTÁRIA (A PARTIR DO 1º ANDAR', value: 63 },
  { label: 'CONVENTOS/MOSTEIROS', value: 64 },
  { label: 'COPIADORA PERMITINDO (SE ENCADERNAÇÃO, PLASTIFICAÇÃO E OUTRO', value: 65 },
  { label: 'CORDOARIA (CORDAS, CORDÕES) - DEPÓSITO', value: 66 },
  { label: 'CORDOARIA (CORDAS, CORDÕES) - FÁBRICAS', value: 67 },
  { label: 'COUROS OU PELES (FÁBRICA DE ARTIGOS)', value: 68 },
  { label: 'DESOCUPADO - IMOVEL COMERCIAL/INDUSTRIAL', value: 69 },
  { label: 'DIVERSÕES ELETRÔNICAS', value: 70 },
  { label: 'DOCES, BALAS, CHOCOLATES E SIMILARES (FÁBRICA)', value: 71 },
  { label: 'ELÉTRICOS/ILUMINAÇÃO OU ELETRICIDADE (DEPÓSITO/LOJAS DE ARTI', value: 72 },
  { label: 'ELETRODOMESTICOS (LOJAS/DEPÓSITOS)', value: 73 },
  { label: 'ELETRODOMESTICOS - (OFICINA DE CONSERTO)', value: 74 },
  { label: 'ELETROELET (LOJA/DEP.C/OFICINA)', value: 75 },
  { label: 'ELETROELETRONICOS (FÁBRICAS DE PECAS)', value: 76 },
  { label: 'EMBARCACÕES (LOJA/DEPÓSITO - SEM VENDA DE MOTORES DE POPA)', value: 77 },
  { label: 'EMPRESAS DE ONIBUS E/OU TAXIS', value: 78 },
  { label: 'EQUIP. PROTEÇÃO INDIVIDUAL (EPI) (LOJAS/DEPÓSITO)', value: 79 },
  { label: 'ENCOMENDAS EXPRESSAS - TERREO', value: 80 },
  { label: 'ARTIGOS RELIGIOSOS (EXCLUIDO MATERIAIS EXPLOSIVOS)', value: 81 },
  { label: 'ESCOLAS PARTICULARES E/OU FILANTROPICAS', value: 82 },
  { label: 'AUTOMÓVEIS - AUTO ELETRICO, PERMITINDO VENDA DE PECAS DE AUT', value: 83 },
  { label: 'EXTINTORES (LOJA/DEPÓSITO, PERMITINDO OFINA)', value: 84 },
  { label: 'ESTUDIO FOTOGRÁFICO E/OU DE GRAVAÇÃO SOM E IMAGEM', value: 85 },
  { label: 'FARINHAS (DEPÓSITOS OU LOJAS)', value: 86 },
  { label: 'EXPOSIÇÕES/CONVÊNÇÕES (EXCLUINDO BENS DE TERCEIROS)', value: 87 },
  { label: 'FARMACIAS OU DROGARIAS (DEPÓSITOS)', value: 88 },
  { label: 'FARMACIAS OU DROGARIAS (LOJAS)', value: 89 },
  { label: 'FARMACEUTICOS, PRODUTOS (FÁBRICA)', value: 90 },
  { label: 'FERRAGENS OU FERRAMENTAS - SEM VENDA DE MATERIAL DE CONST (L', value: 91 },
  { label: 'FLORES/PLANTAS NATURAIS - LOJAS (EXCLUINDO VIVEIRO/ESTUFA)', value: 92 },
  { label: 'AVICULTURA (DEPÓSITOS OU LOJAS)', value: 93 },
  { label: 'ESTUDIO FOTOGRÁFICO, FOTOGRAFO, LABORATORIO', value: 94 },
  { label: 'ESTUDIO FOTOGRÁFICO (DEPÓSITO OU LOJA DE ARTIGO)', value: 95 },
  { label: 'EMBUTIDOS (SEM MATADOURO) - FÁBRICA', value: 96 },
  { label: 'FRUTAS (DEPÓSITOS OU LOJAS)', value: 97 },
  { label: 'FUMOS, CHARUTOS/CIGARROS (LOJAS APENAS PERMITIDO - SE ART.P/', value: 98 },
  { label: 'GELO (FÁBRICA, DEPÓSITO OU LOJAS)', value: 101 },
  { label: 'HOSPITAIS', value: 102 },
  { label: 'IGREJAS/MOSTEIROS/CONVENTOS', value: 103 },
  { label: 'IMOBILIÁRIA, CORRETORA E OU ADM DE IMÓVEIS - TERREO', value: 104 },
  { label: 'INSTRUMENTOS MUSICAIS (LOJA/DEPÓSITO)', value: 105 },
  { label: 'INFORMÁTICA/ELETROELETRÔNICO (FÁBRICA OU MONTAGEM DE PEÇAS/E', value: 106 },
  { label: 'INFORMÁTICA - PROVEDORES DE INTERNET', value: 107 },
  { label: 'JOIAS, FÁBRICA', value: 108 },
  { label: 'INFORMATICA/ELETROELETRONICO (LOJA OU DEPÓSITO)', value: 109 },
  { label: 'JOIAS, FÁBRICA DE (PRÉDIO E MAQUINISMO)', value: 110 },
  { label: 'JOIAS, COM OU SEM OFICINA, LOJA', value: 111 },
  { label: 'KARTODROMO', value: 112 },
  { label: 'LAB. ANALISE CLINICAS, QUIMICAS OU FARMA', value: 113 },
  { label: 'LADRILHOS OU CERAMICAS, FÁBRICA', value: 114 },
  { label: 'LATICINIOS (LOJAS OU DEPÓSITOS)', value: 115 },
  { label: 'LATICÍNIOS (FÁBRICA)', value: 116 },
  { label: 'LAN HOUSE/CYBER CAFÉ', value: 117 },
  { label: 'BAR, BOTEQUIM, LANCHONETE E PASTELARIA', value: 118 },
  { label: 'LIMPEZA (LOJA OU DEPÓSITO DE ARTIGOS DE LIMPEZA)', value: 119 },
  { label: 'LIMPEZA (FÁBRICA)', value: 120 },
  { label: 'LONA (FÁBRICA)', value: 121 },
  { label: 'LONA (LOJA)', value: 122 },
  { label: 'LOTÉRICAS', value: 123 },
  { label: 'MADEIRA (LOJA OU DEPÓSITO)', value: 124 },
  { label: 'MARCENARIA (CARPINTARIA)', value: 125 },
  { label: 'MÁQ ELETROMECÂNICAS COMERCIAIS/INDRUTRIAIS (LOJA OU DEPÓSITO', value: 126 },
  { label: 'MUSEU/ANTIQUIDADE (EXCLUSIVO PARA PRÉDIO)', value: 194 },
  { label: 'SORVETE/GELO (SEM EMPREGO DE MAT. INFLAMÁVEL) - FÁBRICA', value: 195 },
  { label: 'MARMORE/GRANITO (LOJA/DEPÓSITO PERMITINDO FÁBRICAÇÃO DE ARTI', value: 127 },
  { label: 'MASSAS ALIMENTÍCIAS (FÁBRICA OU LOJA)', value: 128 },
  { label: 'MATERIAL PARA CONSTRUÇÃO (LOJA OU DEPÓSITO)', value: 129 },
  { label: 'MEIAS, DEPÓSITOS E/OU LOJAS', value: 130 },
  { label: 'METAL, FÁBRICA DE (FERRO OU ACO EM BLOCO)', value: 131 },
  { label: 'METAL (EXCLUINDO DE PRECIOSO/SEMIPRECIOSO E SUCATAS) DEPÓSIT', value: 132 },
  { label: 'METAL (EXCLUINDO PRECIOSO/SEMIPRECIOSO E SUCATAS) FAB DE ART', value: 133 },
  { label: 'MOLDURAS, DEPÓSITO OU LOJA COM OU SEM OFICINA', value: 134 },
  { label: 'MOLDURAS SEM OFICINA (LOJA OU DEPÓSITO)', value: 135 },
  { label: 'MOTOCICLETAS (EXCLUSIVAMENTE CONCESSIONÁRIAS)', value: 136 },
  { label: 'MÓVEIS COM OFICINA (LOJA OU DEPÓSITO)', value: 137 },
  { label: 'MÓVEIS SEM OFICINA (LOJA OU DEPÓSITO)', value: 138 },
  { label: 'MAQ.ELETROMEC COMERC E INDUSTRIAIS (OFICINAS)', value: 139 },
  { label: 'ÓLEOS VEGETAIS (DEPÓSITO OU LOJA)', value: 140 },
  { label: 'FÁBRICA', value: 141 },
  { label: 'ÓTICA (LOJA OU DEPÓSITO)', value: 142 },
  { label: 'PADARIAS E OU CONFEITARIAS', value: 143 },
  { label: 'PAPEL - LOJA OU DEPÓSITO (EXCLUIDO DEPÓSITO DE DOCUMENTOS)', value: 144 },
  { label: 'PAPEL/PAPELÃO - FAB DE ART (S/PROC RECICLAGEM E/OU UTILIZ AP', value: 145 },
  { label: 'PEIXARIA', value: 146 },
  { label: 'PERFUMARIA/COSMÉTICOS (LOJA OU DEPÓSITO)', value: 147 },
  { label: 'PERFUMARIA/COSMÉTICOS (S/FÁBRICAÇÃO DE SABONETES OU VERNIZ -', value: 148 },
  { label: 'PLÁSTICOS (SEM CELULOIDE) DEPÓSITO OU LOJA - EXCLUINDO SUCAT', value: 149 },
  { label: 'PLÁSTICOS (SEM CELULOIDE), FÁBRICA DE ARTIGOS - EXCLUINDO SU', value: 150 },
  { label: 'PET SHOP/AVICULTURA', value: 151 },
  { label: 'POSTOS DE GASOLINA/SERVIÇOS', value: 152 },
  { label: 'INSTRUMENTOS PRECISÃO OU MEDIÇÃO (LOJAS)', value: 153 },
  { label: 'FARMACEUTICOS PROD (LOJAS/DEP.VIDE FAR)', value: 154 },
  { label: 'QUITANDAS - FRUTAS, VERDURAS E LEGUMES (LOJA OU DEPÓSITO)', value: 155 },
  { label: 'BORRACHA (FÁBRICA DE ARTIGOS)', value: 156 },
  { label: 'BOTÕES - FÁBRICA (EXCLUIDO DE MADEIRA)', value: 157 },
  { label: 'ROUPAS, DEPÓSITO DE', value: 158 },
  { label: 'ROUPAS PARA LOCAÇÃO (LOJA OU DEPÓSITO - PERMITINDO FÁBRICA)', value: 159 },
  { label: 'RECARGA DE CARTUCHOS DE IMPRESSÃO', value: 160 },
  { label: 'SABÃO OU SABONETE (FÁBRICA)', value: 161 },
  { label: 'SABÃO OU SABONETE (DEPÓSITOS OU LOJAS)', value: 162 },
  { label: 'SAL (DEPÓSITO)', value: 163 },
  { label: 'SERRALHERIAS - (S/TRABALHO COM MADEIRA)', value: 164 },
  { label: 'SHOPPING CENTER', value: 165 },
  { label: 'SILK SCREEN/ESTAMPARIA DE ROUPAS E TECIDOS (PERMITINDO A VEN', value: 166 },
  { label: 'BRINQUEDOS (LOJA OU DEPÓSITO)', value: 167 },
  { label: 'TAPETES, CARPETES, FORRAÇÕES OU CORTINAS', value: 168 },
  { label: 'TAPETES, CARPETES, FORRAÇÕES OU CORTINAS (S/MADEIRA) - LOJA', value: 169 },
  { label: 'TEATROS, SALÕES DE CONCERTOS OU CINEMAS (EXCLUIDOS CIRCOS OU', value: 170 },
  { label: 'TECIDOS OU FIOS (FIAÇÃO OU TECELAGEM COM FIBRAS NATURAIS)', value: 171 },
  { label: 'TECIDOS OU FIOS (FIAÇÃO OU TECELAGEM COM FIBRAS ARTIFICIAIS)', value: 172 },
  { label: 'TECIDOS OU FIOS (PERMITE ARTIGOS CAMA/MESA/BANHO) - LOJA/DEP', value: 173 },
  { label: 'TELEFONES, INCLUSIVE CELULARES (LOJA EXCLUSIVAMENTE EM SHOPP', value: 174 },
  { label: 'TINTAS (SEM INFLÁMAVEIS) - FÁBRICA', value: 175 },
  { label: 'TINTURARIAS (INCLUSIVE LAVANDERIAS INDUSTRIAIS) DE ROUPAS/FI', value: 176 },
  { label: 'TRANSPORTADORA - EXCLUÍDO DE INFLAMÁVEIS, TOXICOS OU CORROSI', value: 177 },
  { label: 'VETERINÁRIO', value: 178 },
  { label: 'VIDROS E/OU ARTIGOS DE VIDROS (FÁBRICA)', value: 179 },
  { label: 'VIDROS OU ESPELHOS (VIDRACEIRO) - LOJA', value: 180 },
  { label: 'TRANSPORTE DE PASSAGEIROS', value: 181 },
  { label: 'SEGURO PARA EDIFICIO COM VARIAS EMPRESAS', value: 182 },
  { label: 'CAFÉ - DEPÓSITO S/VENDA (INCLUSIVE BENEF', value: 183 },
  { label: 'CONCESSIONÁRIA (EXCLUSIVAMENTE REVENDA AUTORIZADA)', value: 184 },
  { label: 'GUARDA MÓVEIS OU STORAGE', value: 185 },
  { label: 'ESCOLA DE ENSINO FUNDAMENTAL, MÉDIO E CRECHES', value: 186 },
  { label: 'ESCOLA DE IDIOMAS, CURSO PREPARATÓRIO OU AUTO ESCOLA', value: 187 },
  { label: 'ESCOLA DE INFORMÁTICA', value: 188 },
  { label: 'CONDOMÍNIO HORIZONTAL/EMPRESARIAL', value: 189 },
  { label: 'LABORATÓRIO DE DIAGNÓSTICO/ANÁLISES CLÍNICAS', value: 190 },
  { label: 'LABORATÓRIO DE PESQUISA/ANÁLISE FISÍCA/QUIMICA', value: 191 },
  { label: 'LOJAS DE DEPTO. (EXCETO ALIMENTOS) - PERMITINDO DEPÓSITOS', value: 192 },
  { label: 'MERCADO PÚBLICO (EXCLUSIVO PARA PRÉDIO)', value: 193 },
  { label: 'CARIMBOS/CHAVEIROS (DEPÓSITO OU LOJA)', value: 196 },
  { label: 'FRUTAS, VERDURAS E LEGUMES, COM BENEFICIAMENTO (EXCLUINDO ES', value: 197 },
  { label: 'SEMENTES (DEPÓSITO OU LOJA -EXCETO A GRANEL)', value: 198 },
  { label: 'CONSULTÓRIO/CLÍNICA DENTÁRIA (PAVIMENTO TERRÉO OU SOBRADO)', value: 199 },
  { label: 'CONSULTÓRIO/CLÍNICA DENTÁRIA (A PARTIR DO 1º ANDAR)', value: 200 },
  { label: 'CORTIÇA (DEPÓSITO OU LOJA DE ARTIGOS)', value: 201 },
  { label: 'DOCES, INDUSTRIA E COMÉRCIO', value: 202 },
  { label: 'ELETROELETRÔNICO (LOJA OU DEPÓSITO C/OFIC DE CONSERTO)', value: 203 },
  { label: 'ESCRITÓRIOS - LIMPEZA, VIGILANC, COBRANCA, ENGENH, CONSTRUT', value: 204 },
  { label: 'ESCRIT ENGENHARIA/SERV LIMPEZA, VIGILÂNCIA, COBRANÇA (A PART', value: 205 },
  { label: 'DEMAIS ESCRIT, EXCETO DESP, BCO, SINDICATO (TERREO OU SOBRAD', value: 206 },
  { label: 'AGÊNCIA DE TURISMO E VIAGENS (A PARTIR 1º ANDAR)', value: 207 },
  { label: 'SERV.TEMP, COB, PREST.SERVICO', value: 208 },
  { label: 'DEMAIS ESCRIT, EXCETO DESP, BCO, SINDICATO (A PARTIR 1º ANDA', value: 209 },
  { label: 'ESCRITÓRIOS DE SERVIÇOS DE INFORMÁTICA', value: 210 },
  { label: 'FERRAGENS OU FERRAMENTAS (DEPÓSITO)', value: 211 },
  { label: 'GRáFICAS OU TIPOGRAFIAS COM ROTOGRAVURA', value: 212 },
  { label: 'PRODUTO PRÓPRIO (HOTÉIS E POUSADAS)', value: 213 },
  { label: 'INFORMÁTICA - ESCRITÓRIO DE SERVICOS DE INFORMÁTICA', value: 214 },
  { label: 'INFORMÁTICA (LOJA OU DEPÓSITO SEM OFICINA DE CONSERTO)', value: 215 },
  { label: 'MAQ./APARELHOS ELETRO - MECÂNICOS C/MOTOR INCORP. (LOJA OU D', value: 216 },
  { label: 'OFIC. MECANICA E/OU ELÉTRICA S/VENDA DE PEÇAS E ACESSÓRIOS', value: 217 },
  { label: 'PEÇAS E ACESSÓRIOS P/VEICULOS (EXCETO MOTO - DEPÓSITO OU LOJ', value: 218 },
  { label: 'COMÉRCIO ATACADISTA', value: 219 },
  { label: 'OUTROS', value: 220 },
  { label: 'CONSULTÓRIOS, ESCOLAS, ESCRITÓRIOS E HOSPITAIS', value: 221 },
  { label: 'COMÉRCIO E SERVIÇOS', value: 222 },
  { label: 'INDÚSTRIAS', value: 223 },
  { label: 'IMÓVEIS DESOCUPADOS NÃO LOCADOS', value: 224 },
  { label: 'CHAVEIRO', value: 225 },
  { label: 'CIRURG, HOSPIT, DENT, ORTOP, ART (EXCETO MEDICAMENTO/EQP.ELE', value: 226 },
  { label: 'POLAS (ENVASAMENTO E EMBALAGEM)', value: 227 },
  { label: 'CORREIOS/ ENCOMENDAS EXPRESSAS', value: 228 },
  { label: 'COUROS OU PELES (DEPÓSITO OU LOJA)', value: 229 },
  { label: 'DEFENSIVO AGRÍCOLA E/OU SEMENTES (LOJA/DEPÓS EXCETO A GRANEL', value: 230 },
  { label: 'DESPACHANTES (ESCRITÓRIO)', value: 231 },
  { label: 'DESENTUPIDORA', value: 232 },
  { label: 'CDS, DVDS E SIMILARES (LOJAS)', value: 233 },
  { label: 'DOCES, BALAS, CHOCOLATES E SIMILARES (LOJAS OU DEPÓSITOS)', value: 234 },
  { label: 'ELETRODOMESTICOS (LOJA E DEPÓSITO)', value: 235 },
  { label: 'ENCOMENDAS EXPRESSAS (A PARTIR 1º ANDAR)', value: 236 },
  { label: 'ESCOLAS PUBLICAS (ESTADUAIS, MUNICIPAIS)', value: 237 },
  { label: 'ESCOLAS PARTICULARES E/OU FILANTRÓPICAS', value: 238 },
  { label: 'ESCRITÓRIOS DEMAIS - TERREO/SOBRADOS', value: 239 },
  { label: 'ESCRITÓRIOS DEMAIS - A PARTIR 1º ANDAR', value: 240 },
  { label: 'ESCRITÓRIOS DE SERV INFOR OU PUBLIC', value: 241 },
  { label: 'ESCRITÓRIOS DE SERV INFORMATICA OU PUBLIC (TERRÉO OU SOBRADO', value: 242 },
  { label: 'ESCRIT (EXCETO MICROS/PERIF.) DEP E LOJAS C/EXISTENCIA DE MA', value: 243 },
  { label: 'ESTACIONAMENTO E/OU LAVA RAPIDO, SEM OFICINA OU ABASTECIMENT', value: 244 },
  { label: 'ESPORTE, ARTIGOS DE - LOJA/DEPÓSITO (EXCLUÍNDO ARMAS DE FOGO', value: 245 },
  { label: 'ESTAÇÃO RETRANSMISSORA DE RÁDIO OU TELEVISÃO/TORRE DE RETRAN', value: 246 },
  { label: 'FLORES ARTIFICIAIS (FÁBRICA OU LOJAS)', value: 247 },
  { label: 'ESTUDIO FOTOGRÁFICO, FOTÓGRAFO, LABORATÓRIO FOTOGRÁFICO', value: 248 },
  { label: 'FRIGORÍFICO - DEPÓSITOS (SEM BENEFICIAMENTO)', value: 249 },
  { label: 'GRÁFICAS OU TIPOGRAFIAS (COM OU SEM ROTOGRAVURA)', value: 250 },
  { label: 'GUARDA-CHUVAS, GUARDA-SOIS OU BENGALAS (LOJAS OU OFICINAS)', value: 251 },
  { label: 'GUARDA-CHUVAS, GUARDA-SOIS OU BENGALAS (FÁBRICA - EXCLUINDO', value: 252 },
  { label: 'JÓIAS, COM OU SEM OFICINA, LOJA DE', value: 253 },
  { label: 'LAN HOUSE/CYBER CAFé (FORA DE SHOPPING CENTERS OU HIPERMERCA', value: 254 },
  { label: 'LAVANDERIAS, SEM TINTURARIA (EXCETO INDUSTRIAL)', value: 255 },
  { label: 'LIVROS, REVISTAS, JORNAIS, CDS E DVDS (LOJA OU DEPÓSITO - S/', value: 256 },
  { label: 'LOCADORA', value: 257 },
  { label: 'SHOPPINGS, LOJAS EM - ENQUADR. P/ ATIVI', value: 258 },
  { label: 'LUSTRES (DEPÓSITO OU LOJA)', value: 259 },
  { label: 'LUSTRES (FÁBRICA)', value: 260 },
  { label: 'MARMORARIA', value: 261 },
  { label: 'MEIAS, FÁBRICA DE', value: 262 },
  { label: 'MÓVEIS, FÁBRICA COM ESTOFAMENTO', value: 263 },
  { label: 'MÓVEIS, FÁBRICA SEM ESTOFAMENTO', value: 264 },
  { label: 'ONGS/ENTIDADES FILANTRÓPICAS', value: 265 },
  { label: 'VEDAÇÕES, FÁBRICAS (EXCLUSIVAMENTE METAL)', value: 266 },
  { label: 'PRODUTOS QUÍMICOS - SEM INFLAMÁVEIS (LOJA OU DEPÓSITO)', value: 267 },
  { label: 'PRODUTOS QUÍMICOS - SEM INFLAMÁVEIS (FÁBRICA)', value: 268 },
  { label: 'PRODUTOS QUÍMICOS (DEPÓSITO DE PRODUTOS)', value: 269 },
  { label: 'PRÓTESE, OFICINAS', value: 270 },
  { label: 'TORRE DE RETRANSMISSÃO', value: 271 },
  { label: 'RAÇÕES BALANCEADAS (FÁBRICA)', value: 272 },
  { label: 'RAÇÕES BALANCEADAS (LOJAS OU DEPÓSITO)', value: 273 },
  { label: 'RESTAURANTE, CHOPERIA, CHURRASCARIA, PIZZARIA', value: 274 },
  { label: 'RESIDÊNCIA', value: 275 },
  { label: 'ROUPAS (FÁBRICAS OU OFICINAS)', value: 276 },
  { label: 'ROUPAS (LOJAS)', value: 277 },
  { label: 'SINDICATO DE EMPRESAS E/OU TRABALHADORES (SEM MARCENARIA)', value: 278 },
  { label: 'SORVETE/GELO (LOJA OU DEPÓSITO)', value: 279 },
  { label: 'SECOS E MOLHADOS (SUPERMERCADO, MERCEARIA), LOJA (SEM VENDA)', value: 280 },
  { label: 'SUPERMERCADOS, MERCEARIAS/EMPÓRIOS (SECOS E MOLHADOS) - LOJA', value: 281 },
  { label: 'TINTAS, LOJA/DEPÓSITO, SEM MANIPULAÇÃO, PERMITINDO - SE MAQU', value: 282 },
  { label: 'COMÉRCIO VAREJISTA', value: 283 },
  { label: 'TRANSPORTADORA', value: 284 },
  { label: 'LOTÉRICAS (ACORDO CEF)', value: 285 },
  { label: 'IMOBILIÁRIA/CORRETORA E ADM DE IMÓVEIS A PARTIR DO PRIMEIRO', value: 286 },
  { label: 'UNIVERSIDADES/FACULDADES', value: 287 },
  { label: 'ESCOLA - PROFISSIONALIZANTES/TÉCNICOS', value: 288 },
  { label: 'HOLDING DE INSTITUIÇÃO NÃO FINANCEIRA', value: 289 },
  { label: 'HOLDING DE INSTITUIÇÃO NÃO FINANCEIRA', value: 290 },
  { label: 'BANCAS DE JORNAL', value: 291 },
  { label: 'BIBLIOTECA/SEBO (EXCLUSIVO PARA PRÉDIO)', value: 292 },
  { label: 'COZINHA INDUSTRIAL/MASSAS ALIMENTÍCIAS/ROTISSERIE', value: 293 },
  { label: 'MULTILET (EXCLUSIVO PARA PRÉDIO)', value: 294 },
  { label: 'FARINHAS E FARELOS (FÁBRICA)', value: 295 },
  { label: 'GRÃOS/CEREAIS (EXCLUINDO SEMENTES) LOJA/DEPÓSITO EXCETO A GR', value: 296 },
  { label: 'BICICLETAS - DEPÓSITOS/LOJA PERMITINDO O', value: 297 },
  { label: 'LOUÇAS/PORCELANAS/LADRILHOS/CERAMICAS', value: 298 },
  { label: 'BOLSAS, CINTOS, MALAS OU SIMILARES (LOJA OU DEPÓSITO)', value: 299 },
  { label: 'AGRICULTURA - CAFÉ COM SECADOR', value: 300 },
  { label: 'AGRICULTURA - CAFÉ SEM SECADOR', value: 301 },
  { label: 'AGRICULTURA - CANA DE AÇÚCAR (EXCETO USINA)', value: 302 },
  { label: 'AGRICULTURA - FUMO/CHÁ/ERVA MATE', value: 303 },
  { label: 'AGRICULTURA - GRÃOS /CEREAIS NÃO OLEAGINOSOS - COM SECADOR', value: 304 },
  { label: 'AGRICULTURA - GRÃOS /CEREAIS NÃO OLEAGINOSOS - SEM SECADOR', value: 305 },
  { label: 'AGRICULTURA - GRÃOS /CEREAIS OLEAGINOSOS - COM SECADOR', value: 306 },
  { label: 'AGRICULTURA - GRÃOS /CEREAIS OLEAGINOSOS - SEM SECADOR', value: 307 },
  { label: 'APICULTURA', value: 308 },
  { label: 'AQUICULTURA', value: 309 },
  { label: 'ATIVIDADES FLORESTAIS', value: 310 },
  { label: 'AVICULTURA', value: 311 },
  { label: 'BOVINOCULTURA (CORTE)', value: 312 },
  { label: 'BOVINOCULTURA (LEITE)', value: 313 },
  { label: 'CRIAÇÃO DE ANIMAIS SILVESTRES', value: 314 },
  { label: 'CRIAÇÃO DE BÚFALOS', value: 315 },
  { label: 'CRIAÇÃO DE COELHOS', value: 316 },
  { label: 'EQUINOCULTURA', value: 317 },
  { label: 'FLORICULTURA/PLANTAS ORNAMENTAIS', value: 318 },
  { label: 'FRUTICULTURA', value: 319 },
  { label: 'FUNGICULTURA', value: 320 },
  { label: 'HORTICULTURA/OLERICULTURA', value: 321 },
  { label: 'OVINOCULTURA/CAPRINOCULTURA', value: 322 },
  { label: 'SUINOCULTURA', value: 323 },
  { label: 'TURISMO RURAL', value: 324 },
]
