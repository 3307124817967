import { useCurrentOrganization } from '@/modules'
import { loadBrokerPartnershipSuseps } from '@/services/api/partnership'
import { useField } from 'formik'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { usePartnerOptions } from '../RealEstatePartnershipCombo/hooks'

type TypeData = Awaited<ReturnType<typeof loadBrokerPartnershipSuseps>>

export function usePartnershipSusepQuery(options?: UseQueryOptions<TypeData>) {
  const organization = useCurrentOrganization()
  const [partnerField] = useField('partner')

  const partnerOptionsQuery = usePartnerOptions()

  const partnerSelected = useMemo(() => {
    return partnerOptionsQuery.data?.find(e => e.id === partnerField.value)
  }, [partnerField.value, partnerOptionsQuery.data])

  const susepOptionsQuery = useQuery<TypeData>(
    [`@load-suseps-${partnerSelected?.partnershipId}`],
    () => loadBrokerPartnershipSuseps(partnerSelected?.partnershipId),
    {
      // ? Tempo de vida do dado antes de uma nova busca 5min.
      staleTime: 1 * 60 * 1000,
      enabled: organization.type === 'BROKER' && !!partnerSelected?.partnershipId,
      ...options,
    },
  )

  return susepOptionsQuery
}
