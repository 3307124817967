import React from 'react'

import Base from '../Base'

import { Actions, Conditional, Content as ContentComponent, Icons } from '../../index'

import { IContentProps } from './interfaces'

import { dashText } from '@/tools'
import { Body, CloseIconButton, Container, Footer, Head, Icon, Loader, Title } from './styles'

const variants = {
  hidden: { y: -40 },
  visible: {
    y: 0,
    transition: { ease: [0.25, 1, 0.5, 1], duration: 0.4 },
  },
}

const Content: React.FC<IContentProps> = props => {
  const { Button } = Actions

  return (
    <Base onDismiss={props.onDismiss}>
      <Container initial="hidden" animate="visible" size={props.size} variants={variants}>
        <Conditional when={!!props.title || !!props.icon}>
          <Head>
            {props.icon && <Icon name={props.icon} color={props.colorIcon} />}
            <Title color={props.colorIcon}>{props.title}</Title>
            <CloseIconButton onClick={props.onDismiss}>
              <Icons.FeatherIcons name="x" />
            </CloseIconButton>
          </Head>
        </Conditional>

        {props.loader?.status ? (
          <Loader>
            <ContentComponent.Loader message={props.loader?.message} />
          </Loader>
        ) : (
          <>
            <Body padding={props.padding ? props.padding : false}>{props.children}</Body>

            {(props.onCancel || props.onSubmit || props.onButton) && (
              <Footer>
                {props.onCancel && (
                  <Button
                    kind="smooth"
                    type="button"
                    onClick={props.onCancel.onClick}
                    isDisabled={props.onCancel.disabled}
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name={dashText(props.onCancel.text)}
                  >
                    {props.onCancel.text}
                  </Button>
                )}

                {props.onButton && (
                  <Button
                    onClick={props.onButton.onClick}
                    isDisabled={props.onButton.disabled}
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name={dashText(props.onButton.text)}
                  >
                    {props.onButton.text}
                  </Button>
                )}

                {props.onSubmit && (
                  <Button
                    onClick={props.onSubmit.onClick}
                    isDisabled={props.onSubmit.disabled}
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name={dashText(props.onSubmit.text)}
                  >
                    {props.onSubmit.text}
                  </Button>
                )}
              </Footer>
            )}
          </>
        )}
      </Container>
    </Base>
  )
}

Content.defaultProps = {
  size: 'medium',
}

export default Content
