import {
  isBroker,
} from '@/tools/partnership'
export interface ButtonMenu {
  label: string
  icon: string
  tag?: string
  sectionTitle?: string
  url?: string
  hidden?: boolean
  disabled?: boolean
  menuButtons?: ButtonMenu[]
}

export const useMenuButtons = (profile: { type: string }, essencialEnabled: boolean, capitalizacaoEnabled: boolean, imobiliariaEnabled: boolean, orcamentoLoteEnabled: boolean): ButtonMenu[] => {

  return [
    {
      label: 'Página inicial',
      icon: 'home',
      url: '/',
    },
    {
      label: 'Gestão de carteira',
      icon: 'addressbook',
      url: '/consultas',
    },
    {
      label: 'Novidades',
      icon: 'star',
      url: '/novidades',
    },
    {
      label: 'Benefícios',
      icon: 'giftbox',
      menuButtons: [
        {
          label: 'Assinatura Eletrônica',
          icon: 'edit',
          url: '/assinatura-eletronica',
        },
      ],
    },
    {
      label: 'Parceria Premiada',
      icon: 'handshake',
      url: '/campanhas/parceria-premiada',
      tag: 'Novo',
      hidden: !process.env.REACT_APP_ENABLE_PARCERIA_PREMIADA,
    },
    {
      sectionTitle: 'Soluções para Locação',
      label: 'Seguro Incêndio',
      icon: 'fireflame',
      menuButtons: [
        {
          label: 'Nova Proposta',
          icon: 'add',
          url: '/contratos/novo/imobiliaria',
          disabled: !imobiliariaEnabled,
        },
        {
          label: 'Orçamento em Lote',
          icon: 'cubecheck',
          url: '/orcamento',
          disabled: !orcamentoLoteEnabled,
        },
        {
          label: 'Renovação',
          icon: 'reload',
          url: '/renovacoes/novo/imobiliaria',
          disabled: !imobiliariaEnabled,
        },
        {
          label: 'Entenda o Produto',
          icon: 'circleinfo',
          url: '/produtos/seguro-incendio',
        },
      ],
    },
    {
      label: 'Fiança Locatícia',
      icon: 'receiptcheck',
      menuButtons: [
        {
          label: 'Nova Proposta',
          icon: 'add',
          url: '/contratos/novo/essencial',
          disabled: !essencialEnabled,
        },
        {
          label: 'Parcelamento de Aluguel',
          icon: 'calendar',
          url: '/sinistro/parcelamento-aluguel',
          disabled: !essencialEnabled,
        },
        {
          label: 'Assessoria de Cobrança',
          icon: 'handcoins2',
          url: '/sinistro/assessoria-de-cobranca',
          hidden: isBroker(profile),
        },
        {
          label: 'Sinistro',
          icon: 'residencealert',
          url: '/sinistro',
          disabled: !essencialEnabled,
        },
        {
          label: 'Entenda o Produto',
          icon: 'circleinfo',
          url: '/produtos/fianca-locaticia',
        },
      ],
    },
    {
      label: 'Título de Capitalização',
      icon: 'circledollarsign',
      menuButtons: [
        {
          label: 'Nova Proposta',
          icon: 'add',
          url: '/contratos/novo/capitalizacao',
          disabled: !capitalizacaoEnabled,
        },
        {
          label: 'Entenda o Produto',
          icon: 'circleinfo',
          url: '/produtos/titulo-de-capitalizacao',
        },
      ],
    },
    {
      sectionTitle: 'Produtos de Crédito',
      label: 'Empréstimo com Garantia de Imóvel',
      icon: 'money',
      menuButtons: [
        {
          label: 'Nova Indicação',
          icon: 'add',
          url: '/home-equity/estate-guarantee',
        },
        {
          label: 'Entenda o Produto',
          icon: 'circleinfo',
          url: '/produtos/home-equity',
        },
      ],
    },
    {
      label: 'Cartão Porto Bank',
      icon: 'creditcard',
      hidden: isBroker(profile),
      menuButtons: [
        {
          label: 'Nova Indicação',
          icon: 'add',
          url: '/produtos/cartao-porto/indicar',
        },
        {
          label: 'Entenda o produto',
          icon: 'circleinfo',
          url: '/produtos/cartao-porto',
        },
      ],
    },
  ]
}
