import React, { useState, Suspense, lazy, useEffect } from 'react'

import { api } from '@/services'
import { Content, Modals, toast } from '@/components'
import { useAuth, useCurrentUser, useCurrentOrganization } from '@/modules'
import { dashText, encrypt, useGTM, useTabs } from '@/tools'
import {
  Container,
  Card,
  Navigation,
  Item,
  Icon,
  Inside,
  Head,
  Title,
  Description,
} from './styles'

const Tabs = {
  Details: lazy(() => import('./Tabs/Details/index')),
  Password: lazy(() => import('./Tabs/Password/index')),
  Email: lazy(() => import('./Tabs/Email/index')),
}

const Edit = () => {
  const { Heading } = Content

  const auth = useAuth()
  const user = useCurrentUser()
  const profile = useCurrentOrganization()
  const { setCustomData, setDataLayer } = useGTM()

  const [loading, setLoading] = useState(false)

  const updateData = async (payload) => {
    try {
      setLoading(true)

      await user.updateProfile(payload).catch((error) => {
        setDataLayer({
          event: 'solicitacao_servico',
          nome_servico: 'meus-dados',
          tipo_servico: 'alterar-meus-dados',
          user_id: encrypt(profile?.document),
          retorno: 'erro',
          descricao: 'usuario-atualizar-dados',
          erro: {
            codigo: error?.response?.data?.status,
            servico: 'atualizar-meus-dados',
            mensagem: error?.response?.data?.message,
          },
        })
      })

      toast('Seus dados foram atualizados com sucesso!', {
        type: 'success',
      })
      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'meus-dados',
        tipo_servico: 'alterar-meus-dados',
        user_id: encrypt(profile?.document),
        retorno: 'sucesso',
        descricao: 'usuario-atualizar-dados',
      })
    } finally {
      setLoading(false)
    }
  }

  const updatePassword = async (payload) => {
    try {
      setLoading(true)

      await api.users.auth.updatePassword(user.store.email, {
        new: payload.password,
        current: payload.currentPassword,
      })

      toast('Sua senha foi atualizada com sucesso!', {
        type: 'success',
      })
      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'minha-conta',
        tipo_servico: 'alterar-senha',
        descricao: 'senha atualizada com sucesso',
        retorno: 'sucesso',
      })
    } catch (error) {
      toast(
        'Ocorreu um erro ao alterar sua senha, por favor tente novamente.',
        {
          type: 'error',
        }
      )
      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'minha-conta',
        tipo_servico: 'alterar-senha',
        descricao: 'falha ao atualizar senha',
        retorno: 'erro',
        erro: {
          codigo: error.response?.status,
          servico: 'alteração de senha',
          mensagem: error.response?.data?.message,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  const { Tab, ...tabs } = useTabs(
    [
      {
        key: 'details',
        title: 'Meus dados',
        render: () => <Tabs.Details updateData={updateData} />,
      },
      {
        key: 'password',
        title: 'Alterar senha',
        render: () => <Tabs.Password updatePassword={updatePassword} />,
      },
      !user.store.isAdmin && {
        key: 'email',
        title: 'Alterar e-mail',
        render: () => <Tabs.Email />,
      },
    ].filter(Boolean),
    {
      activeAnchor: true,
    }
  )

  const handleLogout = async () => {
    setDataLayer({
      event: 'modal',
      titulo: 'abrir',
      etapa: 'sair-do-portal',
    })
    const confirm = await Modals.Confirmation({
      icon: 'log-out',
      title: 'Sair do Portal',
      content: 'Deseja realmente sair do portal?',
      submitText: 'Sair do Portal',
      disabledOutsideClick: true,
      gtmType: {
        submitText: {
          'data-gtm-type': 'click',
          'data-gtm-clicktype': 'button',
          'data-gtm-name': 'sair-do-portal',
        },
        dismissText: {
          'data-gtm-type': 'click',
          'data-gtm-clicktype': 'button',
          'data-gtm-name': 'cancelar',
        },
      },
    })
    setDataLayer({
      event: 'modal',
      titulo: 'fechar',
      etapa: 'sair-do-portal',
    })

    if (confirm) {
      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'minha-conta',
        tipo_servico: `sair-do-portal`,
        retorno: 'sucesso',
        descricao: 'usuario-deslogado',
      })
    } else {
      setDataLayer({
        event: 'solicitacao_servico',
        nome_servico: 'minha-conta',
        tipo_servico: `cancelar`,
        retorno: 'sucesso',
        descricao: 'usuario-se-manteve-logado',
      })
    }

    setDataLayer({
      event: 'modal',
      titulo: 'fechar',
      etapa: 'sair-do-portal',
    })

    if (confirm) auth.handleLogout()
  }

  useEffect(() => {
    setCustomData({
      site: {
        brand: 'portoseguro',
        portal: 'imobiliarias',
      },
      page: {
        name: 'Portal Imobiliária - Minha Conta - Meus Dados',
      },
    })
    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - Minha Conta meus dados',
      etapa: '/area-logada/minha-conta/meus-dados',
    })
    //eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Heading
        title="Minha conta"
        subtitle="Aqui você visualiza e edita as suas informações cadastrais."
      />

      <Card>
        <Navigation>
          {tabs.items.map((tab) => (
            <Item
              key={tab.key}
              onClick={() => tabs.setCurrent(tab)}
              active={tabs.current.key === tab.key}
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name={dashText(tab.title)}
            >
              <Icon
                name={
                  {
                    details: 'person-outline',
                    password: 'lock-closed-outline',
                    email: 'mail-outline',
                  }[tab.key]
                }
              />

              {tab.title}
            </Item>
          ))}

          <Item
            onClick={handleLogout}
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="sair-do-portal"
          >
            <Icon name="log-out-outline" /> Sair do Portal
          </Item>
        </Navigation>

        <Inside>
          <Head>
            <Title>
              {
                {
                  details: 'Meus dados',
                  password: 'Alterar senha',
                  email: 'Alterar e-mail de acesso ao Portal',
                }[tabs.current.key]
              }
            </Title>

            <Description>
              {
                {
                  details: 'Altere os dados de sua conta aqui.',
                  password: 'Altere aqui sua senha de acesso ao Portal.',
                }[tabs.current.key]
              }
            </Description>
          </Head>

          <Suspense
            fallback={
              <Content.Loader
                alignment="left"
                message="Aguarde, carregando informações."
              />
            }
          >
            {loading ? (
              <Content.Loader
                alignment="left"
                message="Aguarde, estamos salvando as alterações dos seus dados."
              />
            ) : (
              <Tab />
            )}
          </Suspense>
        </Inside>
      </Card>
    </Container>
  )
}

export default Edit
