import { useField } from 'formik'
import { useEffect, useMemo } from 'react'
import { AutocompleteLocal } from '../AutocompleteLocal'
import { usePartnershipSusepQuery } from './hooks'

export const RealEstatePartnershipSupepCombo = () => {
  const [field, , helpers] = useField('susep')
  const [partnerField, partnerMeta] = useField('partner')

  const susepQuery = usePartnershipSusepQuery()

  const options = useMemo(() => {
    if (susepQuery.isLoading) return

    return susepQuery.data?.map(e => e.susep)
  }, [susepQuery.data, susepQuery.isLoading])

  useEffect(() => {
    if (susepQuery.isFetching || !options) return

    const valid = options.some(value => value === field.value)

    if (!valid) {
      helpers.setValue(undefined)
    }
  }, [field.value, helpers, options, susepQuery.isFetching])

  return (
    <AutocompleteLocal<string>
      label="SUSEP"
      name="susep"
      id="real-estate-partnership-susep-combo"
      getOptionLabel={option => option || ''}
      disabled={!partnerField.value || !!partnerMeta.error}
      loading={susepQuery.isLoading}
      options={options || []}
    />
  )
}
