import { useCurrentOrganization } from '@/modules'
import { Organization } from '@/screens/Contracts/Products/shared'
import { BaseCard } from '../../../../components/BaseCard'
import * as S from './styles'

export const InsuranceBroker = ({ isInDetailsScreen }: { isInDetailsScreen: boolean }) => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'

  return (
    <BaseCard
      title={isBroker ? 'Imobiliária' : 'Corretora de Seguros'}
      subtitle={!isInDetailsScreen && !isBroker && 'Selecione a SUSEP do corretor de seguros.'}
    >
      <S.OrganizationWrapper>
        <Organization product="porto_capitalizacao" data-test-id="susep" />
      </S.OrganizationWrapper>
    </BaseCard>
  )
}
