import { Forms } from '@/components'
import { PartnershipSusepCombo } from '@/components/PartnershipSusepCombo'

const Organization = () => {
  return (
    <Forms.Card active step="1" title="Organização">
      <PartnershipSusepCombo product="porto_imobiliaria" />
    </Forms.Card>
  )
}

export default Organization
