import { FormikProvider, useFormik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import { Conditional, Content } from '@/components'
import { SvgIcons } from '@/components/SvgIcons'
import { useCurrentOrganization } from '@/modules'
import { api } from '@/services'

import UploadModal from '../components/UploadModal'
import validationSchema from './schema'
import * as Steps from './Steps'
import { Container } from './styles'

import { Products } from '@/screens/Contracts/Products/shared/entities'
import { usePartnershipQuery } from '@/tools/hooks/partnership'
import { useIsImobiliariaBatchEnabled } from '@/tools/partnership'
import {
  IBatch,
  INITIAL_STATE,
  resetBatches,
  setBroker,
  setBudgets,
  setPartner,
  setPeriodStart,
} from '../../../store/ducks/batches'

const initialValues = INITIAL_STATE

export type Batch = typeof initialValues

const CalculateBatch = () => {
  const batches: IBatch = useSelector(({ batches }: { batches: IBatch }) => batches)
  const dispatch = useDispatch()

  const organization = useCurrentOrganization()
  const history = useHistory()
  const loteEnable = useIsImobiliariaBatchEnabled(organization)
  const partnerships = usePartnershipQuery()

  const handleReduxState = () => {
    const susep = form.values.susep
    // const broker = partnerships.data?.find(e => e.suseps?.includes(susep))

    if (susep && batches.broker.susep !== susep) dispatch(setBroker({ id: susep, susep }))

    if (form.values.partner && batches.partner !== form.values.partner)
      dispatch(setPartner(form.values.partner))
    if (form.values.period_start && batches.period_start !== form.values.period_start)
      dispatch(setPeriodStart(form.values.period_start))
  }

  const form = useFormik<Batch>({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      let batch

      try {
        UploadModal({
          icon: SvgIcons['upload'],
          title: 'Enviando arquivo. Por favor, aguarde...',
          description: `${values.filename}`,
        })

        batch = await api.batchs.validate({
          ...batches,
          organizationType: organization.type,
        })
      } catch (e) {
        UploadModal({
          icon: SvgIcons['uploadAlert'],
          title: 'Ocorreu um erro na importação. Por favor, tente novamente.',
          description: `${values.filename}`,
        })
        dispatch(setBudgets([]))
        return
      }

      const existErrors = batch.budgets.some(budget => budget.errors.length > 0)

      if (existErrors) {
        history.push(`/orcamento/detalhes`, batch)

        setTimeout(() => {
          UploadModal({
            icon: SvgIcons['uploadAlert'],
            title:
              'Há alguns erros na planilha. Por favor verifique quais são, e após a correção, tente novamente.',
            description: `${values.filename}`,
          })
        }, 100)

        return
      }

      try {
        await api.batchs.calculate(batch.id)
      } catch (e) {
        UploadModal({
          icon: SvgIcons['uploadAlert'],
          title:
            'Os orçamentos foram importados, mas houve um erro no cálculo. Por favor, tente novamente.',
          description: `${values.filename}`,
        })
        return
      }

      dispatch(resetBatches())

      UploadModal({
        icon: SvgIcons['uploadCheck'],
        title: 'Sucesso!',
        description:
          'Os dados dos seus clientes foram processados com sucesso! Clique abaixo para consultar o status das suas solicitações.',
        labelSubmit: 'Consultar status do orçamentos',
        onSubmit: () => {
          history.push(`/orcamento/consulta`)
        },
      })
    },
  })

  useEffect(() => {
    if (organization.type === 'REAL_ESTATE') form.setFieldValue('partner', organization.id)
  }, [organization])

  useEffect(handleReduxState, [form.values])

  return (
    <Container>
      {!loteEnable.result && !loteEnable.isLoading && (
        <Redirect to={`/?nao-habilitado=${Products.IMOBILIARIA}`} />
      )}

      <Content.Heading
        title="Calcular orçamento em lote"
        subtitle="Insira abaixo as informações solicitadas e o seu arquivo."
      />
      <FormikProvider value={form}>
        <Steps.Organization />

        <Conditional when={!!form.values.susep}>
          <Steps.Periods />
          <Conditional when={!!form.values.period_start}>
            <Steps.File />
          </Conditional>
        </Conditional>
      </FormikProvider>
    </Container>
  )
}

export default CalculateBatch
