import { Conditional, Popover } from '@/components'
import { DetailsProduct } from '@/screens/Renovations/Components/DetailsProduct'
import { Tooltip, Typography } from 'design-system-react'
import { useMemo, useState } from 'react'
import { IListProps } from './interfaces'
import { Options, Right } from './styles'

import { limitCaracter } from '@/tools'
import * as S from './styles'

const List = (props: IListProps) => {
  const [pressed, setPressed] = useState(false)
  function onPress() {
    setPressed(!true)
  }

  const errors = useMemo(() => {
    if (!props.errorLabel) return { showError: false } as const

    const message = Array.isArray(props.errorLabel) ? props.errorLabel.join(', ') : props.errorLabel

    if (message.length > 100)
      return { showError: true, message, labelMessage: limitCaracter(message, 100, '...') } as const

    return { showError: true, message, labelMessage: message } as const
  }, [props.errorLabel])

  return (
    <>
      <S.Container
        {...(props.status !== 'error' ? { failure: false } : { failure: true })}
        role="button"
        aria-pressed={pressed}
        aria-haspopup={true}
        tabIndex={0}
        onKeyDown={event => {
          if (event.key === 'Enter' || event.key === ' ') {
            onPress()
          }
        }}
        onClick={() => setPressed(props.status !== 'error' && !pressed)}
      >
        <S.Middle columnsCount={props.details.length}>
          {props.details.map((detail, index) => (
            <S.Cell key={index}>
              <Typography as="span" type="Caption" color="black85" weight="regular">
                {detail.label}
              </Typography>
              <Typography as="span" type="Body2" color="black85" weight="regular">
                {detail.value}
              </Typography>
            </S.Cell>
          ))}
        </S.Middle>

        <Right>
          <Conditional when={props.popover.length > 0}>
            <Popover title="Gerar PDF" items={props.popover}>
              <Options.Toggle>
                <Options.Icon />
                <Options.Label>Mais opções</Options.Label>
              </Options.Toggle>
            </Popover>
          </Conditional>
        </Right>

        {errors.showError && (
          <Tooltip text={errors.message}>
            <Typography as="span" type="Label" weight="bold">
              Retorno do produto:{' '}
              <Typography as="span" color="red100" type="Label" weight="bold">
                {errors.labelMessage}
              </Typography>
            </Typography>
          </Tooltip>
        )}
      </S.Container>

      <Conditional when={pressed}>
        <DetailsProduct statusContract={props.status} details={props.contract} pressed={pressed} />
      </Conditional>
    </>
  )
}
export default List
