import { Icon } from 'design-system-react'
import * as S from './styles'
import { AlertPersistenteProps } from './types'

export const AlertPersistente: React.FC<AlertPersistenteProps> = ({
  children,
  icon,
  handleClose,
}) => {
  return (
    <S.AlertContainer>
      {icon && <Icon size="large" icon={icon} />}

      {children}

      {handleClose && (
        <S.AlertCloseButton onClick={handleClose}>
          <Icon size="large" icon="icon-Close" />
        </S.AlertCloseButton>
      )}
    </S.AlertContainer>
  )
}
