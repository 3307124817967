/* eslint-disable */

import { usePartnershipQuery } from '@/tools/hooks/partnership/usePartnershipQuery'

export const useIsImobiliariaEnabled = (profile): { result: boolean; isLoading: boolean } => {
  if (profile.type === 'BROKER') return { result: true, isLoading: false }

  const partnershipsQuery = usePartnershipQuery()

  const isEnabled = partnershipsQuery.data?.some(
    partnership =>
      partnership.settings['porto_imobiliaria.residential.enabled'] ||
      partnership.settings['porto_imobiliaria.commercial.enabled'],
  )

  return { result: isEnabled, isLoading: partnershipsQuery.isLoading }
}

export const useIsImobiliariaBatchEnabled = (profile): { result: boolean; isLoading: boolean } => {
  if (profile.type === 'BROKER') return { result: true, isLoading: false }

  const partnershipsQuery = usePartnershipQuery()

  const isEnabled = partnershipsQuery.data?.some(
    partnership => partnership.settings['porto_imobiliaria.batch.enabled'],
  )

  return { result: isEnabled, isLoading: partnershipsQuery.isLoading }
}

export const isImobiliariaResidentialEnabled = ({ profile, susep }) => {
  if (profile.type === 'BROKER') return true

  if (!susep) {
    return profile.partnerships.some(
      partnership => partnership.settings['porto_imobiliaria.residential.enabled'],
    )
  }

  const partnership = profile.partnerships.find(p => p.suseps.includes(susep))
  return partnership.settings['porto_imobiliaria.residential.enabled']
}

export const isImobiliariaCommercialEnabled = ({ profile, susep }) => {
  if (profile.type === 'BROKER') return true

  if (!susep) {
    return profile.partnerships.some(
      partnership => partnership.settings['porto_imobiliaria.commercial.enabled'],
    )
  }

  const partnership = profile.partnerships.find(p => p.suseps.includes(susep))
  return partnership.settings['porto_imobiliaria.commercial.enabled']
}
