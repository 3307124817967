import React, { memo, useEffect, useState } from 'react'

import { Conditional, Icons, Loading, Tooltip } from '@/components'
import { useContract, useCurrentOrganization } from '@/modules'
import { dashText, formatMoneyBRL, handleInstallments } from '@/tools'
import Details from '../Details'
import { IPlanProps } from './interfaces'
import * as schema from './schema'

import ModalSelectPayments from './ModalPlan'
import {
  Actions,
  Badge,
  Body,
  Check,
  Container,
  Content,
  CoverageItem,
  Coverages,
  Footer,
  Header,
  Icon,
  Link,
  Price,
  Prices,
  Print,
  Text,
  Title,
} from './styles'

import { usePartnershipQuery } from '@/tools/hooks/partnership'
import { isBroker } from '@/tools/partnership'
import { handleOrgId } from '@/tools/utils/handleOrgId'
import { useLocation } from 'react-router-dom'

interface PaymentsPagesProps {
  setPressed?: React.Dispatch<React.SetStateAction<boolean>>
  pressed?: boolean
  status?: string
  setStatus?: React.Dispatch<React.SetStateAction<String>>
}

const Plan: React.FC<IPlanProps> = props => {
  const contract = useContract()
  const location = useLocation<any>()
  const propsLocation = location.state
  const organization = useCurrentOrganization()

  const [detailsVisibility, setDetailsVisibility] = useState(false)
  const [installments, setInstallments] = useState<number>()

  const [status, setStatus] = useState<'printing' | ''>('')
  const [pressed, setPressed] = useState(false)

  const pagesProps: PaymentsPagesProps = {
    pressed,
    setPressed,
    status,
    setStatus,
  }
  const partnershipsQuery = usePartnershipQuery()

  useEffect(() => {
    if (!partnershipsQuery.data) return
    const partnershipsQueryData = partnershipsQuery.data?.find(
      partnership =>
        partnership.partner.id === handleOrgId(contract.partner) &&
        partnership.suseps.includes(handleOrgId(contract.broker)),
    )

    if (!partnershipsQueryData?.settings) return
    setInstallments(handleInstallments(partnershipsQueryData.settings, isBroker(organization)))
  }, [partnershipsQuery.data])

  return (
    <>
      <Conditional when={detailsVisibility}>
        <Details
          details={props}
          onCancel={() => {
            setDetailsVisibility(false)
          }}
        />
      </Conditional>

      <Conditional when={status === 'printing'}>
        <Loading message="Aguarde, gerando PDF." />
      </Conditional>

      <Container
        onClick={props.onClick && props.onClick}
        active={props.active}
        title="Clique para selecionar"
        data-test-id="select-offer"
      >
        <Content>
          <Header>
            <Actions>
              <Check.Box>
                <Check.Icon />
              </Check.Box>
            </Actions>
            <Title>{props.title}</Title>
            <Conditional when={!!props.type}>
              <Badge type={props.type}>{props.type}</Badge>
            </Conditional>
          </Header>

          <Body>
            <Coverages>
              {props.coverages.map((item: any) => (
                <CoverageItem data-test-id="coverage">
                  <Icon>
                    <Icons.Ionicons name={schema.icons[item.id]} />
                  </Icon>
                  <Text data-test-id={dashText(item.description)}>
                    {item.description} {formatMoneyBRL(item.value)}
                  </Text>
                </CoverageItem>
              ))}
            </Coverages>
          </Body>

          {partnershipsQuery.isFetching && !installments && <span>Carregando...</span>}

          {installments ? (
            <Prices>
              <Price.Highlight data-test-id="price">
                <Price.Content data-test-id="instalments">
                  <Price.Small>{installments}x de </Price.Small>
                  <Price.SmallFess> Sem juros</Price.SmallFess>
                </Price.Content>
                {formatMoneyBRL(props.insurance / installments)}
              </Price.Highlight>

              <Price.Muted data-test-id="total">
                Valor total de {formatMoneyBRL(props.insurance)}
              </Price.Muted>
            </Prices>
          ) : (
            ''
          )}
        </Content>

        <Footer>
          <Link
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="mais-informacoes"
            data-gtm-subname={props.title}
            onClick={() => {
              setDetailsVisibility(state => !state)
            }}
            data-test-id="more-information"
          >
            Mais informações
          </Link>

          <Print
            onClick={() => {
              setPressed(true)
            }}
            data-test-id="print"
          >
            <Tooltip content="Gerar PDF do orçamentos">
              <Icons.Ionicons name="print-outline" />
            </Tooltip>
          </Print>
        </Footer>
      </Container>
      <Conditional when={pressed}>
        <ModalSelectPayments
          details={propsLocation?.state ? propsLocation : contract}
          {...pagesProps}
        />
      </Conditional>
    </>
  )
}

export default memo(Plan)
