import { useField } from 'formik'

import { RadioGroup } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { Flex } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'

import { useCurrentOrganization } from '@/modules'

import { BaseCard } from './../../../../components/BaseCard'
import { useIsCapitalizacaoPaymentEnabled } from '@/tools/partnership'

export const Payment = ({ contract }) => {
  const profile = useCurrentOrganization()

  const { setValue } = useField('payment.type')[2]

  const {
    creditCard: isCapitalizacaoCreditCardEnabled,
    bankSlip: isCapitalizacaoBankSlipEnabled,
    isLoading: isCapitalizacaoPaymentEnabledLoading,
  } = useIsCapitalizacaoPaymentEnabled({
    profile,
    susep: contract.broker.id,
  })

  const paymentOptions = [
    isCapitalizacaoBankSlipEnabled && {
      text: 'Boleto',
      value: 'boleto',
    },
    isCapitalizacaoCreditCardEnabled && {
      text: 'Cartão de crédito',
      value: 'paymentLink',
    },
  ].filter(Boolean)

  return (
    <BaseCard title="Forma de pagamento">
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <RadioGroup
          initialChecked="boleto"
          options={paymentOptions}
          onChange={setValue}
          data-test-id="payment-method"
        />
      </Flex>
    </BaseCard>
  )
}
