import React, { useRef } from 'react'
import { isBrowser, isMobile } from 'react-device-detect'

import { useOutsideClick } from '@/tools'

import { createModal } from '../promised'

import { IInformationProps } from './interfaces'

import Base from '../Base'
import { Body, Close, Content, Dialog, Head, Icon, Title } from './styles'

const variants = {
  hidden: { y: 40 },
  visible: {
    y: 0,
    transition: { ease: [0.65, 0, 0.35, 1], duration: 0.4 },
  },
}

const Information: React.FC<IInformationProps> = props => {
  const ref = useRef()

  useOutsideClick(ref, props.onDismiss)

  return (
    <Base centered={isBrowser} bottom={isMobile}>
      <Dialog ref={ref} initial="hidden" animate="visible" variants={variants}>
        <Head>
          <Close.Action onClick={props.onDismiss}>
            <Close.Icon />
          </Close.Action>
        </Head>

        <Body>
          <Icon name={props.icon} />

          <Title>{props.title}</Title>
          <Content dangerouslySetInnerHTML={{ __html: props.content as any }} />
        </Body>
      </Dialog>
    </Base>
  )
}

export default createModal<IInformationProps>(Information)
