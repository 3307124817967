import { useCurrentOrganization } from '@/modules'
import { Box } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { RealEstatePartnershipCombo } from '../RealEstatePartnershipCombo'
import { RealEstatePartnershipSupepCombo } from '../RealEstatePartnershipSupepCombo'
import { SupepCombo } from '../SusepCombo'
import { IPartnershipSusepCombo } from './interface'

export function PartnershipSusepCombo({ product = 'any' }: IPartnershipSusepCombo) {
  const brokerType = 'BROKER'
  const realEstateType = 'REAL_ESTATE'
  const profile = useCurrentOrganization()
  const profileType = profile.type

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr 1fr', lg: '1fr 1fr' },
        gap: '1rem',
        marginTop: '16px',
        width: '100%',
      }}
    >
      {profileType === realEstateType && <SupepCombo product={product} />}

      {profileType === brokerType && (
        <>
          <RealEstatePartnershipCombo />

          <RealEstatePartnershipSupepCombo />
        </>
      )}
    </Box>
  )
}
