import { dashText } from '@/tools'
import { CircularProgress } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useField } from 'formik'
import { Fragment } from 'react'
import { IAutoCompleteLocal } from './interfaces'
import { ValidationTextField } from './styles'

export function AutocompleteLocal<OptionType>({
  loading = false,
  sx = {},
  options = [],
  disabled = false,
  getOptionLabel,
  isOptionEqualToValue,
  id,
  label,
  name,
}: IAutoCompleteLocal<OptionType>) {
  const [field, meta, helpers] = useField(name)

  return (
    <Autocomplete
      {...field}
      disablePortal
      loading={loading}
      disabled={loading || disabled}
      id={id}
      data-test-id={`combo-${dashText(label)}`}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={async (event, value) => {
        await helpers.setValue(value)
        helpers.setTouched(true)
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      sx={{
        width: '100%',
        ...sx,
      }}
      renderInput={params => (
        <ValidationTextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          error={!!meta.error && meta.touched}
          helperText={meta.touched ? meta.error : undefined}
        />
      )}
    />
  )
}
