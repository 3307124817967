import { usePartnershipQuery } from '@/tools/hooks/partnership/usePartnershipQuery'

export const useIsCapitalizacaoEnabled = (profile): { result: boolean; isLoading: boolean } => {
  const partnershipsQuery = usePartnershipQuery({ enabled: profile.type !== 'BROKER' })

  if (profile.type === 'BROKER') return { result: true, isLoading: false }

  const isEnabled = partnershipsQuery.data?.some(
    partnership => partnership.settings['porto_capitalizacao.enabled'],
  )

  return { result: isEnabled, isLoading: partnershipsQuery.isLoading }
}

export const useIsCapitalizacaoPaymentEnabled = ({
  profile,
  susep,
}): { creditCard: boolean; bankSlip: boolean; isLoading: boolean } => {
  const partnershipsQuery = usePartnershipQuery({ enabled: profile.type !== 'BROKER' })

  if (profile.type === 'BROKER') return { creditCard: true, bankSlip: true, isLoading: false }

  if (!susep) {
    return partnershipsQuery.data?.some(
      partnership =>
        partnership.settings['porto_capitalizacao.payments.creditCard.enabled'] &&
        partnership.settings['porto_capitalizacao.payments.bankSlip.enabled'],
    )
  }

  const partnership = partnershipsQuery.data?.find(p => p.suseps.includes(susep))

  return {
    creditCard: partnership?.settings['porto_capitalizacao.payments.creditCard.enabled'],
    bankSlip: partnership?.settings['porto_capitalizacao.payments.bankSlip.enabled'],
    isLoading: partnershipsQuery.isLoading,
  }
}
