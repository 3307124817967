import dayjs from 'dayjs'
import * as yup from 'yup'

import { birthdateValidator } from '@/tools/validators'

import { BRLMoneyToNumber } from '@/tools'
import { over18Validator } from '@/tools/validators/birthdate'
import { isAfterToday, isBeforeToday } from '@/tools/validators/date'
import { isCEP, isCNPJ, isCPF, isPhone } from 'brazilian-values'
import organizationSchema from '../../shared/Organization/schema'

const documentValidation = (value: string) => {
  const document = value.replace(/\.|-|\//g, '')
  if (document.length < 14) return isCPF(document)

  return isCNPJ(document)
}

const address = yup.object().shape({
  zipcode: yup
    .string()
    .required('Campo CEP é obrigatório.')
    .test({
      message: 'Por favor insira um CEP válido.',
      test: value => !!value && isCEP(value),
    }),
  street: yup.string().required('Por favor confirme a rua.'),
  number: yup.string().required('Campo número é obrigatório.'),
  complement: yup.string(),
  neighborhood: yup.string().required('Por favor confirme o bairro.'),
  city: yup.string().required('Por favor confirme a cidade.'),
  state: yup.string().required('Por favor confirme o estado.'),
})

const commonCoverages = {
  fire: yup
    .string()
    .test('invalid-coverage', 'Cobertura máxima de R$ 200.000.', function (value) {
      const floatValue = value ? parseFloat(value) : 0
      const [
        ,
        {
          value: { estate_type },
        },
      ] = this.from

      if (estate_type === '9') {
        if (floatValue > 200000) {
          return false
        }
      }
      return true
    })
    .test('invalid-coverage', 'Cobertura mínima de R$ 40.000.', value => {
      const floatValue = value ? parseFloat(value) : 0
      return floatValue >= 40000
    })
    .test('invalid-coverage', 'Cobertura máxima de R$ 2.000.000.', value => {
      const floatValue = value ? parseFloat(value) : 0
      return floatValue <= 2000000
    })
    .required('Cobertura incêndio obrigatória.')
    .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
  content: yup.string().required('Cobertura conteúdo obrigatória.'),
  eletrical_damages: yup
    .string()
    .test({
      message: 'Cobertura mínima de R$ 1.000 e máxima de 50% do incêndio.',
      test: function (value) {
        const fireValue = parseFloat(this.parent.fire)
        const eletricalDamagesValue = parseFloat(`${Number(0)}`)

        if (isNaN(eletricalDamagesValue)) return false
        if (eletricalDamagesValue < 1000 && eletricalDamagesValue > 0) return false
        if (eletricalDamagesValue > fireValue * 0.5) return false

        return true
      },
    })
    .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
}

const coverages = {
  residential: {
    ...commonCoverages,
    vehicle_impacts: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 1.000 e máxima de 100% do incêndio.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const vehicleImpactsValue = parseFloat(`${Number(0)}`)

          if (isNaN(vehicleImpactsValue)) return false
          if (vehicleImpactsValue < 1000 && vehicleImpactsValue > 0) return false
          if (vehicleImpactsValue > fireValue) return false

          return true
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
    gale: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 1.000 e máxima de 50% do incêndio limitada a R$ 500.000.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const galeValue = parseFloat(`${Number(0)}`)

          if (isNaN(galeValue)) return false
          if (galeValue < 1000 && galeValue > 0) return false
          if (galeValue > 500000) return false
          if (galeValue > fireValue * 0.5) return false

          return true
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
    rental_loss: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 3.000 e máxima de 50% do incêndio limitada a R$ 500.000.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const rentalLossValue = parseFloat(`${Number(0)}`)

          if (isNaN(rentalLossValue)) return false
          if (rentalLossValue === 0) return true
          if (rentalLossValue < 3000) return false

          return rentalLossValue <= 500000 && rentalLossValue <= fireValue * 0.5
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
    civil_responsability: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 2.000 e máxima de 50% do incêndio.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const civilResponsabilityValue = parseFloat(`${Number(0)}`)

          if (isNaN(civilResponsabilityValue)) return false
          if (civilResponsabilityValue === 0) return true
          if (civilResponsabilityValue < 2000) return false

          return civilResponsabilityValue <= fireValue * 0.5
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
  },
  commercial: {
    ...commonCoverages,
    vehicle_impacts: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 1.000 e máxima de 100% do incêndio.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const vehicleImpactsValue = parseFloat(`${Number(0)}`)

          if (isNaN(vehicleImpactsValue)) return false
          if (vehicleImpactsValue === 0) return true
          if (vehicleImpactsValue < 1000) return false

          return vehicleImpactsValue <= fireValue
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
    gale: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 1.000 e máxima de 30% do incêndio limitada a R$ 400.000.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const galeValue = parseFloat(`${Number(0)}`)

          if (isNaN(galeValue)) return false
          if (galeValue === 0) return true
          if (galeValue < 1000) return false
          return galeValue <= 400000 && galeValue <= fireValue * 0.3
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
    rental_loss: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 1.000 e máxima de 50% do incêndio limitada a R$ 500.000.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const rentalLossValue = parseFloat(`${Number(0)}`)

          if (isNaN(rentalLossValue)) return false
          if (rentalLossValue === 0) return true
          if (rentalLossValue < 1000) return false
          return rentalLossValue <= 500000 && rentalLossValue <= fireValue * 0.5
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
    civil_responsability: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 5.000 e máxima de 50% do incêndio.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const civilResponsabilityValue = parseFloat(`${Number(0)}`)

          if (isNaN(civilResponsabilityValue)) return false
          if (civilResponsabilityValue === 0) return true
          if (civilResponsabilityValue < 5000) return false
          return civilResponsabilityValue <= fireValue * 0.5
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
    riot: yup
      .string()
      .test({
        message: 'Cobertura mínima de R$ 1.000 e máxima de 50% do incêndio.',
        test: function (value) {
          const fireValue = parseFloat(this.parent.fire)
          const riotValue = parseFloat(`${Number(0)}`)

          if (isNaN(riotValue)) return false
          if (riotValue === 0) return true
          if (riotValue < 1000) return false
          return riotValue <= fireValue * 0.5
        },
      })
      .transform(value => `${value ? BRLMoneyToNumber(`${value}`) : ''}`),
  },
}

const contract = {
  contract_plan: yup
    .string()
    .oneOf(['residential', 'commercial'], 'Selecione o plano')
    .required('Selecione o plano'),
  contract_clause: yup
    .string()
    .notOneOf([''], 'Selecione a cláusula de serviço')
    .required('Selecione a cláusula de serviço'),
  contract_period_start: yup
    .string()
    .nullable()
    .required('Campo início de vigência é obrigatório.'),
  contract_period_end: yup.string().when('estate_type', {
    is: value => !['7', '8', '4'].includes(value),
    then: yup
      .string()
      .nullable()
      .required('Campo fim de vigência é obrigatório.')
      .test('contract-end-validation', 'Vigência máxima de 5 anos.', (value = '') => {
        const max = dayjs().add(5, 'year').add(2, 'months')
        return dayjs(value).isBefore(max)
      }),
  }),
  contract_discount: yup.string(),
  contract_prolabore: yup.string(),
  contract_commission: yup.string(),
}

const customer = {
  customer_document: yup
    .string()
    .required('Campo CPF/CNPJ é obrigatório.')
    .test(
      'document-test',
      'Por favor insira um CPF/CNPJ válido.',
      value => !!value && documentValidation(value),
    ),
  customer_name: yup.string().when('customer_document', {
    is: value => isCPF(value),
    then: yup.string().required('Campo nome é obrigatório.'),
  }),
  customer_social_name: yup.string().optional(),
  customer_birthdate: yup.string().when('customer_document', {
    is: value => isCPF(value),
    then: yup
      .string()
      .test('invalid-date', 'Favor informar data de nascimento válida.', birthdateValidator)
      .test('over-18', 'Idade mínima 18 anos', over18Validator)
      .required('Campo é obrigatório.'),
  }),
  customer_email: yup.string().when('customer_document', {
    is: value => isCPF(value),
    then: yup
      .string()
      .email('Por favor insira um e-mail válido.')
      .matches(/(\w|$)+@/g, 'Por favor insira um e-mail válido.')
      .required('Campo e-mail é obrigatório.'),
  }),
  customer_phone: yup.string().when('customer_document', {
    is: value => isCPF(value),
    then: yup
      .string()
      .test('invalid-phone', 'Por favor insira um número válido.', value => isPhone(value))
      .required('Campo telefone/celular é obrigatório.'),
  }),
  civil_status: yup.string().when('customer_document', {
    is: value => isCPF(value),
    then: yup
      .string()
      .notOneOf([''], 'Selecione o estado civil.')
      .required('Selecione o estado civil.'),
  }),
  customer_income: yup.string().when('customer_document', {
    is: value => isCPF(value),
    then: yup
      .string()
      .notOneOf([''], 'Selecione a faixa de renda.')
      .required('Selecione a faixa de renda.'),
  }),
  customer_occupation: yup.string().when('customer_document', {
    is: value => isCPF(value),
    then: yup.string().notOneOf([''], 'Selecione a profissão.').required('Selecione a profissão.'),
  }),
  customer_pep: yup.string().when('customer_document', {
    is: value => isCPF(value),
    then: yup.string().notOneOf([''], 'Selecione uma opção.').required('Selecione uma opção.'),
  }),

  pep_document_type: yup.string().when('customer_pep', {
    is: value => value === '1',
    then: yup.string().notOneOf([''], 'Selecione uma opção.').required('Selecione uma opção'),
  }),
  pep_document: yup.string().when('pep_document_type', {
    is: value => !!value,
    then: yup.string().required('Campo é obrigatório.'),
  }),
  pep_document_dispatcher: yup.string().when('pep_document_type', {
    is: value => !!value,
    then: yup.string().required('Campo é obrigatório.'),
  }),
  pep_document_expedition_date: yup.string().when('pep_document_type', {
    is: value => !!value && ['1', '40'].includes(value),
    then: yup
      .string()
      .required('Campo é obrigatório.')
      .test('invalid-expedition-date', 'Favor informar data válida.', isBeforeToday),
  }),
  pep_document_expiration_date: yup.string().when('pep_document_type', {
    is: value => !!value && !['1', '40'].includes(value),
    then: yup
      .string()
      .required('Campo é obrigatório.')
      .test('invalid-expiration-date', 'Favor informar data válida.', isAfterToday),
  }),
  pep_nationality: yup.string().when('pep_document_type', {
    is: value => !!value && !['1', '5'].includes(value),
    then: yup.string().required('Campo é obrigatório'),
  }),
  pep_brazilian_resident: yup.string().when('pep_document_type', {
    is: value => !!value,
    then: yup.string().required('Campo é obrigatório'),
  }),

  pep_cpf: yup.string().when('customer_pep', {
    is: value => value === '3',
    then: yup
      .string()
      .required('Campo é obrigatório')
      .test(
        'pep-cpf-test',
        'Por favor insira um CPF válido.',
        value => !!value && documentValidation(value),
      ),
  }),
  pep_name: yup.string().when('customer_pep', {
    is: value => value === '3',
    then: yup.string().required('Campo é obrigatório'),
  }),
  pep_relationship_type: yup.string().when('customer_pep', {
    is: value => value === '3',
    then: yup.string().required('Campo é obrigatório'),
  }),

  company_name: yup.string().when('customer_document', {
    is: value => isCNPJ(value),
    then: yup.string().required('Campo é obrigatório.'),
  }),
  company_activity_area: yup.string().when('customer_document', {
    is: value => isCNPJ(value),
    then: yup.string().notOneOf([''], 'Selecione').required('Campo é obrigatório.'),
  }),
  foundation_date: yup.string().when('customer_document', {
    is: value => isCNPJ(value),
    then: yup
      .string()
      .required('Campo é obrigatório.')
      .test('invalid-foundation-date', 'Favor informar data válida.', birthdateValidator),
  }),
  company_phone: yup.string().when('customer_document', {
    is: value => isCNPJ(value),
    then: yup
      .string()
      .required('Campo é obrigatório.')
      .test('invalid-phone', 'Por favor insira um número válido.', value => isPhone(value)),
  }),
  company_email: yup.string().when('customer_document', {
    is: value => isCNPJ(value),
    then: yup
      .string()
      .required('Campo é obrigatório.')
      .email('Por favor insira um e-mail válido.')
      .matches(/(\w|$)+@/g, 'Por favor insira um e-mail válido.'),
  }),
}

const estate = {
  estate_type: yup
    .string()
    .notOneOf([''], 'Campo tipo de imóvel é obrigatório.')
    .required('Campo tipo de imóvel é obrigatório.'),
  estate_owner: yup.boolean(),
  estate_address: address,
}

export default yup.object().shape({
  ...organizationSchema,
  ...contract,
  ...customer,
  ...estate,
  coverages: yup.object().when('contract_plan', value => {
    if (!value) return
    return yup.object().shape(coverages[value])
  }),
})
