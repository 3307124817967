import { Forms } from '@/components'
import { useCurrentOrganization } from '@/modules'

import { PartnershipSusepCombo } from '@/components/PartnershipSusepCombo'
import { ISuseps } from './types'

interface IProducts {
  product:
    | 'porto_imobiliaria'
    | 'porto_essencial'
    | 'porto_aluguel'
    | 'porto_capitalizacao'
    | 'home_equity'
  valuesPartner?: (partner: { id?: string }) => void
  suseps?: ISuseps
  setStatus?: boolean
  gtmSubname?: string
  disabled?: boolean
}

export const parseError = {
  '@partners/invalid-document': {
    title: 'Documento ou corretor deve ser um CPF ou CNPJ válido.',
  },
  '@partners/invalid-name': {
    title: 'O campo de nome deve ter pelo menos 5 caracteres',
  },
  '@partners/partner-not-found': {
    title: 'Parceiro não encontrado',
  },
  '@partners/not-allowed': {
    title: 'O usuário não tem permissão para executar essa ação',
  },
  'Integration Error': {
    title: 'Ocorreu um erro!',
  },
  '@partners/partnership-not-found': {
    title: 'Não há vínculo com o parceiro selecionado, não é possível prosseguir',
  },
}

const Organization = ({ product, valuesPartner }: IProducts) => {
  const profile = useCurrentOrganization()
  const isBroker = profile.type === 'BROKER'
  const getProduct = () => {
    return product !== 'home_equity' ? product : 'any'
  }

  return (
    <Forms.Card
      active
      step="1"
      title={isBroker ? 'Imobiliária e Corretora de Seguros' : 'Corretora de Seguros'}
      helper={
        isBroker
          ? 'Comece selecionando qual <b>parceiro de negócio</b> e <b>SUSEP</b> será relacionada neste orçamento.'
          : 'Selecione a <b>SUSEP</b> da <b>corretora de seguros</b> parceira.'
      }
    >
      <PartnershipSusepCombo product={getProduct()} />
    </Forms.Card>
  )
}

export default Organization
