import { useOutsideClick } from '@/tools'
import {
  Box,
  Flex,
  Typography,
} from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import { Loader } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/porto'
import { useField } from 'formik'
import { debounce } from 'lodash'
import { useCallback, useRef, useState } from 'react'

import Input from '../Forms/Input'
import Label from '../Forms/Label'
import Message from '../Forms/Message'
import * as S from './styles'
import { AutoCompleteProps, Suggestions } from './types'

const ERROR_HEIGHT = '24px'
const SUGGESTION_MAX_HEIGHT = '180px'

const Autocomplete = ({
  initialInnerValue = '',
  name,
  onSearch,
  label,
  onSelect,
  placeholder,
  emptySearchMessage,
  showRequired,
}: AutoCompleteProps) => {
  const [_, meta, helpers] = useField(name)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [innerValue, setInnerValue] = useState<string>(initialInnerValue)
  const [suggestions, setSuggestions] = useState<Suggestions>([])
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
  const ref = useRef(null)

  useOutsideClick(ref, () => {
    if (showSuggestions && !isLoading) {
      setShowSuggestions(false)
      setSuggestions([])
      setInnerValue('')
      helpers.setValue('')
    }
  })

  const handleChange = useCallback(
    debounce(async function (value: string) {
      try {
        if (value.length > 2) {
          setIsLoading(true)
          const response = await onSearch(value)
          setSuggestions(response)
          setShowSuggestions(true)
          return
        }
      } catch (err) {
        setShowSuggestions(false)
        setSuggestions([])
        throw err
      } finally {
        setIsLoading(false)
      }
    }, 500),
    [],
  )

  const handleOnKeyDown = e => {
    /*
      @TODO: adds A11Y - navigation with arrow keys, and a better
      event handling.
    */
    /* prevents enter click to submit some wrapper form */
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }

  const hasError = meta.touched && !!meta.error

  return (
    <Box ref={ref}>
      <Box sx={{ position: 'relative' }} onClick={e => e.stopPropagation()}>
        <Box>
          <Label showRequired={showRequired}>{label || 'Buscar'}</Label>
          <Box
            sx={{
              position: 'relative',
              input: {
                marginBottom: '0px',
              },
            }}
          >
            <Input
              autoComplete="off"
              onChange={e => {
                setInnerValue(e.target.value)
                handleChange(e.target.value.toUpperCase())
              }}
              onFocus={() => helpers.setTouched(false)}
              onBlur={() => helpers.setTouched(true)}
              onKeyDown={handleOnKeyDown}
              value={innerValue}
              placeholder={placeholder || 'Digite para buscar'}
              error={hasError}
            />
            {isLoading && (
              <Box
                sx={{
                  '--loader-size': '1rem',
                  position: 'absolute',
                  top: 'calc(50% - (var(--loader-size)/2))',
                  right: '12px',
                }}
              >
                <Loader size="var(--loader-size)" color="porto-primitive-black-65" />
              </Box>
            )}
          </Box>
        </Box>
        {hasError && <Message kind="danger">{meta.error}</Message>}
        {!isLoading && showSuggestions && (
          <Flex
            sx={{
              flexDirection: 'column',
              borderRadius: '.25rem',
              minWidth: '100%',
              maxWidth: 'fit-content',
              padding: '.25rem .5rem',
              position: 'absolute',
              zIndex: '2',
              top: `calc(100% ${hasError && `- ${ERROR_HEIGHT}`})`,
              backgroundColor: 'system-surface-secondary',
              border: '1px solid var(--system-primitive-off-white-300)',
              maxHeight: SUGGESTION_MAX_HEIGHT,
              overflowY: 'auto',
            }}
          >
            {suggestions.length === 0 && (
              <S.Suggestion disabled>
                <Typography
                  variant="porto-text-caption-bold"
                  color="system-text-primary"
                  content={emptySearchMessage || 'Nenhum item encontrado'}
                />
              </S.Suggestion>
            )}
            {suggestions.map(({ label, value, ...props }, index) => (
              <S.Suggestion
                key={`${label}_${value}-${index}`}
                onClick={() => {
                  helpers.setValue(value)
                  setInnerValue(label)
                  setSuggestions([])
                  setShowSuggestions(false)
                  onSelect && onSelect({ label, value, ...props })
                }}
              >
                <Typography
                  variant="porto-text-caption-bold"
                  color="system-text-primary"
                  content={label}
                />
              </S.Suggestion>
            ))}
          </Flex>
        )}
      </Box>
    </Box>
  )
}

export default Autocomplete
