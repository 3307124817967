import { useField } from 'formik'
import { useEffect, useMemo } from 'react'
import { AutocompleteLocal } from '../AutocompleteLocal'
import { useSusepOptionsQuery } from './hooks'
import { ISupepCombo } from './interfaces'

export const SupepCombo = ({ product = 'any', disabled = false }: ISupepCombo) => {
  const [field, , helpers] = useField('susep')
  const susepQuery = useSusepOptionsQuery(product)

  const options = useMemo(() => {
    if (susepQuery.isLoading) return []

    return susepQuery.data?.map(e => e.susep)
  }, [susepQuery.data, susepQuery.isLoading])

  useEffect(() => {
    if (susepQuery.isFetching) return

    const valid = options.some(value => value === field.value)

    if (!valid) helpers.setValue(undefined)
  }, [field.value, helpers, options, susepQuery.isFetching])

  return (
    <AutocompleteLocal<string>
      label="SUSEP"
      name="susep"
      id="susep-combo"
      getOptionLabel={value => {
        const option = susepQuery.data?.find(e => e.susep === value)

        if (!option) return ''

        return `${option.susep} - ${option.broker.name}`
      }}
      disabled={disabled}
      loading={susepQuery.isFetching}
      options={options}
    />
  )
}
