import React from 'react'

import { AnimatePresence } from 'framer-motion'
import { Portal } from 'react-portal'

import { useLockBodyScroll } from '@/tools/hooks/window'

import { IModalBaseProps } from './interfaces'

import { Dialog, Modal, Overlay } from './styles'

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const Base: React.FC<IModalBaseProps> = props => {
  useLockBodyScroll()

  return (
    <Portal>
      <AnimatePresence>
        <Modal
          className="modal"
          id="modal"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          {...props}
        >
          <Overlay onClick={props.onDismiss} />

          <Dialog>{props.children}</Dialog>
        </Modal>
      </AnimatePresence>
    </Portal>
  )
}

export default Base
