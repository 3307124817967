import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Conditional, Content, Grid, Info, Loading, Modals, Paginator, toast } from '@/components'
import { plansParse } from '@/screens/Contracts/Products/Imobiliaria/Budgets/utils'
import { api } from '@/services'

import Export from '@/screens/Consultations/Listing/components/Export'
import Filter from '../../Components/Filter'
import List from './List'
import { IListPopover } from './List/interfaces'

import dayjs from 'dayjs'

import { limitCaracter } from '@/tools'
import { capitalize } from '@brazilian-utils/brazilian-utils'
import { Button, Typography } from 'design-system-react'
import { FormikProvider, useFormik } from 'formik'
import { useQuery } from 'react-query'
import * as S from './styles'

const generateReactQueryKeys = (filters: object) => {
  const keys = Object.keys(filters).sort()
  const queryKeys: string[] = []

  for (let key of keys) {
    if (filters[key]) queryKeys.push(`${key} ${filters[key]}`)
  }

  return queryKeys
}

const Imobiliaria = () => {
  const [page, setPage] = useState(1)
  const [statusPdf, setStatusPdf] = useState<'printing' | ''>('')
  const [filters, setFilters] = useState<Filter.Values>({})
  const ticketExists = ['11', '21', '81']
  const history = useHistory()

  const queryKeys = useMemo(
    () =>
      generateReactQueryKeys({
        page,
        ...filters,
      }),
    [filters, page],
  )

  const contractQuery = useQuery(
    ['@imobiliaria/renewal', ...queryKeys],
    async () => {
      const response = await api.instance.v2.get(`/contracts`, {
        params: {
          product: 'PORTO_IMOBILIARIA',
          page,
          limit: 10,
          ...filters,
          renewal_available: true,
        },
      })

      return response.data
    },
    {
      onError: () => {
        toast('Ocorreu um erro ao buscar os contratos.', {
          type: 'error',
        })
      },
    },
  )

  const handlePrint = async (id: string, type: string, budget?: string) => {
    try {
      setStatusPdf('printing')
      await api.contracts.print(id, type, budget)
    } catch (error) {
      setStatusPdf('')
      if (type === 'ticket') {
        handleInformationTicket()
      }
    } finally {
      setStatusPdf('')
    }
  }

  function handleInformationTicket() {
    Modals.Information({
      icon: 'hourglass-outline',
      title: 'O boleto está sendo gerado.',
      content: `Por favor, tente novamente mais tarde.`,
    })
  }

  const validadeContractStatus = contract => {
    if (contract.policy?.status === 'Recusada') return 'refused'

    if (contract.policy?.status === 'Cancelado') return 'canceled'

    if (contract.policy?.id) return 'policy'

    if (contract.policy?.proposal) return 'proposal'

    if (contract?.budgets?.length > 0) return 'budget'

    return 'error'
  }

  const validatePopover = contract => {
    const list: IListPopover[] = [
      {
        icon: 'copy',
        label: 'Editar dados',
        onClick: async () => {
          history.push({
            pathname: '/contratos/novo/imobiliaria',
            state: { ...contract, renovation: true },
          })
        },
      },
    ]

    if (contract?.renewal_ready && validadeContractStatus(contract) !== 'error') {
      list.push({
        icon: 'copy',
        label: 'Manter dados e renovar',
        onClick: async () => {
          history.push({
            pathname: `/contratos/${contract?.id}`,
          })
        },
      })
    }

    if (contract.payload.payment?.method.includes(ticketExists)) {
      list.push({
        icon: 'reader',
        label: 'PDF do boleto',
        onClick: () => handlePrint(contract.id, 'ticket'),
      })
    }
    if (contract.policy?.budget) {
      list.push({
        icon: 'reader',
        label: 'PDF do orçamento',
        onClick: () => handlePrint(contract.id, 'budget', contract.policy?.budget),
      })
    }
    if (contract.policy?.proposal) {
      list.push({
        icon: 'reader',
        label: 'PDF da proposta',
        onClick: () => handlePrint(contract.id, 'proposal'),
      })
    }
    if (contract.policy?.id) {
      list.push({
        icon: 'reader',
        label: 'PDF da apólice',
        onClick: () => handlePrint(contract.id, 'policy'),
      })
    }
    return list
  }

  const handleFilter = async () => {
    searchForm.setFieldValue('search', '')
    const newFilters = await Filter.openModal(filters)
    setFilters(!!newFilters ? newFilters : {})
  }

  useEffect(() => {
    setPage(1)
  }, [filters])

  const handleExport = async () => {
    const filterProduct = {
      product: 'PORTO_IMOBILIARIA',
      renewal_available: true,
    }
    await Export(filterProduct)
  }

  const searchForm = useFormik({
    initialValues: { search: '' },
    onSubmit(values) {
      setFilters(value => ({ ...value, search: values.search }))
    },
  })

  function showName(customer: any) {
    const hasSocialName = customer.socialName ? true : false
    const socialName = customer.socialName
    const name = customer.name
    const allName = capitalize(socialName || name || '-')
    const realName = capitalize(name)

    return (
      <Grid>
        <Grid columns="repeat(2, max-content)" gap="8px">
          <Typography as="span" type="Body2" weight="regular">
            {limitCaracter(allName, 25, '...')}
          </Typography>
          {hasSocialName && (
            <Info content="Este é o nome social do cliente e deve ser utilizado em todas as comunicações. O nome de registro, abaixo, é apenas para consulta." />
          )}
        </Grid>
        {hasSocialName && (
          <Typography as="span" type="Label" color="black65">
            {limitCaracter(realName, 25, '...')}
          </Typography>
        )}
      </Grid>
    )
  }

  return (
    <>
      <Content.Heading
        title="Qual Seguro Incêndio será renovado?"
        subtitle="Busque pelos dados do contrato e selecione o que deseja renovar."
      />
      <S.Container>
        <Conditional when={statusPdf === 'printing'}>
          <Loading message="Aguarde, gerando PDF." />
        </Conditional>
        <Conditional when={contractQuery.isLoading}>
          <Content.Loader message="Aguarde, buscando contratos de Seguro Incêndio." />
        </Conditional>
        <Conditional when={contractQuery.isSuccess && !contractQuery.isLoading}>
          <S.Section>
            <S.Head>
              <FormikProvider value={searchForm}>
                <S.SearchForm>
                  <S.Search name="search" placeholder={'Digite o documento, nome, CPF ou CNPJ'} />
                  <Button
                    styles="primary"
                    variant="insurance"
                    icon="icon-Magnifier-glass"
                    type="submit"
                  >
                    Buscar
                  </Button>
                </S.SearchForm>
              </FormikProvider>
              <S.Actions>
                <S.Action
                  icon="icon-Filter"
                  styles={!Object.keys(filters).length ? 'secondary' : 'primary'}
                  variant="insurance"
                  type="button"
                  onClick={handleFilter}
                >
                  {Object.keys(filters).length > 0 ? 'Editar filtros' : 'Filtros'}
                </S.Action>
                <S.Action
                  styles="secondary"
                  variant="insurance"
                  icon="icon-XLS-file"
                  type="button"
                  onClick={handleExport}
                >
                  Exportar
                </S.Action>
              </S.Actions>
            </S.Head>
            <S.Body>
              {contractQuery.data?.map(contract => {
                const errorLabel = contract.metadata.error?.cause?.exception?.errors?.message

                return (
                  <List
                    details={[
                      {
                        label: 'Proponente',
                        value: showName(contract.payload.customer),
                      },
                      {
                        label: 'Criado em',
                        value: dayjs(contract.created_at).format('DD/MM/YYYY'),
                      },
                      {
                        label: 'Início de vigência',
                        value: dayjs(contract.payload.contract.period.start)
                          .utc(false)
                          .format('DD/MM/YYYY'),
                      },
                      {
                        label: 'Tipo de produto',
                        value: plansParse[contract.payload.contract.plan.toLowerCase()],
                      },
                      !contract.policy?.id && {
                        label: 'N° do orçamento',
                        value: contract.policy?.budget,
                      },
                      {
                        label: 'N° da proposta',
                        value: contract.policy?.proposal,
                      },
                      contract.policy?.id && {
                        label: 'N° da apólice',
                        value: contract.policy?.id || '',
                      },
                      contract.renewal_policy_id && {
                        label: 'N° da apólice',
                        value: contract.renewal_policy_id || '',
                      },
                    ].filter(item => !!item?.value)}
                    popover={validatePopover(contract)}
                    status={validadeContractStatus(contract)}
                    errorLabel={errorLabel}
                    contract={contract}
                  />
                )
              })}
            </S.Body>
          </S.Section>
        </Conditional>
      </S.Container>

      {contractQuery.isSuccess && (
        <>
          {contractQuery.data?.length === 0 && (
            <S.Message>Não há mais documentos a serem exibidos</S.Message>
          )}

          <Paginator
            onNext={() => setPage(currentPage => currentPage + 1)}
            onPrevious={() => setPage(currentPage => currentPage - 1)}
            numberPage={page}
            limitPage={contractQuery.data?.length}
          />
        </>
      )}
    </>
  )
}

export default Imobiliaria
