import { Conditional, Content, Loading } from '@/components'
import { useCurrentOrganization } from '@/modules'
import { useEffect } from 'react'

import { Container, List } from './styles'

import { Capitalizacao, Essencial, Incendio } from '@/components/Icons/Products'

import Box from '@/components/Box'
import { useGTM } from '@/tools'

import {
  useIsCapitalizacaoEnabled,
  useIsEssencialEnabled,
  useIsImobiliariaEnabled
} from '@/tools/partnership'

const Listing = () => {
  const { setDataLayer, setCustomData } = useGTM()
  const profile = useCurrentOrganization()

  const { result: isEssencialEnabled, isLoading: isEssencialEnabledLoading } = useIsEssencialEnabled(profile)
  const { result: isCapitalizacaoEnabled, isLoading: isCapitalizacaoEnabledLoading } = useIsCapitalizacaoEnabled(profile)
  const { result: isImobiliariaEnabled, isLoading: isImobiliariaEnabledLoading } = useIsImobiliariaEnabled(profile)

  useEffect(() => {
    setCustomData({
      page: {
        name: 'Portal Imobiliária - Orçamento',
      },
    })

    setDataLayer({
      event: 'step_change',
      titulo: 'Portal Imobiliária - Orçamentos',
      etapa: '/orcamentos',
    })
  }, [setCustomData, setDataLayer])

  return (
    <Container>
      <Content.Heading
        title="Orçamentos"
        subtitle="Selecione o produto para iniciar um novo orçamento."
      />

      {/* {isEssencialEnabledLoading && <Loading />} */}

      <List>
        <Conditional when={isImobiliariaEnabled}>
          <Box
            color="var(--porto-banking-100)"
            initialsColor="var(--porto-banking-15)"
            to="/contratos/novo/imobiliaria"
            icon={<Incendio />}
            title="Seguro Incêndio"
            description="Oferece cobertura contra incêndio para imóveis alugados. Obrigatório por lei."
            data-gtm-type="click"
            data-gtm-clicktype="card"
            data-gtm-name="seguro-incendio"
          />
        </Conditional>

        <Conditional when={isEssencialEnabled}>
          <Box
            color="var(--porto-banking-100)"
            initialsColor="var(--porto-banking-15)"
            to="/contratos/novo/essencial"
            icon={<Essencial />}
            title="Fiança Locatícia Essencial"
            description="Economia na garantia do contrato de aluguel. Com análise de crédito sem complicações!"
            data-gtm-type="click"
            data-gtm-clicktype="card"
            data-gtm-name="seguro-fianca-essencial"
          />
        </Conditional>

        <Conditional when={isCapitalizacaoEnabled}>
          <Box
            color="var(--porto-banking-100)"
            initialsColor="var(--porto-banking-15)"
            to="/contratos/novo/capitalizacao"
            icon={<Capitalizacao />}
            title="Título de Capitalização"
            description="Garantia do contrato de locação para pagamento do aluguel. Sem análise cadastral e resgate de até 100% do valor contratado!"
            data-gtm-type="click"
            data-gtm-clicktype="card"
            data-gtm-name="capitalizacao"
          />
        </Conditional>
      </List>
    </Container>
  )
}

export default Listing
